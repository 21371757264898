import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { Query } from 'react-apollo'
import Loader from '../../../components/Loader'
import TrackList from '../../../components/trackList'
import EmptyParams from '../components/EmptyParams'
import SEARCH_TRACKS_QUERY from '../../../graphql/TracksSearchQuery'
import SearchQuery from '../components/Search'

const TrackSearchView = () => (
  <SearchQuery placeholder="Search by track...">
    {({ query }) => {
      if (isEmpty(query)) return <EmptyParams />
      return (
        <div id="content">
          <Query query={SEARCH_TRACKS_QUERY} variables={{ trackTitle: query }}>
            {({ loading, error, data, ...props }) => {
              if (loading) return <Loader />
              if (error) return <p>An error had happened.</p>
              if (
                data &&
                data.tracks &&
                data.tracks.edges &&
                data.tracks.edges.length === 0
              ) {
                return <p>There are no results.</p>
              }
              return (
                <React.Fragment>
                  <header>
                    <h3>TRACKS ({data.tracks.pageInfo.totalCount})</h3>
                  </header>
                  <TrackList {...data} {...props} />
                </React.Fragment>
              )
            }}
          </Query>
        </div>
      )
    }}
  </SearchQuery>
)

export default TrackSearchView
