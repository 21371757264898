import {
  AccessTime as PendingIcon,
  CreateOutlined as EditIcon, Done as DoneIcon
} from '@material-ui/icons'
import { constants } from '@onerights/common'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ORQuery from '../../../../components/ORQuery'
import ADMIN_PRODUCTION_FILE_QUERY from '../../../../graphql/productions/admin/adminProductionFileQuery'
import ChatFeedback from '../../components/ChatFeedback'
import ProductionFileItemTracks from './ProductionFileItemTracks'

// Iterate over each track state
const states = [
  [constants.FETCH_FAILED, 'Failed fetching'],
  [constants.REPORT_SKIP, 'Skipped from report'],
  [constants.FEEDBACK_NEEDED, 'Ready for editor feedback'],
  [constants.FEEDBACK_REQUESTED, 'Feedback requested to editor'],
  [constants.FEEDBACK_GIVEN, 'Editor feedback given'],
  [constants.REPORT_READY, 'Ready to be reported'],
  [constants.REPORT_DONE, 'Already reported'],
]

const ProductionFileItem = ({
  id,
  filename,
  fetchStatus,
  status,
  onOpen,
  isOpen,
}) => {
  const mainClasses = classNames('production-item', { open: isOpen })
  // Select the status icon based in fetchStatus and status
  let StatusIcon = PendingIcon
  let iconTitle = 'Pending...'
  // If the ingestion fetch has completed but feedback status is:
  if (fetchStatus === 'DONE') {
    // 1: completed or not required we display a done icon
    if (status === constants.REPORT_DONE) {
      StatusIcon = DoneIcon
      iconTitle = 'Done'
    }
    // 2: Feedback from editor is requested
    if (status === constants.FEEDBACK_REQUESTED) {
      StatusIcon = EditIcon
      iconTitle = 'Awaiting editor feedback...'
    }
  }
  return (
    <article className={mainClasses}>
      <header>
        <div className="colp colp-2x" title={iconTitle}>
          {filename} <StatusIcon />
        </div>
        <div className="colp">Fetch-Status: {fetchStatus}</div>
        <div className="colp colp-2x">Status: {status}</div>
        <div className="colp colp-has-btn">
          <button
            type="button"
            data-id={id}
            onClick={onOpen}
            className="btn w100"
          >
            {!isOpen ? 'Open' : 'Close'}
          </button>
        </div>
      </header>
      {isOpen && (
        <div className="production-item-content">
          <ORQuery
            query={ADMIN_PRODUCTION_FILE_QUERY}
            variables={{ fileId: id }}
            isPrivate
          >
            {({ data }) => {
              const { tracks, notes } = data.productionFile

              return states.map(([state, label]) => {
                const items = tracks.filter(
                  t => t.status.toLowerCase() === state
                )
                if (items.length === 0) return null

                return (
                  <React.Fragment key={label}>
                    <ChatFeedback title="Editor notes for file" notes={notes} />
                    <ProductionFileItemTracks
                      label={label}
                      items={items}
                      fileId={id}
                    />
                  </React.Fragment>
                )
              })
            }}
          </ORQuery>
        </div>
      )}
    </article>
  )
}

ProductionFileItem.propTypes = {
  id: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  fetchStatus: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
}

ProductionFileItem.defaultProps = {
  isOpen: false,
}

export default ProductionFileItem
