import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withFormik } from 'formik'
import Input from '../../../../components/input'
import Select from '../../../../components/Select'

export const InviteMemberForm = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  isValid,
  dirty,
  isSubmitting,
}) => (
  <form className="userbasic" onSubmit={handleSubmit}>
    <Input
      label="Full name"
      name="name"
      id="i1"
      value={values.name}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.name}
      required
    />
    <Input
      label="Email"
      name="email"
      type="email"
      id="i1"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.email}
      required
    />
    <Select
      name="role"
      selected={values.role}
      options={[
        { value: 'member', label: 'Member' },
        { value: 'admin', label: 'Admin' },
      ]}
      onChange={handleChange}
      required
    />
    {dirty && (
      <button
        disabled={!isValid}
        type="submit"
        className="btn btn-icon btninvite right green"
      >
        {isSubmitting ? 'Sending invite...' : 'Send Invite'}
      </button>
    )}
  </form>
)

InviteMemberForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
  }),
  errors: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
  }),
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}
InviteMemberForm.defaultProps = {
  values: {
    name: '',
    email: '',
    role: '',
  },
  errors: {
    name: '',
    email: '',
    role: '',
  },
}

const inviteMemberOrganizationMutation = gql(`
  mutation OrganizationInviteMember($member: OrganizationMemberInput!) {
    organizationInviteMember(member: $member) {
      email
      name
    }
  }
`)

export default compose(
  graphql(inviteMemberOrganizationMutation),
  withFormik({
    handleSubmit: async (
      values,
      { props, setFieldError, resetForm, setSubmitting }
    ) => {
      try {
        setSubmitting(true)
        await props.mutate({ variables: { member: values } })
        setSubmitting(false)
        props.onSuccess(
          `An invitation to ${values.email} to join has been sent successfully!`
        )
        resetForm()
      } catch (e) {
        setSubmitting(false)
        if (e.graphQLErrors) {
          e.graphQLErrors.forEach(_e => {
            if (
              _e.data &&
              _e.data.code &&
              _e.data.code === 'USER_EMAIL_IN_USE'
            ) {
              setFieldError('email', _e.message)
            }
          })
        }
        props.onError(e)
      }
    },
    mapPropsToValues: () => ({ role: 'member' }),
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .min(3)
        .required(),
      email: yup
        .string()
        .email()
        .required(),
      role: yup.string().required(),
    }),
  })
)(InviteMemberForm)
