import gql from 'graphql-tag'

export default gql(`
  query ProductionFileQuery($fileId: ID!) {
    productionFile(fileId: $fileId) {
      id
      filename
      notes {
        id
        sender {
          name
        }
        text
        createdAt
      }
      tracks {
        id
        title
        filename
        useType
        status
      }
    }
  }
`)
