import React from 'react'
import PropTypes from 'prop-types'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { withFormik } from 'formik'
import Input from '../../../../components/input'
import Select from '../../../../components/Select'
import Loader from '../../../../components/Loader'

export const OrganizationInfoForm = ({
  data,
  values,
  errors,
  isValid,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  dirty,
}) => {
  if (data.loading) {
    return <Loader />
  }
  return (
    <form className="userbasic" onSubmit={handleSubmit}>
      <Input
        label="Company name"
        name="name"
        value={values.name}
        id="i1"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.name}
        required
      />
      <Input
        label="CVR"
        name="cvr"
        value={values.cvr}
        id="i2"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.cvr}
        required
      />
      <Input
        label="Zip"
        name="zip"
        value={values.zip}
        id="i3"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.zip}
        required
      />
      <Input
        label="Address"
        name="address"
        value={values.address}
        id="i5"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.address}
        required
      />
      <Select
        name="country"
        selected={values.country}
        options={data.countries.map(c => ({
          label: c.displayTitle,
          value: c.alpha2Code,
        }))}
        onChange={handleChange}
      />
      {dirty && (
        <div style={{ display: 'inline-block', width: '100%' }}>
          <button
            disabled={!isValid}
            type="submit"
            style={{ marginTop: 0 }}
            className="btn btn-icon btnsave right black"
          >
            {isSubmitting ? 'SAVING...' : 'SAVE CHANGES'}
          </button>
        </div>
      )}
    </form>
  )
}

OrganizationInfoForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    cvr: PropTypes.string,
    zip: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    cvr: PropTypes.string,
    zip: PropTypes.string,
    address: PropTypes.string,
  }),
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  data: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        displayTitle: PropTypes.string.isRequired,
        alpha2Code: PropTypes.string.isRequired,
      })
    ),
  }),
}
OrganizationInfoForm.defaultProps = {
  errors: {
    name: '',
    cvr: '',
    zip: '',
    address: '',
    country: '',
  },
  data: {
    name: '',
    cvr: '',
    zip: '',
    address: '',
    country: '',
    countries: [],
  },
}

const updateOrganizationInfo = gql(`
  mutation OrganizationUpdateInfo($info: OrganizationInfoInput!) {
    organizationUpdateInfo(organizationInfo: $info) {
      name
      cvr
      address
      zip
    }
  }
`)

const countriesQuery = gql(`
  query getCountriesForSelector {
    countries {
      id
      displayTitle
      alpha2Code
    }
  }
`)

export default compose(
  graphql(updateOrganizationInfo),
  graphql(countriesQuery),
  withFormik({
    mapPropsToValues: ({ organization }) => ({
      name: organization.name,
      cvr: organization.cvr,
      address: organization.address,
      zip: organization.zip,
      country: organization.country && organization.country.alpha2Code,
    }),
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .min(3)
        .max(30)
        .required(),
      cvr: yup
        .string()
        .min(3)
        .max(10)
        .required(),
      address: yup
        .string()
        .min(3)
        .max(60)
        .required(),
      zip: yup
        .string()
        .min(4)
        .max(5)
        .required(),
      country: yup
        .string()
        .min(2)
        .max(2)
        .required(),
    }),
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
      try {
        setSubmitting(true)
        await props.mutate({ variables: { info: values } })
        setSubmitting(false)
        props.onSuccess('Changes saved!')
        resetForm(values)
      } catch (e) {
        setSubmitting(false)
        props.onError(e)
      }
    },
  })
)(OrganizationInfoForm)
