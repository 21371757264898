import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FiltersProvider } from './connect'
import { getFiltersVariables } from '../../utils/searchHelpers'

import {
  AddSearchFilter,
  RemoveSearchFilter,
  AddInitFiltersFromUrl,
  ResetSearch,
  ChangeSort,
} from '../../actions/searchActions'

class SearchFiltersWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    this.props.dispatch(AddInitFiltersFromUrl(this.props.location.search))
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps.filters, this.props.filters) || nextProps.sort !== this.props.sort
  }

  componentWillReceiveProps(nextProps) {
    // if the path location changes (changed to other route)
    // we must reset the filters selected
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.dispatch(ResetSearch())
    }
  }

  /**
   * Filter has been selected/added
   * @param  {string} name filter key name
   * @param  {string|number|object} value value of filter
   * @param  {DOM} displayTagLabel html to be displayed for the filter
   */
  onFilterAdded = ({ name, value, displayTagLabel }) => {
    const { dispatch, location } = this.props
    dispatch(AddSearchFilter(location.pathname, { name, value, displayTagLabel }));
  }

  /**
   * Filter has been deselected/removed
   * @param  {string} name key name of the filter
   */
  onFilterRemoved = ({ name }) => {
    const { dispatch, location } = this.props;
    dispatch(RemoveSearchFilter(location.pathname, { name }));
  }

  setFilter = (name, value, displayTagLabel) => {
    return value
      ? this.onFilterAdded({ name, value, displayTagLabel })
      : this.onFilterRemoved({ name })
  }

  handleFilterChange = (e) => {
    const { name, value } = e.target
    this.setFilter(name, value)
  }

  handleYearRangeChange = (dates) => {
    let displayTagLabel
    if (dates.from === dates.until) {
      displayTagLabel = `All Releases <span>from</span> ${dates.from}`
    }
    else if (dates.from !== '') {
      displayTagLabel = `Releases <span>between</span> ${dates.from} <span>and</span> ${dates.until}`
    }
    else {
      displayTagLabel = `All Releases <span>until</span> ${dates.until}`
    }
    const name = 'yearRange'
    return (dates.from !== '' || dates.until !== '') ? this.setFilter(name, dates, displayTagLabel) : this.setFilter(name)
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target
    if (e.target.checked) {
      const displayTagLabel = e.target.dataset.displaylabel
      this.setFilter(name, true, displayTagLabel)
    } else {
      this.setFilter(name)
    }
  }

  changeSort = (sortedBy) => {
    const { dispatch } = this.props
    dispatch(ChangeSort(sortedBy))
  }

  handleSliderChange = _.debounce((name, [min, max], defaultValues) => {
    // delete the filter if the values selected again are the defaults
    const minSelected = parseInt(min, 10)
    const maxSelected = parseInt(max, 10)
    // If the selected values are exactly the same as the initials, we reset this filter.
    if (defaultValues.min === minSelected && defaultValues.max === maxSelected) {
      this.setFilter(name)
    } else if (defaultValues.min < minSelected || defaultValues.max > maxSelected) {
      this.setFilter(name, {
        min: `${minSelected}|${defaultValues.min}`,
        max: `${maxSelected}|${defaultValues.max}`,
      })
    }
  }, 500)

  getFiltersForQuery = () => {
    const { filters } = this.props
    return getFiltersVariables(filters)
  }

  getFiltersBag = () => {
    const { filters, sort } = this.props
    return {
      handleFilterChange: this.handleFilterChange,
      handleYearRangeChange: this.handleYearRangeChange,
      handleCheckboxChange: this.handleCheckboxChange,
      handleSliderChange: this.handleSliderChange,
      filtersQuery: this.getFiltersForQuery(), // pretty version of filters selected useful in apollo queries
      filtersSelected: filters,
      changeSort: this.changeSort,
      sort,
    }
  }

  getFiltersContext = () => {
    return {
      ...this.getFiltersBag(),
    }
  }

  render() {
    const { children } = this.props
    const props = this.getFiltersBag()
    return (
      <FiltersProvider value={this.getFiltersContext()}>
        <React.Fragment>
          {children((props))}
        </React.Fragment>
      </FiltersProvider>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.getIn(['search', 'filters']).toJS(),
  sort: state.getIn(['search', 'sort']),
})

export default compose(
  withRouter,
  connect(mapStateToProps),
)(SearchFiltersWrapper)
