import React from 'react'

const FeedbackRequestThanks = () => (
  <section className="c fcom">
    <svg
      id="checkmark-svg"
      className="run-animation"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 193.3 193.3"
    >
      <circle className="cls-1 circle" cx="96.65" cy="96.65" r="94.15" />
      <polyline
        className="cls-1 checkmark"
        points="46.9 101.4 76.9 131.4 146.4 61.9"
      />
    </svg>
    <h3>Thanks, your feedback has been sent!</h3>

    <p>You{"'"}ll be notified when the project have been finalized.</p>
  </section>
)

export default FeedbackRequestThanks
