import { isTokenExpired } from '../utils/jwtHelper'

export default class AuthService {
  isTokenValid() {
    const token = this.getToken()
    if (!token) return false
    return isTokenExpired(token)
  }
  setToken(token) {
    localStorage.setItem('accessToken', token) // eslint-disable-line class-methods-use-this
  }
  getToken() {
    return localStorage.getItem('accessToken') // eslint-disable-line class-methods-use-this
  }
  deleteToken() {
    localStorage.removeItem('accessToken') // eslint-disable-line class-methods-use-this
  }
  loggedIn() {
    const expired = this.isTokenValid()
    if (expired) {
      this.deleteToken()
    }
    return this.getToken() !== null && !expired
  }
  logOut() {
    this.deleteToken()
  }
}

export const auth = new AuthService()
