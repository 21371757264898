import React from 'react'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { NavLink, Link } from 'react-router-dom'
import ExperimentalFeature from '../ExperimentalFeature'
import AdminContent from '../AdminContent'
import Logo from '../../assets/img/logo-new.svg'

const Nav = props => {
  if (props.data.loading) return null
  const profile = props.data.me
  return (
    <nav>
      <ul>
        {/* <!-- Left side Menu --> */}
        <li id="logomargin">
          <img src={Logo} alt="OneRights official logo" />
        </li>
        <li>
          <NavLink
            to="/playlist/5b516312e139e70033ba1a16/grid"
            exact
            activeClassName="active"
          >
            <h5>NEW RELEASES</h5>
          </NavLink>
        </li>
        <li>
          <NavLink to="/ranks" exact activeClassName="active">
            <h5>DANISH HITS</h5>
          </NavLink>
        </li>
        <li>
          <NavLink to="/playlists" exact activeClassName="active">
            <h5>PLAYLISTS</h5>
          </NavLink>
        </li>
        <li className="nav-dropdown">
          <h5>LISTS</h5>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M4.516 7.548c.436-.446 1.043-.48 1.576 0L10 11.295l3.908-3.747c.533-.48 1.14-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502-.217.223-.502.335-.787.335s-.57-.112-.79-.335c0 0-4.286-4.084-4.694-4.502-.41-.418-.436-1.17 0-1.615z" />
          </svg>
          <ul id="menu">
            <li>
              <NavLink to="/artists/list/dk">
                <p>Danish Artists</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/artists/list/se">
                <p>Swedish Artists</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/artists/list/no">
                <p>Norwegian Artists</p>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/search/browse" exact activeClassName="active">
            <h5>BROWSE</h5>
          </NavLink>
        </li>
        <li className="nav-dropdown">
          <h5>SEARCH</h5>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M4.516 7.548c.436-.446 1.043-.48 1.576 0L10 11.295l3.908-3.747c.533-.48 1.14-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502-.217.223-.502.335-.787.335s-.57-.112-.79-.335c0 0-4.286-4.084-4.694-4.502-.41-.418-.436-1.17 0-1.615z" />
          </svg>
          <ul id="menu">
            <li>
              <NavLink to="/search/album">
                <p>ALBUM</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/search/track">
                <p>TRACK</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/search/artist">
                <p>ARTIST</p>
              </NavLink>
            </li>
          </ul>
        </li>
        <ExperimentalFeature feature="sensitive-placements">
          <li>
            <NavLink to="/sensitive-placements" exact activeClassName="active">
              <h5>SENSITIVE PLACEMENTS</h5>
            </NavLink>
          </li>
        </ExperimentalFeature>
      </ul>
      {/* <!-- RIGHT SIDE MENU --> */}
      <ul>
        <li>
          <div id="dd">
            <img
              id="partnerlogo"
              src={profile.organization.logo}
              alt={`${profile.organization.name} logo`}
            />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M4.516 7.548c.436-.446 1.043-.48 1.576 0L10 11.295l3.908-3.747c.533-.48 1.14-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502-.217.223-.502.335-.787.335s-.57-.112-.79-.335c0 0-4.286-4.084-4.694-4.502-.41-.418-.436-1.17 0-1.615z" />
            </svg>
          </div>
          <ul id="user">
            <li>
              <NavLink to="/me/info">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5 11h-11c-.275 0-.5.225-.5.5v1c0 .276.225.5.5.5h11c.276 0 .5-.224.5-.5v-1c0-.275-.224-.5-.5-.5zm0-4h-11c-.275 0-.5.225-.5.5v1c0 .276.225.5.5.5h11c.276 0 .5-.224.5-.5v-1c0-.275-.224-.5-.5-.5zm-5 8h-6c-.275 0-.5.225-.5.5v1c0 .276.225.5.5.5h6c.276 0 .5-.224.5-.5v-1c0-.275-.224-.5-.5-.5zm5-12h-11c-.275 0-.5.225-.5.5v1c0 .276.225.5.5.5h11c.276 0 .5-.224.5-.5v-1c0-.275-.224-.5-.5-.5z"
                  />
                </svg>
                <p>Basic Information</p>
              </NavLink>
            </li>
            <ExperimentalFeature feature="ingestion">
              <li>
                <NavLink to="/ingestion">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.726 12.64c-.843 1.364-3.535 2.362-6.726 2.362-3.19 0-5.883-.998-6.727-2.36-.178-.29-.273-.136-.273.006v2.002c0 1.94 3.134 3.95 7 3.95s7-2.01 7-3.95v-2c0-.144-.096-.3-.274-.01zm.01-5.115c-.83 1.205-3.53 2.09-6.736 2.09-3.205 0-5.908-.885-6.738-2.09C3.092 7.277 3 7.412 3 7.523V9.88c0 1.762 3.134 3.19 7 3.19s7-1.43 7-3.19V7.523c0-.11-.092-.246-.263.002zM10 1C6.134 1 3 2.18 3 3.633v1.26c0 1.54 3.134 2.79 7 2.79s7-1.25 7-2.79v-1.26C17 2.18 13.866 1 10 1z" />
                  </svg>
                  <p>Ingestion</p>
                </NavLink>
              </li>
            </ExperimentalFeature>
            {profile.organization.role === 'admin' && (
              <li>
                <NavLink to="/me/users">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M15.99 19.13c0-2.247-2.188-3.39-4.318-4.308-2.123-.914-2.8-1.684-2.8-3.334 0-.99.647-.667.93-2.48.12-.753.693-.013.803-1.73 0-.684-.313-.854-.313-.854s.16-1.013.22-1.793c.065-.816-.397-2.56-2.3-3.094-.332-.34-.557-.882.467-1.424C6.44.008 5.917 1.18 4.724 2.042c-1.015.756-1.29 1.953-1.24 2.59.065.78.223 1.793.223 1.793s-.314.17-.314.854c.11 1.717.684.976.803 1.728.284 1.814.933 1.492.933 2.48 0 1.65-.212 2.21-2.336 3.125C.664 15.53 0 17 .01 19.13.015 19.765 0 20 0 20h16s-.01-.234-.01-.87zm2.538-5.765c-1.135-.457-1.605-1.002-1.605-2.066 0-.642.418-.433.602-1.604.077-.484.447-.008.518-1.115 0-.44-.202-.55-.202-.55s.104-.656.144-1.16c.05-.626-.364-2.246-2.268-2.246-1.903 0-2.318 1.62-2.27 2.247.043.503.145 1.16.145 1.16s-.2.11-.2.55c.07 1.108.44.632.517 1.116.184 1.172.602.963.602 1.603 0 1.063-.44 1.56-1.81 2.15-.07.03-.12.07-.183.103 1.64.712 4.226 1.94 4.838 4.447H20v-2.318c0-1-.273-1.834-1.472-2.317z" />
                  </svg>
                  <p>Users</p>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/logout">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M19 10l-6-5v3H6v4h7v3l6-5zM3 3h8V1H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H3V3z" />
                </svg>
                <p>Log Out</p>
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  )
}

Nav.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    me: PropTypes.shape({
      name: PropTypes.string.isRequired,
      organization: PropTypes.shape({
        logo: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}

const profileQuery = gql(`
  query Profile {
    me {
      name
      organization {
        name
        logo
        role
      }
    }
  }
`)

export default graphql(profileQuery)(Nav)
