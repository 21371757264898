import React from 'react'
import PropTypes from 'prop-types'
import { Range as SliderRange, createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import YearRangeSelector from './YearRangeSelector'
import SearchGenresPicker from './SearchGenresPicker'
import { getFiltersFromObject } from '../../utils/searchHelpers'
import Select from '../Select'
import { connect } from './connect'

const Range = createSliderWithTooltip(SliderRange)

const ShowFilter = ({ show, children }) => show ? children : null

class SearchFilters extends React.Component {
  static defaultProps = {
    data: {},
  }

  state = {
    rangeValues: {
      artistPopularity: [0, 100],
      trackPopularity: [0, 100],
      bpm: [0, 100],
    },
  }

  componentDidMount() {
    this.updateRanges(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.updateRanges(nextProps)
  }

  /** Sync the props passed to the state */
  updateRanges(props) {
    const { data: filters, filters: { filtersSelected } } = props
    const filtersBase = getFiltersFromObject(filtersSelected, filters)
    // Attach the range selectors values if available to state
    const rangeValues = {}

    Array.prototype.forEach.call(['artistPopularity', 'trackPopularity', 'bpm'], propKey => {
      if (filtersBase.hasOwnProperty(propKey)) {
        rangeValues[propKey] = [
          filtersBase[propKey].minSelected,
          filtersBase[propKey].maxSelected,
        ]
      }
    })
    this.setState({ rangeValues })
  }

  /** Updates the selected range values in local state only */
  handleSliderLocalChange = (name, values) => {
    this.setState(state => {
      state.rangeValues[name] = values
      return state
    })
  }

  render() {
    const { rangeValues } = this.state
    const {
      filtersSelected,
      handleFilterChange,
      handleSliderChange,
      handleYearRangeChange,
    } = this.props.filters
    const { data: filters } = this.props
    const filtersBase = getFiltersFromObject(filtersSelected, filters)
    return (
      <div id="filters">
        <ul className="f">
          <ShowFilter show={filters.countries && filters.countries.length > 0}>
            <li>
              <label>Country Of Origin</label>
              <Select
                emptyValue="All"
                required
                name="country"
                selected={filtersBase.country}
                options={filters.countries}
                onChange={handleFilterChange}
              />
            </li>
          </ShowFilter>
          <ShowFilter show={filters.languages.length > 0}>
            <li>
              <label>Language</label>
              <Select
                emptyValue="All"
                required
                name="sungLanguage"
                selected={filtersBase.sungLanguage}
                options={filters.languages}
                onChange={handleFilterChange}
              />
            </li>
          </ShowFilter>
          <li>
            <label>Released between</label>
            <YearRangeSelector value={filtersBase.yearRange} onChange={handleYearRangeChange} />
          </li>
          <ShowFilter show={filters.genres.length > 0}>
            <li>
              <label>Genre</label>
              <SearchGenresPicker
                selected={filtersBase.genres}
                genres={filters.genres}
                onChange={(genres) => handleFilterChange({ target: { name: 'genres', value: genres } })}
              />
            </li>
          </ShowFilter>
          <ShowFilter show={filters.keys.length > 0}>
            <li>
              <label>Key</label>
              <Select
                emptyValue="All"
                required
                name="key"
                selected={filtersBase.key}
                options={filters.keys}
                onChange={handleFilterChange}
              />
            </li>
          </ShowFilter>
          <ShowFilter show={filtersBase.artistPopularity.min !== filtersBase.artistPopularity.max}>
            <li className="dottedline"/>
            <li>
              <label>
                Artist Popularity
                <span>Use the 2 sliders to change the result to filter in or out popular artists</span>
              </label>
              <div className="slider-wrapper">
                <Range
                  min={filtersBase.artistPopularity.min}
                  max={filtersBase.artistPopularity.max}
                  allowCross={false}
                  value={rangeValues.artistPopularity}
                  onChange={selected => this.handleSliderLocalChange('artistPopularity', selected)}
                  onAfterChange={selected => handleSliderChange('artistPopularity', selected, filtersBase.artistPopularity)}
                />
              </div>
            </li>
          </ShowFilter>
          <li></li>
          <ShowFilter show={filtersBase.trackPopularity.min !== filtersBase.trackPopularity.max}>
            <li>
              <label>
                Track Popularity
                <span>Use the 2 sliders to change the result to filter in or out popular tracks</span>
              </label>
              <div className="slider-wrapper">
                <Range
                  min={filtersBase.trackPopularity.min}
                  max={filtersBase.trackPopularity.max}
                  allowCross={false}
                  value={rangeValues.trackPopularity}
                  onChange={selected => this.handleSliderLocalChange('trackPopularity', selected)}
                  onAfterChange={selected => handleSliderChange('trackPopularity', selected, filtersBase.trackPopularity)}
                />
              </div>
            </li>
          </ShowFilter>
          <ShowFilter show={filtersBase.bpm.min !== filtersBase.bpm.max}>
            <li className="dottedline"/>
            <li>
              <label>
                BPM
                <span>Use the 2 sliders to change the result to filter in or out tracks</span>
              </label>
              <div className="slider-wrapper">
                <Range
                  min={filtersBase.bpm.min}
                  max={filtersBase.bpm.max}
                  allowCross={false}
                  value={rangeValues.bpm}
                  onChange={selected => this.handleSliderLocalChange('bpm', selected)}
                  onAfterChange={selected => handleSliderChange('bpm', selected, filtersBase.bpm)}
                />
              </div>
            </li>
          </ShowFilter>
        </ul>
      </div>
    )
  }
}

SearchFilters.defaultProps = {
  data: {
    countries: [],
    languages: [],
    genres: '',
    keys: [],
  },
}

SearchFilters.propTypes = {
  data: PropTypes.object,
  filters: PropTypes.shape({
    handleFilterChange: PropTypes.func.isRequired,
    handleSliderChange: PropTypes.func.isRequired,
    handleYearRangeChange: PropTypes.func.isRequired,
    sort: PropTypes.string.isRequired,
    filtersSelected: PropTypes.shape({
      artistPopularity: PropTypes.shape({
        min: PropTypes.string,
        max: PropTypes.string,
      }),
      trackPopularity: PropTypes.shape({
        min: PropTypes.string,
        max: PropTypes.string,
      }),
      country: PropTypes.string,
      sungLanguage: PropTypes.string,
      key: PropTypes.string,
      genre: PropTypes.string,
      bpm: PropTypes.shape({
        min: PropTypes.string,
        max: PropTypes.string,
      }),
      yearRange: PropTypes.shape({
        from: PropTypes.string,
        until: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default connect(SearchFilters)
