import { push } from 'react-router-redux'
import * as types from '../types/authTypes'

export const OnLogin = nextPath => dispatch => {
  dispatch({ type: types.LOGGED_IN })
  if (nextPath) {
    dispatch(push(nextPath))
  }
}

export const OnLogout = () => dispatch => {
  dispatch({ type: types.LOGGED_OUT })
  dispatch(push('/login'))
}

export default {
  OnLogin,
  OnLogout,
}
