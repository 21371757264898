import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class Input extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    autoComplete: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }
  static defaultProps = {
    autoComplete: '',
    required: false,
    type: 'text',
    error: '',
    value: '',
  }
  state = {
    isFocused: false,
  }

  onFocusOrBlur = () => {
    this.setState({ isFocused: !this.state.isFocused })
  }

  render() {
    const { label, type, error } = this.props

    const articleClass = classnames({
      active: this.state.isFocused,
    })
    const inputClass = classnames({
      warning: error.length > 0,
    })

    return (
      <article className={articleClass}>
        <label htmlFor={this.props.name}>{label}</label>
        <input
          className={inputClass}
          name={this.props.name}
          type={type}
          value={this.props.value}
          onFocus={this.onFocusOrBlur}
          onBlur={this.onFocusOrBlur}
          onChange={this.props.onChange}
          required={this.props.required}
          autoComplete={this.props.autocomplete}
        />
      </article>
    )
  }
}

export default Input
