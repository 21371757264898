import React from 'react'
import PropTypes from 'prop-types'
import DebounceInput from 'react-debounce-input'

const SearchInput = props => {
  function inputChange(e) {
    const { value } = e.target
    props.onChange(value)
  }

  return (
    <DebounceInput
      id="bigsrc"
      type="text"
      placeholder={props.placeholder}
      autoFocus
      onChange={inputChange}
      debounceTimeout={300}
      value={props.value || ''}
    />
  )
}

SearchInput.defaultProps = {
  placeholder: 'Search...',
  value: '',
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default SearchInput
