import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <main style={{ height: 'calc(100vh - 71px)' }} id="e404">
      <h5>OH NO!</h5>
      <h6>
        This properly wasn’t what you where looking for and it neither it was
        for us.
        <br />
        So lets go back and try again...
      </h6>
      <p>
        Pssst... We logged the error, so our highly skilled monkeys will look
        into this!
      </p>
      <Link to="/">
        <button className=" btngoback btn-icon black left">GO BACK</button>
      </Link>
    </main>
  )
}

export default NotFound
