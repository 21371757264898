import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AudioPlayer from '../audioplayer'
import Nav from '../nav'

const Header = props => {
  return (
    <header>
      {props.audioPlayerVisible && <AudioPlayer />}
      <Nav />
    </header>
  )
}

Header.propTypes = {
  audioPlayerVisible: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  audioPlayerVisible: state.getIn(['audioPlayer', 'visible']),
})

export default withRouter(connect(mapStateToProps)(Header))
