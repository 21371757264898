import React from 'react'
import PropTypes from 'prop-types'
import Album from '../Album'
import InfiniteList from './InfiniteListComponent'

const InfiniteAlbumList = ({ albums, fetchMore }) => (
  <InfiniteList
    cacheKey="albums"
    className="artists ar grid"
    fetchMore={fetchMore}
    items={albums}
    component={Album}
  />
)

InfiniteAlbumList.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  albums: PropTypes.shape({
    pageInfo: PropTypes.shape({
      nextPage: PropTypes.number.isRequired,
      hasNextPage: PropTypes.bool.isRequired,
    }).isRequired,
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.object.isRequired,
      }).isRequired,
    ),
  }).isRequired,
}

export default InfiniteAlbumList
