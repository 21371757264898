import React from 'react'
import { compose, graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar'
import PropTypes from 'prop-types'
import Header from '../components/header'
import bugsnagClient from '../services/bugsnag'
import Footer from '../components/footer'
import Loader from '../components/Loader'
import { OnLogin } from '../actions/authActions'
import ProfileQuery from '../graphql/ProfileQuery'

class Layout extends React.Component {
  static propTypes = {
    OnLogin: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    isLogged: PropTypes.bool.isRequired,
  }

  state = {
    authChecked: false,
  }

  renderNotLogged() {
    return (
      <>
        {this.props.children}
        <Footer />
      </>
    )
  }

  renderLogged() {
    return (
      <>
        <Header />
        {this.props.children}
        <Footer />
      </>
    )
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.fetchProfile.loading &&
      nextProps.fetchProfile.me &&
      !this.state.authChecked
    ) {
      // Store user logged info data in bugsnag client instance
      const { me: user } = nextProps.fetchProfile
      bugsnagClient.user = {
        id: user.id,
        name: user.name,
        organization: {
          id: user.organization.id,
          name: user.organization.name,
        },
      }

      this.props.OnLogin()
      this.setState({ authChecked: true })
    }
  }

  render() {
    if (this.props.fetchProfile.loading) return <Loader />
    return (
      <div>
        <LoadingBar
          style={{ backgroundColor: '#198cdb', height: '5px', zIndex: 2 }}
        />
        {this.props.isLogged ? this.renderLogged() : this.renderNotLogged()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLogged: state.get('auth').get('isLogged'),
})

export default compose(
  connect(
    mapStateToProps,
    { OnLogin }
  ),
  graphql(ProfileQuery, { name: 'fetchProfile' })
)(Layout)
