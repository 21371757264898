import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import SearchInput from './SearchInput'

import {
  SearchInputChanged,
  SearchInputQueryInit,
} from '../../../../actions/searchActions'

class SearchQuery extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    placeholder: '',
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(SearchInputQueryInit(this.props.location.search))
  }

  inputSearchChanged = (searchText) => {
    const { dispatch } = this.props
    dispatch(SearchInputChanged(searchText))
  }

  render() {
    const { children } = this.props
    return (
      <React.Fragment>
        <div id="searchgroup" className="trackandartist">
          <SearchInput placeholder={this.props.placeholder} onChange={this.inputSearchChanged} value={this.props.query} />
        </div>
        {children(({ query: this.props.query }))}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  query: state.getIn(['search', 'query']),
})

export default compose(
  withRouter,
  connect(mapStateToProps),
)(SearchQuery)
