import React from 'react'
import PropTypes from 'prop-types'
import withToggleState from './ToggleStateHOC'

const ItemList = ({ component, items, onItemOpen, checkOpen }) => {
  const Component = component // capitalize var name for react to render it as a component
  return items.map(i => (
    <Component key={i.id} onOpen={onItemOpen} isOpen={checkOpen(i.id)} {...i} />
  ))
}

ItemList.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  onItemOpen: PropTypes.func.isRequired,
  checkOpen: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default withToggleState(ItemList)
