import * as types from '../types/checkoutTypes'

export default function checkoutReducer(state = {}, action) {
  switch (action.type) {
    case types.DISPLAY_CHECKOUT: {
      return state.set('shown', true)
    }
    case types.HIDE_CHECKOUT: {
      return state.set('shown', false)
    }
    case types.TOGGLE_VISIBILITY_CHECKOUT: {
      return state.set('shown', !state.get('shown'))
    }
    default:
      return state
  }
}
