import React from 'react'

export const {
  Provider: FiltersProvider,
  Consumer: FiltersConsumer,
} = React.createContext()

export function connect(Comp) {
  return (props) => (
    <FiltersConsumer>
      {filters => <Comp {...props} filters={filters} />}
    </FiltersConsumer>
  )
}
