import React from 'react'
import { constants } from '@onerights/common'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import gql from 'graphql-tag'
import _ from 'lodash'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import InputField from '../../../../components/InputField'
import ORMutation from '../../../../components/ORMutation'
import ORQuery from '../../../../components/ORQuery'
import ADMIN_PRODUCTION_FILE_TRACK_QUERY from '../../../../graphql/productions/admin/adminProductionFileTrackQuery'
import ChatFeedback from '../../components/ChatFeedback'

const UPDATE_TRACK_MUTATION = gql(`
  mutation UpdateFileTrack($id: ID!, $input: FileTrackInput!) {
    updateFileTrack(trackId: $id, trackInput: $input) {
      id
      title
      filename
      composers
      album
      trackNumber
      publishers
      label
      useType
      source
      status
      notes {
        id
        sender {
          name
        }
        text
        createdAt
      }
    }
  }
`)

const PRODUCTION_FILE_TRACK_STATUS_MUTATION = gql(`
  mutation SetProductionFileTrackStatus($id: ID!, $status: ProductionTrackStatus!) {
    setFileTrackStatus(trackId: $id, status: $status) {
      id
      status
      file {
        id
        status
        production {
          id
          status
        }
      }
    }
  }
`)

/**
 * Wraps the funcionality of updating the track status
 */
const TrackStatusUpdaterBtn = ({
  isDirty,
  isLoading,
  id,
  status,
  setStatus,
  onSetStatus,
  skipInStatuses = [],
  children,
}) => {
  if (skipInStatuses.includes(status)) return null
  return (
    <button
      disabled={isDirty || isLoading}
      type="button"
      onClick={() =>
        onSetStatus({
          variables: {
            id,
            status: setStatus,
          },
        })
      }
      className="btn"
    >
      {children}
    </button>
  )
}

const ProductionFileTrackItem = ({
  id,
  fileId,
  status,
  filename,
  onOpen,
  isOpen,
}) => {
  const mainClasses = classNames('production-item', 'production-item-track', {
    open: isOpen,
  })
  return (
    <article className={mainClasses}>
      <header>
        <div
          className="colp colp-2x"
          style={{ justifyContent: 'flex-start', padding: '0 15px' }}
        >
          {filename}
        </div>
        <div className="colp colp-has-btn black" style={{ maxWidth: '150px' }}>
          <button
            type="button"
            data-id={id}
            onClick={onOpen}
            className="btn w100"
          >
            {!isOpen ? 'Open' : 'Close'}
          </button>
        </div>
      </header>
      {isOpen && (
        <ORQuery
          query={ADMIN_PRODUCTION_FILE_TRACK_QUERY}
          variables={{ trackId: id, fileId }}
          isPrivate
        >
          {({ data }) => {
            const {
              composers,
              duration,
              title,
              album,
              publishers,
              label,
              trackNumber,
              notes,
              useType,
              source,
            } = data.productionFileTrack
            const CAN_BE_EDITED = !['FEEDBACK_REQUESTED', 'REPORTED'].includes(
              status
            )
            return (
              <div className="production-item-content">
                <ORMutation isPrivate mutation={UPDATE_TRACK_MUTATION}>
                  {updateTrack => (
                    <Formik
                      initialValues={{
                        composers,
                        duration,
                        title,
                        album,
                        publishers,
                        label,
                        trackNumber,
                        notes,
                        useType,
                        source,
                      }}
                      validationSchema={yup.object().shape({
                        useType: yup.string().required(),
                        title: yup.string().required(),
                        composers: yup.string().required(),
                        album: yup.string(),
                        source: yup.string().oneOf(['or', 'upm', 'upright', 'discovery', '']),
                        publishers: yup.string(),
                        label: yup.string(),
                        trackNumber: yup.string(),
                        note: yup.string(),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        try {
                          setSubmitting(true)
                          await updateTrack({
                            variables: {
                              id,
                              input: _.pick(values, [
                                'composers',
                                'title',
                                'useType',
                                'source',
                                'album',
                                'publishers',
                                'label',
                                'trackNumber',
                                'note',
                              ]),
                            },
                          })
                          setSubmitting(false)
                          resetForm()
                        } catch (e) {
                          setSubmitting(false)
                        }
                      }}
                      render={({ isSubmitting, isValid, dirty }) => (
                        <Form>
                          <section className="c">
                            <ChatFeedback notes={notes} />
                            <div className="userbasic">
                              <Field
                                label="Usage Type"
                                vertical
                                type="select"
                                name="useType"
                                required
                                component={InputField}
                                disabled={!CAN_BE_EDITED}
                                options={[
                                  'BACKGROUND',
                                  'EM',
                                  'BEM',
                                  'KAT',
                                  'SIG',
                                  'BUMP',
                                  'GT',
                                  'LIV',
                                  'VB',
                                ]}
                              />
                              <Field
                                label="Source (Production Company)"
                                vertical
                                type="select"
                                name="source"
                                required
                                component={InputField}
                                disabled={!CAN_BE_EDITED}
                                options={[
                                  { value: 'or', label: 'OneRights' },
                                  { value: 'upm', label: 'Universal Production Music' },
                                  { value: 'upright', label: 'Upright' },
                                  { value: 'discovery', label: 'Discovery' },
                                ]}
                              />
                              <Field
                                label="Duration (seconds)"
                                vertical
                                name="duration"
                                disabled
                                component={InputField}
                              />
                            </div>
                            <div className="userbasic">
                              <Field
                                label="Composers"
                                vertical
                                name="composers"
                                required
                                disabled={!CAN_BE_EDITED}
                                component={InputField}
                              />
                              <Field
                                label="Publishers"
                                vertical
                                name="publishers"
                                required
                                disabled={!CAN_BE_EDITED}
                                component={InputField}
                              />
                              <Field
                                label="Title"
                                vertical
                                name="title"
                                required
                                disabled={!CAN_BE_EDITED}
                                component={InputField}
                              />
                              <Field
                                label="Track Number"
                                vertical
                                name="trackNumber"
                                disabled={!CAN_BE_EDITED}
                                component={InputField}
                              />
                              <Field
                                label="Album"
                                vertical
                                name="album"
                                required
                                disabled={!CAN_BE_EDITED}
                                component={InputField}
                              />
                              <Field
                                label="Label"
                                vertical
                                name="label"
                                required
                                disabled={!CAN_BE_EDITED}
                                component={InputField}
                              />
                            </div>
                            <div className="userbasic">
                              <Field
                                label="Feedback to editor"
                                vertical
                                name="note"
                                placeholder="Write your note..."
                                disabled={!CAN_BE_EDITED}
                                component={InputField}
                              />
                            </div>
                          </section>
                          <section>
                            <button
                              disabled={!dirty || !isValid || isSubmitting}
                              type="submit"
                              className="btn w100"
                            >
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                          </section>
                          <ORMutation
                            isPrivate
                            mutation={PRODUCTION_FILE_TRACK_STATUS_MUTATION}
                          >
                            {(setStatus, { loading }) => (
                              <div className="production-item-controls">
                                <TrackStatusUpdaterBtn
                                  dirty={dirty}
                                  loading={loading}
                                  onSetStatus={setStatus}
                                  id={id}
                                  setStatus={constants.REPORT_SKIP.toUpperCase()}
                                  status={status}
                                  skipInStatuses={[
                                    constants.REPORT_DONE.toUpperCase(),
                                    constants.FEEDBACK_REQUESTED.toUpperCase(),
                                    constants.REPORT_SKIP.toUpperCase(),
                                  ]}
                                >
                                  Skip from Report
                                </TrackStatusUpdaterBtn>
                                <TrackStatusUpdaterBtn
                                  dirty={dirty}
                                  loading={loading}
                                  onSetStatus={setStatus}
                                  id={id}
                                  setStatus={constants.FEEDBACK_NEEDED.toUpperCase()}
                                  status={status}
                                  skipInStatuses={[
                                    constants.REPORT_DONE.toUpperCase(),
                                    constants.FEEDBACK_REQUESTED.toUpperCase(),
                                    constants.FEEDBACK_NEEDED.toUpperCase(),
                                  ]}
                                >
                                  Request Feedback
                                </TrackStatusUpdaterBtn>
                                <TrackStatusUpdaterBtn
                                  dirty={dirty}
                                  loading={loading}
                                  onSetStatus={setStatus}
                                  id={id}
                                  setStatus={constants.REPORT_READY.toUpperCase()}
                                  status={status}
                                  skipInStatuses={[
                                    constants.REPORT_READY.toUpperCase(),
                                    constants.REPORT_DONE.toUpperCase(),
                                  ]}
                                >
                                  Mark as ready
                                </TrackStatusUpdaterBtn>
                              </div>
                            )}
                          </ORMutation>
                        </Form>
                      )}
                    />
                  )}
                </ORMutation>
              </div>
            )
          }}
        </ORQuery>
      )}
    </article>
  )
}

ProductionFileTrackItem.propTypes = {
  id: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
}

ProductionFileTrackItem.defaultProps = {
  isOpen: false,
}

export default ProductionFileTrackItem
