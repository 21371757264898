import gql from 'graphql-tag'

export default gql(`
  query ProductionsQuery($done: Boolean!) {
    productions(done: $done) {
      id
      title
      contactPerson {
        name
        email
      }
    }
  }
`)
