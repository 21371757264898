import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { privateClient, client } from '../utils/Apollo'

/**
 * ApolloLoader is a wrapper in top of Apollo Mutation
 */
const ORMutation = ({
  mutation,
  variables,
  update,
  apollo,
  children,
  skipError,
  isPrivate,
}) => (
  <Mutation
    mutation={mutation}
    variables={variables}
    update={update}
    client={isPrivate ? privateClient : client}
    {...apollo}
  >
    {(fn, props) => {
      if (!props.loading && props.called && props.error && !skipError) return (<p>An unexpected error happened. Please try again later...</p>)
      return children(fn, props)
    }}
  </Mutation>
)

ORMutation.propTypes = {
  children: PropTypes.func.isRequired,
  skipError: PropTypes.bool, // Should the component skip displaying error message?
  update: PropTypes.func,
  mutation: PropTypes.object.isRequired,
  variables: PropTypes.object,
  apollo: PropTypes.object,
  isPrivate: PropTypes.bool, // run with private client?
}

ORMutation.defaultProps = {
  skipError: false,
  isPrivate: false,
  variables: {},
  apollo: {},
}

export default ORMutation
