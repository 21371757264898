import * as types from '../types/userTypes'

export const SetUser = user => dispatch => {
  dispatch({ type: types.SET_USER, user })
}

export const UnSetUser = () => dispatch => {
  dispatch({ type: types.UNSET_USER })
}

export default {
  SetUser,
  UnSetUser,
}
