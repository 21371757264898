import { combineReducers } from 'redux-immutable'
import { routerReducer } from 'react-router-redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import auth from './authReducer'
import audioPlayer from './audioPlayerReducer'
import notifications from './notificationsReducer'
import search from './searchReducer'
import trackDetail from './trackDetailReducer'
import checkout from './checkoutReducer'
import user from './userReducer'

export default combineReducers({
  search,
  trackDetail,
  notifications,
  audioPlayer,
  auth,
  checkout,
  user,
  // Third-party reducers
  router: routerReducer,
  loadingBar: loadingBarReducer,
})
