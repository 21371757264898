import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import Loader from '../../../components/Loader'
import InviteForm from './forms/InviteMemberForm'
import { ShowAlert, ShowSuccess } from '../../../actions/notificationActions'

const organizationMembersQuery = gql(`
  query OrganizationMembers {
    me {
      id
      organization {
        id
        name
        members {
          id
          name
          email
          role
        }
      }
    }
  }
`)

const deleteMemberMutation = gql(`
  mutation DeleteMember($organizationId: ID!, $memberId: ID!) {
    organizationDeleteMember(organizationId: $organizationId, memberId: $memberId) {
      deleted
    }
  }
`)

class UserUsersView extends Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    notifications: PropTypes.shape({
      ShowAlert: PropTypes.func.isRequired,
      ShowSuccess: PropTypes.func.isRequired,
    }).isRequired,
  }
  deleteMember = async memberId => {
    const { id, members } = this.props.data.me.organization
    const member = members.find(m => m.id === memberId)
    const confirmed = window.confirm(`Are you sure to delete [${member.name}]?`)
    if (confirmed) {
      try {
        await this.props.deleteMember({
          variables: { organizationId: id, memberId: member.id },
          update: (
            cache,
            {
              data: {
                organizationDeleteMember: { deleted },
              },
            }
          ) => {
            if (deleted) {
              const { me } = cache.readQuery({
                query: organizationMembersQuery,
              })
              const { organization } = me
              organization.members = organization.members.filter(
                m => m.id !== memberId
              )
              cache.writeQuery({
                query: organizationMembersQuery,
                data: { me },
              })
            }
          },
        })
        this.onSuccess('Deleted successfully!')
      } catch (e) {
        this.onError(e)
      }
    }
  }
  onError = error => {
    let errorMessage = 'An unexpected error happened'
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorMessage = error.graphQLErrors.map(({ message }) => message).join('')
    }
    this.props.notifications.ShowAlert(errorMessage)
  }

  onSuccess = message => {
    this.props.notifications.ShowSuccess(message)
  }
  renderUsers = () => {
    const { data } = this.props
    if (data.loading) return <Loader />
    const { members } = data.me.organization
    if (!members || members.length === 0) {
      return (
        <div>
          <p id="subinfo">
            No users joined the organization yet. Invite your company colleagues
            and they{"'"}ll appear here.
          </p>
        </div>
      )
    }
    return (
      <ul className="users">
        {members.map(member => (
          <li key={member.id}>
            <section>
              <p>
                {member.name}
                <span>
                  &nbsp;•&nbsp;{member.email}&nbsp;
                  {member.role === 'admin' ? ' (ADMIN)' : ''}
                </span>
              </p>
            </section>
            <section>
              <a onClick={() => this.deleteMember(member.id)}>
                <figure className="deleteuser" />
              </a>
            </section>
          </li>
        ))}
      </ul>
    )
  }
  render() {
    if (this.props.data.loading) {
      return <Loader />
    }
    return (
      <div>
        <h3 className="toppadding">Add user</h3>
        <p id="subinfo">
          Administrators can edit all about the company, whereas an employee are
          only able to purchase licenses on behalf ofthe company.
        </p>
        <InviteForm onError={this.onError} onSuccess={this.onSuccess} />
        <h3 className="toppadding">Users</h3>
        {this.renderUsers()}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  notifications: bindActionCreators({ ShowAlert, ShowSuccess }, dispatch),
})

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  graphql(organizationMembersQuery),
  graphql(deleteMemberMutation, { name: 'deleteMember' })
)(UserUsersView)
