import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { graphql, compose } from 'react-apollo'
import { formatBPM, formatTime } from '../../utils/Formatters'
import TrackDetailQuery from '../../graphql/TrackDetailQuery'

// Artworks DEMO
import PlayIcon from '../../assets/img/play.svg'
import PauseIcon from '../../assets/img/pause.svg'

// Import components
import AudioFeatures from './components/AudioFeatures'
import RelatedArtists from './components/RelatedArtists'

// Actions
import { Play } from '../../actions/audioPlayerActions'

class TrackDetailView extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    trackDetail: PropTypes.object.isRequired,
    trackPlaying: PropTypes.bool.isRequired,
    playerIsPaused: PropTypes.bool.isRequired,
  }

  state = {
    isPlayingMainTrack: false,
    relatedArtistsVisible: false,
  }

  /**
   * Main track has been clicked to play/pause
   */
  playMainTrack = () => {
    this.props.dispatch(Play(this.props.data.track))
  }

  loadRelatedTracks = () => {}

  loadRelatedArtists = isVisible => {
    if (isVisible === this.state.relatedArtistsVisible) return
    this.setState({ relatedArtistsVisible: isVisible })
  }

  _isTrackPlayed = () =>
    this.props.data.track.id === this.props.trackPlaying.id &&
    !this.props.playerIsPaused

  render() {
    const { data } = this.props
    if (!data || data.error) return <p>An error happened...</p>
    if (data.loading) return <p>Loading...</p>
    const { track } = data
    if (!track)
      return (
        <p>
          We apologyze but the track is not available in our catalogue anymore.
        </p>
      )
    return (
      <React.Fragment>
        <p id="breadcrumb">
          <a>Search</a>
          <span> &nbsp;{'//'}&nbsp;</span>
          {track.artists.map(artist => (
            <NavLink to={`/artist/${artist.id}`} key={artist.id}>{`${
              artist.displayName
            }`}</NavLink>
          ))}
          <span> &nbsp;{'//'}&nbsp;</span>
          <span>{track.displayTitle}</span>
        </p>
        <div id="trackinfo">
          <section>
            <figure className="tleft">
              <img
                src={_.get(track, 'album.images[1].url', null)}
                id="artwork"
                alt="presentation"
              />
              <a onClick={this.playMainTrack}>
                <img
                  src={this._isTrackPlayed() ? PauseIcon : PlayIcon}
                  alt="play track"
                  className="play"
                />
              </a>
              <span className="ol" />
            </figure>
            {track.downloadUrl && (
              <a
                href={track.downloadUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="btn btn-icon btndownload left green"
                >
                  Download MP3
                </button>
              </a>
            )}
          </section>
          <section id="trackmeta">
            <h1>{track.displayTitle}</h1>
            {track.artists.map(artist => (
              <NavLink to={`/artist/${artist.id}`} key={artist.id}>
                <h2>
                  {`${artist.displayName}`} <span>(view all releases)</span>
                </h2>
              </NavLink>
            ))}
            {track.spotifyAudioFeatures && (
              <p>
                BPM:<span>{formatBPM(track.spotifyAudioFeatures.tempo)}</span>
              </p>
            )}
            <p>
              DURATION:<span>{formatTime(track.durationMs)}</span>
            </p>
            <p>
              ISRC:<span>{track.isrc}</span>
            </p>
            <p>
              RELEASE DATE:<span>{track.album.releaseDate}</span>
            </p>
          </section>
        </div>
        <RelatedArtists
          onVisible={this.loadRelatedArtists}
          isVisible={this.state.relatedArtistsVisible}
          trackId={this.props.match.params.trackId}
        />
        <hr />
        {track.spotifyAudioFeatures && (
          <AudioFeatures features={track.spotifyAudioFeatures} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    trackDetail: state.get('trackDetail').toJS(),
    trackPlaying:
      state.getIn([
        'audioPlayer',
        'playlist',
        state.getIn(['audioPlayer', 'playingIndex']),
      ]) || false,
    playerIsPaused: state.getIn(['audioPlayer', 'isPaused']),
  }
}

export default compose(
  connect(mapStateToProps),
  graphql(TrackDetailQuery, {
    options: props => {
      return {
        notifyOnNetworkStatusChange: true,
        variables: {
          trackId: props.match.params.trackId,
        },
      }
    },
  })
)(TrackDetailView)
