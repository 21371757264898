import gql from 'graphql-tag'

export default gql(`
  query TrackDetailQuery($trackId: ID!) {
    track(trackId: $trackId) {
      id
      displayTitle
      durationMs
      isrc
      previewUrl
      streamUrl
      waveforms {
        datUrl
        imageUrl
      }
      downloadUrl
      spotifyAudioFeatures {
        acousticness
        danceability
        energy
        key
        loudness
        mode
        speechiness
        instrumentalness
        liveness
        valence
        tempo
        timeSignature
      }
      album {
        id
        releaseDate
        images {
          url
        }
      }
      artists {
        id
        displayName
      }
    }
  }
`)
