import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

const FeaturesQuery = gql(`
  query Profile {
    me {
      id
      organization {
        id
        experimentalFeatures
      }
    }
  }
`)

const ExperimentalFeature = ({ feature, children, ...props }) => (
  <Query query={FeaturesQuery}>
    {({ loading, error, data }) => {
      if (loading || error) return null
      if (
        !data ||
        !data.me ||
        !data.me.organization ||
        !data.me.organization.experimentalFeatures
      )
        return null
      // Make sure the feature passed is allowed for the organization via "experimentalFeatures"
      if (!data.me.organization.experimentalFeatures.includes(feature)) {
        return null
      }
      return React.cloneElement(children, props)
    }}
  </Query>
)

ExperimentalFeature.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ExperimentalFeature
