import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class NotificationItem extends Component {
  static propTypes = {
    // @required message to show on notification
    message: PropTypes.string.isRequired,
    // @required unique identifier to hide
    id: PropTypes.number.isRequired,
    // @required when notification ends
    onHide: PropTypes.func.isRequired,
    // @optional notification type to apply a theme. options are: alert, success
    // default is success
    type: PropTypes.string,
    // @optional time to show notification
    timeout: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
      show: true,
    }
  }

  componentDidMount() {
    const timeout = this.props.timeout || 1500
    this._interval = setTimeout(this._hide.bind(this), timeout)
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  _hide() {
    this.setState({ clicked: true, show: false })
    const interval = setTimeout(() => {
      this.props.onHide(this.props.id)
      clearInterval(interval)
    }, 100)
  }

  itemClicked = () => {
    if (!this.state.clicked) {
      this._hide()
    }
  }

  render() {
    // Set theme based on type of notification
    let theme = 'blue' // default blue is === type info
    const type = this.props.type ? this.props.type : 'success'
    if (type === 'alert') {
      theme = 'red'
    }
    if (type === 'success') {
      theme = 'green'
    }

    const elClass = classNames(theme, {
      slideup: this.state.show,
      slidedown: !this.state.show,
    })
    return (
      <div id="nbar" className={elClass} onClick={this.itemClicked}>
        <p dangerouslySetInnerHTML={{ __html: this.props.message }} />
      </div>
    )
  }
}

export default NotificationItem
