import qs from 'qs'

export const objectifyFilters = (filters = {}) => {
  const qsObj = {}
  Object.keys(filters).forEach(filterKey => {
    const keyVal = filters[filterKey]
    if (!keyVal) return
    qsObj[`fl[${filterKey}]`] = filters[filterKey]
  })
  return qsObj
}

export const parseFilters = str => {
  let query = ''
  if (str.startsWith('?')) query = str.substring(1)
  const obj = qs.parse(query)
  return obj.fl || {}
}

export const parseSearch = str => {
  let query = ''
  if (str.startsWith('?')) query = str.substring(1)
  const obj = qs.parse(query)
  return {
    filters: obj.fl || {},
    sort: obj.sort || '',
    query: obj.q || '',
  }
}

export default {
  objectifyFilters,
  parseFilters,
  parseSearch,
}
