import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

class YearRangeSelector extends Component {

  static propTypes = {
    minYear: PropTypes.string,
    maxYear: PropTypes.string,
    value: PropTypes.oneOfType(
      [
        PropTypes.shape({
          from: PropTypes.string.isRequired,
          until: PropTypes.string.isRequired,
        }),
        PropTypes.string,
      ],
    ),
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    minYear: '1900',
    maxYear: moment().format('YYYY'),
    value: {
      from: '',
      until: '',
    },
  }

  state = {
    minYear: this.props.minYear,
    maxYear: this.props.maxYear,
    fromSelected: this.props.value.from,
    untilSelected: this.props.value.until,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (nextState.fromSelected !== this.state.fromSelected || nextState.untilSelected !== this.state.untilSelected );
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.value)) {
      this.setState({ fromSelected: nextProps.value.from, untilSelected: nextProps.value.until })
    } else {
      this.reset();
    }
  }

  reset() {
    this.setState({
      fromSelected: '',
      untilSelected: '',
    });
  }

  getYears(from, to, skipBlank = false) {
    const years = [];
    const toInt = parseInt(to, 10);
    const fromInt = parseInt(from, 10);
    for (let i = fromInt; i <= toInt; i++) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    if (!skipBlank) years.push(<option key="empty" value="">All</option>);
    return years.reverse();
  }

  notifyChange() {
    this.props.onChange({ from: this.state.fromSelected, until: this.state.untilSelected });
  }

  selectionChanged = (e) => {
    const selectName = e.target.name;
    const selectYear = e.target.value;
    if (selectName === 'fromSelected') {
      if (selectYear <= this.state.untilSelected) {
        this.setState({ fromSelected: selectYear }, this.notifyChange);
      // if until is still blank, autoselect if with the same year as from
      } else if (this.state.untilSelected === '') {
        this.setState({ fromSelected: selectYear, untilSelected: selectYear, }, this.notifyChange);
      }
    }
    else if (selectName === 'untilSelected') {
      if (selectYear >= this.state.fromSelected) {
        this.setState({ untilSelected: selectYear }, this.notifyChange);
      }
      // blank option only appears if the releaseFrom is blank too.
      else if (this.state.fromSelected === '') {
        this.setState({ untilSelected: '' }, this.notifyChange);
      }
    }
  }

  render() {
    return (
      <div className="multiply">
          <div className="dropdown">
              <select required name="fromSelected" value={this.state.fromSelected} onChange={this.selectionChanged}>
                { this.getYears(this.state.minYear, this.state.untilSelected || this.state.maxYear) }
              </select>
          </div>
          <p>-</p>
          <div className="dropdown">
              <select required name="untilSelected" value={this.state.untilSelected} onChange={this.selectionChanged}>
                { this.getYears(this.state.fromSelected || this.state.minYear, this.state.maxYear, this.state.fromSelected !== '') }
              </select>
          </div>
      </div>
    )
  }
};

export default YearRangeSelector;
