import React from 'react'
import { Query } from 'react-apollo'
import Loader from '../../../components/Loader'
import { FiltersWrapper, Filters } from '../../../components/SearchFilters'
import BrowseSearchResults from './BrowseSearchResults'
import SEARCH_TRACKS_QUERY from '../../../graphql/SearchTracksQuery'

const BrowseSearchView = () => (
  <FiltersWrapper>
    {({ filtersQuery, sort, changeSort }) => {
      const queryVars = { ...filtersQuery }
      if (sort) queryVars.sort = sort
      return (
        <Query query={SEARCH_TRACKS_QUERY} variables={queryVars}>
          {({ loading, error, networkStatus, data, ...queryProps }) => {
            if (loading && networkStatus === 1) {
              return <Loader />
            }
            if (error) return <p>An unexpected error happened!</p>
            return (
              <div id="content" className="contentpush">
                <aside id="filters">
                  <h3>Filters</h3>
                  <Filters data={data.tracks.filters} />
                </aside>
                <div id="filteredcontent">
                  <header>
                    <h3>Sort by:</h3>
                    <div className="dropdown">
                      <select
                        name="sort"
                        required
                        defaultValue={sort || 'RELEASE_DATE_DESC'}
                        onChange={({ target: { value } }) => changeSort(value)}
                      >
                        <option value="POPULARITY_ASC">Least popular</option>
                        <option value="POPULARITY_DESC">Most popular</option>
                        <option value="RELEASE_DATE_ASC">Oldest release</option>
                        <option value="RELEASE_DATE_DESC">
                          Recently released
                        </option>
                      </select>
                    </div>
                  </header>
                  {loading && <Loader />}
                  {!loading && (
                    <BrowseSearchResults
                      filtersSelected={filtersQuery}
                      tracks={data.tracks}
                      {...queryProps}
                    />
                  )}
                </div>
              </div>
            )
          }}
        </Query>
      )
    }}
  </FiltersWrapper>
)

export default BrowseSearchView
