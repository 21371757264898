import gql from 'graphql-tag'

export default gql(`
  query TracksSearchQuery($trackTitle: String!, $size: Int = 50, $page: Int = 1) {
    tracks(title: $trackTitle, size: $size, page: $page) {
      pageInfo {
        totalCount
        hasNextPage
        nextPage
      }
      edges {
        node {
          id
          displayTitle,
          isrc,
          previewUrl,
          downloadUrl
          streamUrl
          waveforms {
            datUrl
            imageUrl
          }
          artists {
            id
            displayName,
            popularity,
          }
          album {
            id
            displayTitle,
            releaseDate,
            genres,
            upc
            images {
              url,
            }
          }
        }
      }
    }
  }
`)
