import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import DKFlag from '../../assets/img/flags/dk.svg'
import Loader from '../../components/Loader'
import TrackList from '../../components/trackList'

const TRACKS_RANK_QUERY = gql(`
  query TrackRanks($page: Int = 1, $size: Int = 15) {
    tracksRank(page: $page, size: $size) {
      pageInfo {
        nextPage
        hasNextPage
      }
      edges {
        node {
          id
          isrc
          displayTitle
          durationMs
          previewUrl
          downloadUrl
          streamUrl
          waveforms {
            datUrl
            imageUrl
          }
          album {
            id
            displayTitle
            releaseDate
            genres
            type
            upc
            images {
              url
            }
          }
          artists {
            id
            displayName
          }
        }
      }
    }
  }
`)

const TrackRankDetailView = () => (
  <div className="tracklistinfo singlepl">
    <section className="plfilters">
      <figure className="tleft">
        <img id="flag" src={DKFlag} alt="Denmark flag" />
      </figure>
    </section>
    <div className="tracklist">
      <Query query={TRACKS_RANK_QUERY}>
        {({ loading, networkStatus, error, data, fetchMore }) => {
          if (loading && networkStatus === 1) {
            return <Loader />
          }
          if (error) return <p>An error happened...</p>
          const { tracksRank } = data
          return <TrackList tracks={tracksRank} fetchMore={fetchMore} />
        }}
      </Query>
    </div>
  </div>
)

export default TrackRankDetailView
