import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import TrackList from '../../../components/trackList'

const BrowseSearchResults = ({ filtersSelected, tracks, ...props }) => {
  // if not filters has been selected, no result is displayed
  if (isEmpty(filtersSelected)) {
    return <p>Select some filters to see some results...</p>
  }
  if (tracks && tracks.edges && tracks.edges.length === 0) {
    return <p>There are no results with the filters supplied.</p>
  }
  return <TrackList tracks={tracks} {...props} />
}

BrowseSearchResults.propTypes = {
  filtersSelected: PropTypes.shape({
    artistPopularity: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }),
    trackPopularity: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }),
    country: PropTypes.string,
    sungLanguage: PropTypes.string,
    key: PropTypes.string,
    genre: PropTypes.string,
    bpm: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }),
    yearRange: PropTypes.shape({
      from: PropTypes.number,
      until: PropTypes.number,
    }),
  }).isRequired,
  tracks: PropTypes.object.isRequired,
}

export default BrowseSearchResults
