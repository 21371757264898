import React from 'react'
import PropTypes from 'prop-types'
import ORQuery from '../../../../components/ORQuery'
import ADMIN_PRODUCTIONS_QUERY from '../../../../graphql/productions/admin/adminProductionsQuery'
import ProductionItem from './ProductionItem'
import ItemList from './ItemList'

const ProductionItemList = ({ title, done }) => (
  <ORQuery query={ADMIN_PRODUCTIONS_QUERY} variables={{ done }} isPrivate>
    {({ data }) => {
      if (data && data.productions.length === 0) {
        return (
          <React.Fragment>
            <h2>{title}</h2>
            <p>No productions available to review yet.</p>
          </React.Fragment>
        )
      }
      const { productions } = data
      return (
        <React.Fragment>
          <h2>
            {title} ({productions.length})
          </h2>
          <ItemList items={productions} component={ProductionItem} />
        </React.Fragment>
      )
    }}
  </ORQuery>
)

ProductionItemList.defaultProps = {
  done: false,
}

ProductionItemList.propTypes = {
  title: PropTypes.string.isRequired,
  done: PropTypes.bool,
}

export default ProductionItemList
