import { Map, List, fromJS } from 'immutable'
import { auth } from './services/Auth'

export default Map(
  fromJS({
    search: {
      query: '',
      filters: {},
      sort: 'RELEASE_DATE_DESC',
    },
    audioPlayer: {
      visible: false,
      isPaused: true,
      isMuted: false,
      playlist: List(),
      playingIndex: null,
      volume: 50,
    },
    checkout: {
      shown: false,
    },
    auth: {
      isLogged: auth.loggedIn(),
    },
  })
)
