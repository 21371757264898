import React from 'react'
import ProductionItemList from './components/ProductionItemList'

const AdminProductionLists = () => (
  <div>
    <h1>Shows</h1>
    <ProductionItemList title="Undone" />
    <ProductionItemList done title="Finished" />
  </div>
)

export default AdminProductionLists
