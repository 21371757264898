import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import gql from 'graphql-tag'
import qs from 'qs'
import { compose, graphql } from 'react-apollo'
import AuthLayout from '../../../containers/AuthLayout'
import {
  ShowAlert,
  ShowSuccess,
  ShowMessage,
} from '../../../actions/notificationActions'
import Loader from '../../../components/Loader'
import InviteForm from './InviteInfoForm'

export class InviteView extends Component {
  static propTypes = {
    data: PropTypes.shape({
      organizationInvite: PropTypes.shape({
        registerRequired: PropTypes.bool.isRequired,
      }),
      error: PropTypes.shape({
        graphQLErrors: PropTypes.arrayOf(
          PropTypes.shape({
            message: PropTypes.string.isRequired,
            data: PropTypes.shape({
              code: PropTypes.string.isRequired,
            }).isRequired,
          })
        ),
      }),
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    notifications: PropTypes.shape({
      ShowAlert: PropTypes.func.isRequired,
      ShowSuccess: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    mutate: PropTypes.func.isRequired,
  }
  componentWillReceiveProps({ data }) {
    // check errors
    if (data.error && data.error.graphQLErrors) {
      // check if expired error
      const errors = data.error.graphQLErrors
      const filterExpiredError = errors.filter(
        e => e.data && e.data.code === 'ORG_INVITE_INVALID'
      )
      if (filterExpiredError.length > 0) {
        const [expiredError] = filterExpiredError
        this.props.notifications.ShowAlert(expiredError.message)
        this.props.router.push('/')
      } else {
        this.props.notifications.ShowAlert(errors[0].message)
      }
    }
  }
  onError = error => {
    let errorMessage = 'An unexpected error happened'
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorMessage = error.graphQLErrors.map(({ message }) => message).join('')
    }
    this.props.notifications.ShowAlert(errorMessage)
  }

  onSuccess = () => {
    this.props.notifications.ShowSuccess("You've joined successfully!")
    // redirect to login
    this.props.router.push('/')
  }
  acceptInvite = async member => {
    const { match, location } = this.props
    const params = qs.parse(location.search.substr(1))
    await this.props.mutate({
      variables: {
        id: match.params.inviteId,
        code: params.code,
        member,
      },
    })
    this.onSuccess()
  }
  renderFormOrButton = data => {
    const orgName = data.invite.organization.name
    if (data.registerRequired) {
      return (
        <div>
          <p>
            Join <b>{orgName}</b> on OneRights by completing your profile.
          </p>
          <InviteForm invite={data.invite} onAccept={this.acceptInvite} />
        </div>
      )
    }
    return (
      <div>
        <p>
          You{"'"}ve been invited to join <b>{orgName}</b> on OneRights.
        </p>
        <div style={{ textAlign: 'center' }}>
          <button
            onClick={this.acceptInvite.bind(this, null)}
            style={{ display: 'inline-block' }}
            className="btn btn-icon btninvite right green"
          >
            Accept Invite
          </button>
        </div>
      </div>
    )
  }
  render() {
    const { data } = this.props
    if (data.loading) {
      return <Loader />
    }
    if (!data.loading && data.error) {
      return null
    }
    return (
      <AuthLayout>
        <div id="logingroup">
          <img
            id="llogo"
            src={data.organizationInvite.invite.organization.logo}
            alt={`${data.organizationInvite.invite.organization.name} logo`}
          />
          {this.renderFormOrButton(data.organizationInvite)}
        </div>
      </AuthLayout>
    )
  }
}

const inviteQuery = gql(`
  query OrganizationInvite($id: ID!, $code: String!) {
    organizationInvite(id: $id, code: $code) {
      invite {
        id
        code
        expiresAt
        email
        name
        organization {
          name
          logo
        }
      }
      registerRequired
    }
  }
`)

const acceptInviteMutation = gql(`
  mutation OrganizationAcceptInvite($id: ID!, $code: String!, $member: UserRegisterInput) {
    organizationAcceptInvite(id: $id, code: $code, member: $member) {
      id
    }
  }
`)

const mapDispatchToProps = dispatch => ({
  router: bindActionCreators({ push }, dispatch),
  notifications: bindActionCreators(
    { ShowAlert, ShowSuccess, ShowMessage },
    dispatch
  ),
})

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  graphql(acceptInviteMutation),
  graphql(inviteQuery, {
    options: ({ location, match }) => {
      const params = qs.parse(location.search.substr(1))
      return {
        variables: {
          code: params.code,
          id: match.params.inviteId,
        },
      }
    },
  })
)(InviteView)
