import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import FeedbackRequestForm from './FeedbackRequestForm'
import FeedbackRequestThanks from './FeedbackRequestThanks'
import { Fragment } from 'react'

class FeedbackRequest extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        feedbackId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  }

  state = {
    isDone: false,
  }

  onComplete = () => {
    this.setState({ isDone: true })
  }

  render() {
    const {
      match: {
        params: { feedbackId },
      },
      location: { search },
    } = this.props
    // parse the token from the url
    const { t: token } = qs.parse(search.replace('?', ''))

    return (
      <Fragment>
        <figure className="fbc" />
        <main id="content" className="ingestion">
          {!this.state.isDone ? (
            <FeedbackRequestForm
              feedbackId={feedbackId}
              token={token}
              onComplete={this.onComplete}
            />
          ) : (
            <FeedbackRequestThanks />
          )}
        </main>
      </Fragment>
    )
  }
}

export default FeedbackRequest
