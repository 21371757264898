import React from 'react'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { graphql, compose } from 'react-apollo'
import IngestionForm from './IngestionForm'
import Loader from '../../../components/Loader'

const UserInfoQuery = gql(`
  query UserInfoQuery {
    me {
      id
      email
      name
      organization {
        id
        name
        role
        cvr
        address
        city
        zip
        country {
          displayTitle
          alpha2Code
        }
      }
    }
  }
`)

const IngestionSubmitView = ({ userInfo }) => {
  if (userInfo && userInfo.loading) return <Loader />
  return <IngestionForm user={userInfo.me} />
}

IngestionSubmitView.propTypes = {
  userInfo: propType(UserInfoQuery).isRequired,
}

export default compose(graphql(UserInfoQuery, { name: 'userInfo' }))(
  IngestionSubmitView
)
