import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { auth } from '../services/Auth'

const LOGIN_PATH = '/login'

const buildRedirectUrl = (loginPath, nextPath) => {
  if (!nextPath || nextPath === '/') return loginPath
  return `${loginPath}?next=${nextPath}`
}

const AuthRoute = ({ component: Component, isLogged, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLogged ? (
        <Component {...props} />
      ) : (
        <Redirect to={buildRedirectUrl(LOGIN_PATH, props.location.pathname)} />
      )
    }
  />
)

AuthRoute.defaultProps = {
  isLogged: false,
}

AuthRoute.propTypes = {
  isLogged: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
}

const mapStateToProps = state => ({
  isLogged: state.get('auth').get('isLogged'),
})
export default connect(mapStateToProps)(AuthRoute)
