import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Loader from '../../components/Loader'
import CloseIcon from '../../assets/img/close.svg'
import PlaylistList from './PlaylistList'

const PLAYLISTS_QUERY = gql(`
  query PlaylistsQuery {
    playlists {
      edges {
        node {
          id
          name
          category
          cover
          order
          totalTracks
        }
      }
    }
  }
`)

class BetaNotice extends React.Component {
  state = {
    isVisible: false,
  }
  componentWillMount = () => {
    const hasBeenVisible = localStorage.getItem('pl_beta_viewed')
    if (!hasBeenVisible) this.setState({ isVisible: true })
  }
  onClose = () => {
    localStorage.setItem('pl_beta_viewed', true)
    this.setState({ isVisible: false })
  }
  render() {
    if (!this.state.isVisible) return null
    return (
      <div className="betainfo">
        <span>
          <h4>We're so beta!</h4>
          <a href="#" onClick={this.onClose}>
            <img src={CloseIcon} alt="close" />
          </a>
        </span>
        <p>
          Our playlists are made by algorithms, so you might experince some
          funky tracks in the lists. Every day our digital monkeys will add
          tracks, while we adjust the algorithms. Stay tuned!
        </p>
      </div>
    )
  }
}

const PlaylistView = props => (
  <React.Fragment>
    <BetaNotice />
    <Query query={PLAYLISTS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />
        if (error) return <p>An error had happened.</p>
        if (
          data &&
          data.playlists &&
          data.playlists.edges &&
          data.playlists.edges.length === 0
        ) {
          return <p>There are no playlists available.</p>
        }
        const playlists = data.playlists.edges.map(edge => edge.node)
        return <PlaylistList playlists={playlists} />
      }}
    </Query>
  </React.Fragment>
)

export default PlaylistView
