import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import UserForm from './forms/UserInfoForm'
import OrganizationForm from './forms/OrganizationInfoForm'
import ChangePassword from './forms/ChangePasswordForm'
import Loader from '../../../components/Loader'
import { ShowAlert, ShowSuccess } from '../../../actions/notificationActions'

export class UserInfoView extends Component {
  static propTypes = {
    notifications: PropTypes.shape({
      ShowAlert: PropTypes.func.isRequired,
      ShowSuccess: PropTypes.func.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        organization: PropTypes.shape({
          role: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }),
  }
  static defaultProps = {
    data: {
      loading: false,
      me: null,
    },
  }

  onError = error => {
    let errorMessage = 'An unexpected error happened'
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorMessage = error.graphQLErrors.map(({ message }) => message).join('')
    }
    this.props.notifications.ShowAlert(errorMessage)
  }

  onSuccess = message => {
    this.props.notifications.ShowSuccess(message)
  }

  isAdmin = () => this.props.data.me.organization.role === 'admin'

  render() {
    const { data } = this.props
    if (data.loading) {
      return <Loader />
    }
    if (data.error) {
      return null
    }
    return (
      <main>
        {this.isAdmin() && (
          <section>
            <h3 className="toppadding">Company Information</h3>
            <OrganizationForm
              organization={data.me.organization}
              onError={this.onError}
              onSuccess={this.onSuccess}
            />
          </section>
        )}
        <section>
          <h3 className="toppadding">User Information</h3>
          <UserForm
            user={{ name: data.me.name, email: data.me.email }}
            onError={this.onError}
            onSuccess={this.onSuccess}
          />
        </section>
        <section>
          <h3 className="toppadding">Reset password</h3>
          <ChangePassword onError={this.onError} onSuccess={this.onSuccess} />
        </section>
      </main>
    )
  }
}

const UserInfoQuery = `
  query UserInfoQuery {
    me {
      id
      email
      name
      organization {
        id
        name
        role
        cvr
        address
        zip
        country {
          displayTitle
          alpha2Code
        }
      }
    }
  }
`

const mapDispatchToProps = dispatch => ({
  notifications: bindActionCreators({ ShowAlert, ShowSuccess }, dispatch),
})

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  graphql(gql(UserInfoQuery))
)(UserInfoView)
