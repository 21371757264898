import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import Notifications from '../notifications'
import CloseIcon from '../../assets/img/close.svg'

class Footer extends Component {
  static propTypes = {
    playerVisible: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
  }
  state = {
    modalIsOpen: false,
    isLoading: true,
    html: null,
  }
  showStatusModal = () => {
    this.setState({ modalIsOpen: true })
  }
  closeStatusModal = () => {
    this.setState({ modalIsOpen: false })
  }
  render() {
    if (!this.props.isLogged) {
      return (
        <footer>
          <Notifications />
        </footer>
      )
    }
    const statusBtnClasses = classnames({
      ap: this.props.playerVisible,
    })
    return (
      <footer>
        <div id="statusbtn" className={statusBtnClasses}>
          <button id="st1" onClick={this.showStatusModal}>
            UPDATES & CHANGES
          </button>
          <Modal
            isOpen={this.state.modalIsOpen}
            ariaHideApp={false}
            style={{
              content: {
                padding: 0,
              },
              overlay: {
                zIndex: '99',
                top: '72px',
              },
            }}
          >
            <div id="status" className="fadein">
              <img
                onClick={this.closeStatusModal}
                id="close"
                src={CloseIcon}
                alt="close"
              />
              <iframe src="https://docs.google.com/document/d/e/2PACX-1vQVy6YRsn2BpYjIgdmxELRy3HvpEVJt8PWjNuEozUSgmculbkt9piF5NV4NibLxrt6A2c6m5LB8o31l/pub?embedded=true" />
            </div>
          </Modal>
        </div>
        <div id="reportbtn" className={statusBtnClasses}>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeYsCbFWxHJWChDaxxHVzZtdOmx9f3kQWd4pDvyUcsvMfzDRA/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>REPORTING & IDEAS</button>
          </a>
        </div>
        <Notifications />
      </footer>
    )
  }
}

const mapStateToProps = state => ({
  isLogged: state.get('auth').get('isLogged'),
  playerVisible: state.getIn(['audioPlayer', 'visible']),
})

export default connect(mapStateToProps)(Footer)
