import React from 'react'
import { Route } from 'react-router'

// import subroutes
import BrowseSearch from './browse'
import TrackSearch from './track'
import ArtistSearch from './artist'
import AlbumSearch from './album'

const SearchView = () => (
  <main id="searched">
    <Route exact path="/search/browse" component={BrowseSearch} />
    <Route exact path="/search/track" component={TrackSearch} />
    <Route exact path="/search/artist" component={ArtistSearch} />
    <Route exact path="/search/album" component={AlbumSearch} />
  </main>
)

export default SearchView
