import React from 'react'
import WaveformData from 'waveform-data'
import Waveform from 'react-audio-waveform'
import Loader from '../Loader'

export default class AudioWaveform extends React.Component {
  state = {
    peaks: [],
    isLoading: true,
  }

  async resolveWaveformURL(url) {
    if (!url) throw new Error('Dat url file is required')
    this.setState({ isLoading: true })
    const response = await fetch(url)
    const buffer = await response.arrayBuffer()
    const waveform = WaveformData.create(buffer)
    const peaks = []
    for (let index = 0; index < waveform.offset_length * 2; index++) {
      peaks.push(waveform.at(index))
    }
    this.setState({ peaks, isLoading: false })
  }

  async componentDidMount() {
    await this.resolveWaveformURL(this.props.url)
  }

  async componentWillReceiveProps(nextProps) {
    // If the dat url changes... recreate the peaks
    if (nextProps.url !== this.props.url) {
      await this.resolveWaveformURL(nextProps.url)
    }
  }

  render() {
    if (this.state.isLoading) return null
    return (
      <Waveform
        barWidth={1}
        peaks={this.state.peaks}
        height={50}
        duration={this.props.duration}
        pos={this.props.position}
        onClick={this.props.onClick}
        color="#ffffff"
        progressGradientColors={[[0, '#000'], [1, '#000']]}
        transitionDuration={30}
      />
    )
  }
}
