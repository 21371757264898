import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AlbumDefaultImage from '../../assets/img/album.svg'

const AlbumComponent = ({
  id,
  images,
  displayTitle,
  artists,
}) => {
  const image = (images.length >= 2) ? images[1].url : AlbumDefaultImage
  const firstArtist = _.first(artists)
  const artistsNames = artists.map(ar => ar.displayName).join(', ')
  return (
    <li>
      <Link to={`/artist/${firstArtist.id}?album=${id}`}>
        <img src={image} alt={displayTitle} />
        <figure className="triangle" />
        <span>
          <h5>{displayTitle}</h5>
          <h6>{artistsNames}</h6>
        </span>
      </Link>
    </li>
  )
}

AlbumComponent.propTypes = {
  id: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
  })).isRequired,
  displayTitle: PropTypes.string.isRequired,
  artists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  })).isRequired,
}

export default AlbumComponent
