import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { routerMiddleware } from 'react-router-redux'
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import { composeWithDevTools } from 'redux-devtools-extension' // eslint-disable-line import/no-extraneous-dependencies
import rootReducer from '../reducers'
import browserHistory from '../utils/history'

const middlewares = applyMiddleware(
  thunk,
  promiseMiddleware(),
  loadingBarMiddleware(),
  routerMiddleware(browserHistory)
)
const isDevelopment = process.env.NODE_ENV === 'development'

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    isDevelopment ? composeWithDevTools(middlewares) : compose(middlewares)
  )

  // HMR
  if (isDevelopment && module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(rootReducer.default)
    )
  }
  return store
}
