import * as types from '../types/trackDetailTypes'
import { Map, List, fromJS } from 'immutable'

const initialState = Map({
  info: Map(),
  relatedTracks: List(),
  relatedArtists: List(),
})

export default function TrackDetailReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case types.TRACK_INFO_FULFILLED:
      return state.set('info', fromJS(payload))
    case types.LOAD_RELATED_TRACKS_FULFILLED:
      return state.set('relatedTracks', fromJS(payload))
    case types.LOAD_RELATED_ARTISTS_FULFILLED:
      return state.set('relatedArtists', fromJS(payload))
    default:
      return state
  }
}
