import React from 'react'
import PropTypes from 'prop-types'
import VisibleSensor from 'react-visibility-sensor'
import Spinner from 'react-spinkit'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Artist from '../../../components/Artist'

const ARTISTS_RELATED_TRACK_QUERY = gql`
  query TrackDetailQueryRelated($trackId: ID!) {
    track(trackId: $trackId) {
      relatedArtists {
        id
        displayName
        images {
          url
        }
        totalTracks
      }
    }
  }
`

const RelatedArtist = ({ children }) => (
  <div>
    <header>
      <h3>RELATED ARTISTS</h3>
    </header>
    {children}
  </div>
)
RelatedArtist.propTypes = {
  children: PropTypes.node.isRequired,
}

const RelatedArtistsWrapper = ({ isVisible, trackId, onVisible }) => {
  if (!isVisible) {
    return (
      <VisibleSensor
        delay={500}
        onChange={_isVisible => {
          // if isVisible and no data has been loaded, call onVisible
          if (_isVisible) onVisible(_isVisible)
        }}
      />
    )
  }
  return (
    <div className="trackcontent">
      <Query
        query={ARTISTS_RELATED_TRACK_QUERY}
        variables={{ isVisible, trackId }}
        skip={!isVisible}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <RelatedArtist>
                <Spinner style={{ textAlign: 'center' }} />
              </RelatedArtist>
            )
          if (error)
            return (
              <RelatedArtist>
                <p>An error happened loading the content!</p>
              </RelatedArtist>
            )
          if (
            data.track.relatedArtists &&
            data.track.relatedArtists.length === 0
          )
            return null
          return (
            <RelatedArtist>
              <ul className="artists grid">
                {data.track.relatedArtists.map(artist => (
                  <Artist key={artist.id} {...artist} />
                ))}
              </ul>
            </RelatedArtist>
          )
        }}
      </Query>
    </div>
  )
}

RelatedArtistsWrapper.defaultProps = {
  isVisible: false,
}

RelatedArtistsWrapper.propTypes = {
  onVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  trackId: PropTypes.string.isRequired,
}

export default RelatedArtistsWrapper
