import * as types from '../types/audioPlayerTypes'
import _ from 'lodash'
import { Map } from 'immutable'

const initialState = Map()

export default function audioplayerReducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_PLAYER:
      return state.set('visible', true)
    case types.HIDE_PLAYER:
      return state.set('visible', false).set('playingIndex', null)
    case types.ADD_TRACK_TO_PLAYLIST:
      // Check if already added
      if (
        state
          .get('playlist')
          .findIndex(track => track.id === action.track.id) !== -1
      )
        return state
      return state.set('playlist', state.get('playlist').push(action.track))
    case types.PLAY_NEXT_TRACK: {
      // play previous song
      let playingIndex = state.get('playingIndex')
      let requiredIndex = playingIndex + 1

      if (state.get('playlist').get(requiredIndex)) {
        return state
          .set('playingIndex', requiredIndex)
          .set('playing', state.getIn(['playlist', requiredIndex]))
      }

      return state
    }
    case types.PLAY_PREV_TRACK: {
      // play previous song
      let playingIndex = state.get('playingIndex')
      let requiredIndex = playingIndex - 1

      if (requiredIndex >= 0 && state.get('playlist').get(requiredIndex)) {
        return state
          .set('playingIndex', requiredIndex)
          .set('playing', state.getIn(['playlist', requiredIndex]))
      }

      return state
    }
    case types.PLAY_TRACK: {
      const playingIndex = state
        .get('playlist')
        .findIndex(track => track.id === action.track.id)
      if (playingIndex === -1) return state
      // Revert isPaused if already playing
      if (
        playingIndex === state.get('playingIndex') &&
        state.get('isPaused') === false
      )
        return state.set('isPaused', true)
      return state
        .set('playingIndex', playingIndex)
        .set('playingAlbumUPC', action.albumUPC)
        .set('isPaused', false)
    }
    case types.PAUSE_TRACK: {
      return state.set('isPaused', !state.get('isPaused'))
    }
    case types.VOLUME_CHANGED: {
      return state.set('volume', action.volume)
    }
    case types.MUTE_PLAYER: {
      return state.set('isMuted', !state.get('isMuted'))
    }
    default:
      return state
  }
}
