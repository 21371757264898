import gql from 'graphql-tag'

export default gql(`
  query ArtistSearchQuery($artistName: String!, $size: Int = 50, $page: Int = 1) {
    artists(displayName: $artistName, size: $size, page: $page) {
      pageInfo {
        totalCount
        nextPage
        hasNextPage
      }
      edges {
        node {
          id
          displayName
          totalTracks
          images {
            url
          }
        }
      }
    }
  }
`)
