import * as types from '../types/notificationTypes'

export default function notificationsReducer(state = [], action) {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      const notification = {
        id: new Date().getTime(),
        message: action.message,
        type: action.notificationType || 'info',
      }
      const notifications = [...state]
      notifications.push(notification)
      return notifications
    case types.HIDE_NOTIFICATION:
      return [...state].filter(n => n.id !== action.id)
    default:
      return state
  }
}
