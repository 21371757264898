import bugsnag from 'bugsnag-js'

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  notifyReleaseStages: ['stage', 'production'],
  releaseStage: process.env.REACT_APP_STAGE,
  appVersion: process.env.REACT_APP_VERSION,
})

export default bugsnagClient
