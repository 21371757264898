import React from 'react'
import PropTypes from 'prop-types'
import { compose, graphql, gql } from 'react-apollo'
import { withFormik } from 'formik'
import * as yup from 'yup'

export const InviteInfoForm = ({
  values,
  isValid,
  handleSubmit,
  handleChange,
}) => (
  <form onSubmit={handleSubmit}>
    <input
      type="text"
      placeholder="Your name"
      name="name"
      value={values.name}
      onChange={handleChange}
    />
    <input
      disabled
      type="email"
      placeholder="Your email"
      value={values.email}
      onChange={handleChange}
    />
    <input
      type="password"
      placeholder="Type password"
      name="password"
      value={values.password}
      onChange={handleChange}
    />
    <input
      type="password"
      placeholder="Retype password"
      name="rePassword"
      value={values.rePassword}
      onChange={handleChange}
    />
    {isValid && (
      <button type="submit" className="btn btn-icon w100 black btnlogin right">
        Join now
      </button>
    )}
  </form>
)

InviteInfoForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    rePassword: PropTypes.string,
  }),
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}
InviteInfoForm.defaultProps = {
  values: {
    name: '',
    email: '',
    password: '',
    rePassword: '',
  },
}

export default compose(
  withFormik({
    mapPropsToValues: ({ invite }) => ({
      email: invite.email,
      name: invite.name,
    }),
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .min(3)
        .max(50)
        .required(),
      email: yup
        .string()
        .min(6)
        .max(30)
        .email()
        .required(),
      password: yup
        .string()
        .min(6)
        .max(30)
        .required(),
      rePassword: yup
        .string()
        .min(6)
        .max(30)
        .required()
        .test(
          'password-match',
          'passwords do not match',
          function matchPasswords(value) {
            return this.parent.password === value
          }
        ),
    }),
    handleSubmit: async (values, { props, resetForm }) => {
      await props.onAccept({
        name: values.name,
        email: values.email,
        password: values.password,
      })
      resetForm()
    },
  })
)(InviteInfoForm)
