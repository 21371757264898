import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as authConstants from '../types/authTypes'

function authReducer(state = {}, action) {
  switch (action.type) {
    case authConstants.LOGIN_IN_PROGRESS:
      return state.set('loginInProgress', true)
    case authConstants.LOGGED_IN:
      return state.set('isLogged', true).set('loginInProgress', false)
    case authConstants.LOGGED_OUT:
      return state.set('isLogged', false).set('loginInProgress', false)
    default:
      return state
  }
}

const persistConfig = {
  key: 'auth',
  storage,
}

export default persistReducer(persistConfig, authReducer)
