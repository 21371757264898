import ApolloClient from 'apollo-boost'

function buildApolloClient(uri) {
  const client = new ApolloClient({
    uri,
    credentials: 'include',
    onError: err => {
      // Only log errors to console when in development.
      // Errors in production stages should be notified
      // to places like Bugsnag
      if (process.env.REACT_APP_STAGE === 'development') {
        console.error(err) // eslint-disable-line no-console
      }
    },
  })

  return client
}

/**
 * Apollo client to talk with the public graphql endpoint
 */
export const client = buildApolloClient(process.env.REACT_APP_GRAPHQL)

/**
 * Apollo client to talk with the private graphql endpoint
 */
export const privateClient = buildApolloClient(
  process.env.REACT_APP_GRAPHQL_PRIVATE
)

export default client
