import React, { MouseEvent } from 'react'
import { connect } from 'react-redux'
import { Howl, Howler } from 'howler'
import { bindActionCreators, Dispatch } from 'redux'
import {
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import PreviousIcon from '../../assets/img/previous.svg'
import PlayIcon from '../../assets/img/play.svg'
import PauseIcon from '../../assets/img/pause.svg'
import NextIcon from '../../assets/img/next.svg'
import NoteIcon from '../../assets/img/beamed-note.svg'
import SoundOnIcon from '../../assets/img/soundon.svg'
import SoundOffIcon from '../../assets/img/soundoff.svg'
import { formatTime, formatArtistNames } from '../../utils/Formatters'
import * as AudioPlayerActions from '../../actions/audioPlayerActions'
import AudioWaveform from '../AudioWaveform'
import Loader from '../Loader'
import { detectIEVersion } from '../../utils/browser'

interface IAudioPlayerProps {
  volume: number
  playingIndex: number
  playlist: any
  isPaused: boolean
  isMuted: boolean
}

interface IAudioPlayerActions {
  MutePlayer: () => void
  VolumeChanged: (arg0: number) => void
  PlayTrack: (arg0: any) => void
  PauseTrack: () => void
  PlayPrevTrack: () => void
  PlayNextTrack: () => void
  StoppedPlaying: () => void
}

interface IProps {
  audioPlayer: IAudioPlayerProps
  AudioPlayerActions: IAudioPlayerActions
}

interface IStatePlayingWaveforms {
  datUrl: string
}

interface IArtist {
  displayName: string
}

interface ITrackImage {
  url: string
}

interface ITrack {
  waveforms?: IStatePlayingWaveforms
  streamUrl?: string
  previewUrl: string
  displayTitle: string
  artists: [IArtist]
  image: ITrackImage
}

interface IState {
  isClosed: boolean
  isExpanded: boolean
  playing?: ITrack
  duration: number
  seek: number
  seekPercentage: number
  isLoading: boolean
  isSeeking: boolean
  noAudioContext: boolean
  canDisplayWaveforms: boolean
}

class HowlerRequired extends Error {
  constructor(message: string = 'Audio Howler is required') {
    super(message)
  }
}

class AudioPlayer extends React.Component<IProps, IState> {
  private audio: Howl | undefined
  private _stopInterval: number | undefined
  private _frameInterval: number | undefined
  private volumeBarRef: HTMLElement | null = null
  private progressBarRef: HTMLElement | null = null

  state: IState = {
    isClosed: false,
    isExpanded: this.expandSetting() as boolean,
    duration: 0,
    seek: 0,
    seekPercentage: 0,
    isLoading: true,
    isSeeking: false,
    noAudioContext: !window.AudioContext,
    canDisplayWaveforms: detectIEVersion() === false, // Waveforms are not allowed in IE
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyShortcuts)
    this.onTrackPropsChange(this.props)
  }

  componentWillUnmount() {
    this._clearStopInterval()
    window.removeEventListener('keydown', this.handleKeyShortcuts)
    this.resetHowl()
  }

  componentWillReceiveProps(nextProps: IProps) {
    const { audioPlayer } = nextProps

    // Update volume on changed
    if (
      audioPlayer.volume &&
      audioPlayer.volume !== this.props.audioPlayer.volume
    ) {
      this.onVolumeChange(audioPlayer.volume)
    }

    // If a track change had happened, we have to re-init the player
    if (audioPlayer.playingIndex !== this.props.audioPlayer.playingIndex) {
      this.onTrackPropsChange(nextProps)
    }

    if (!this.props.audioPlayer.isPaused && audioPlayer.isPaused) {
      this.pause()
    } else if (this.props.audioPlayer.isPaused && !audioPlayer.isPaused) {
      this.play()
    }
  }

  private expandSetting(expanded?: boolean): boolean | void {
    const key = 'settings:audioplayer:expanded'
    if (expanded !== undefined) {
      window.localStorage.setItem(key, expanded ? '1' : '0')
    } else {
      const val = window.localStorage.getItem(key)
      if (!val) return true
      return val === '1'
    }
  }

  /**
   * We want to listen for keystrokes range from 0-9 in order to do quick seek for an audio file.
   * For example:
   * If a user press 6 in the keyboard the playing position will move to the six segment.
   * Segments are calculated using the total duration of the song divided by 9 (as is the max possible number in the keyboard).
   *
   * NOTE: We divide by 10 actually otherwise 9 will be the end of the file.
   */
  handleKeyShortcuts = (evt: KeyboardEvent): void => {
    // if cmd or ctrl keys are pressed, skip...
    if (evt.metaKey || evt.ctrlKey) return

    const [min, max] = [0, 9]
    const { key } = evt
    const keyNumber = parseInt(key, 10)

    if (Number.isNaN(keyNumber)) return

    if (keyNumber >= min && keyNumber <= max) {
      // calculate the second we have to seek
      const secondsInSegment = this.getDuration() / (max + 1)
      const secondToGo = secondsInSegment * keyNumber
      this.goToSecond(secondToGo)
    }
  }

  onTrackPropsChange = ({ audioPlayer }: IProps) => {
    this._clearStopInterval()

    const playing = audioPlayer.playlist[audioPlayer.playingIndex]
    this.setState(
      {
        playing,
        duration: 0,
        seek: 0,
        seekPercentage: 0,
        isLoading: true,
      },
      () => {
        this.createHowl(playing.streamUrl || playing.previewUrl)
      }
    )
  }

  onClickPlay = (e: any) => {
    e.preventDefault()
    const { AudioPlayerActions } = this.props
    if (this.props.audioPlayer.isPaused) {
      AudioPlayerActions.PlayTrack(this.state.playing)
    } else {
      AudioPlayerActions.PauseTrack()
    }
  }

  onProgressReset = () => {
    // Update progress and timer while playing
    this._clearStopInterval()
    this.stopTimeout(this.getPendingDuration())
    this._frameInterval = window.requestAnimationFrame(this.step.bind(this))
  }

  onPlay = () => {
    this.onProgressReset()
    if (this.state.isSeeking) this.setState({ isSeeking: false })
  }

  onPlayPrev = (e: any) => {
    e.preventDefault()
    this.props.AudioPlayerActions.PlayPrevTrack()
  }

  onPlayNext = (e: any) => {
    e.preventDefault()
    this.props.AudioPlayerActions.PlayNextTrack()
  }

  play = () => {
    if (!this.audio) throw new HowlerRequired()
    if (this.audio.playing()) throw new Error('Audioplayer is already playing')
    this.setState({ isSeeking: true })
    this.audio.play()
  }

  pause = () => {
    if (!this.audio) throw new HowlerRequired()
    this.audio.pause()
    this._clearStopInterval()
  }

  onPause = () => {}

  onEnd = () => {
    this.props.AudioPlayerActions.StoppedPlaying()
    this._clearStopInterval()
  }

  onSeek = () => {
    if (!this.audio) throw new HowlerRequired()
    if (!this.props.audioPlayer.isPaused && !this.audio.playing()) {
      this.play()
    } else {
      this.setState({ isSeeking: false })
    }
  }

  goToSecond = (second: number) => {
    if (this.state.isSeeking) return
    this.setState({ isSeeking: true }, () => {
      if (!this.audio) throw new HowlerRequired()
      if (this.audio.playing()) this.pause()
      this.audio.seek(second)
      this.updateSeek(second)
    })
  }

  getDuration = () => {
    if (!this.audio) throw new HowlerRequired()
    return this.audio.duration()
  }

  getSeek = (): number => {
    if (!this.audio) throw new HowlerRequired()
    const seek = this.audio.seek()
    if (seek instanceof Howl) return 0 // Seek can return Howl instance instead of second. Is this a bug?
    return seek || 0
  }

  getPendingDuration = () => {
    if (!this.audio) throw new HowlerRequired()

    return this.getDuration() - this.getSeek()
  }

  onTrackLoad = () => {
    const duration = this.getDuration()
    this.setState(
      {
        duration,
        isLoading: false,
      },
      () => {
        // // In order to give better experience when playing a full track,
        // // we skip the start and seek to a random place that hopefully
        // // is close to the chorus. This functionality should be improve
        // // in the future.
        if (this.state.playing && this.state.playing.streamUrl) {
          window.setTimeout(() => {
            this.goToSecond(duration / 4)
          }, 0)
        }
      }
    )
  }

  updateSeek = (second?: number) => {
    return new Promise(res => {
      if (!this.audio) throw new HowlerRequired()
      const seek = second || this.getSeek()
      const duration = this.getDuration()
      this.setState((state: IState) => {
        state.seek = seek
        state.seekPercentage = (seek / duration) * 100
        return state
      }, res)
    })
  }

  step = () => {
    if (this.audio && this.audio.playing()) {
      this._frameInterval = window.requestAnimationFrame(async () => {
        await this.updateSeek()
        this.step()
      })
    }
  }

  stopTimeout = (seconds: number) => {
    this._stopInterval = window.setTimeout(() => {
      if (!this.audio) throw new HowlerRequired()
      this.audio.stop()
      this._clearStopInterval()
    }, Math.floor(seconds * 1000))
  }

  _clearStopInterval = () => {
    window.clearInterval(this._stopInterval)
    window.cancelAnimationFrame(this._frameInterval as number)
  }

  onVolumeChange = (percentage: number) => {
    if (!this.audio) throw new HowlerRequired()
    this.audio.volume(percentage / 100) // convert percentage to format 0.0 to 1 range
  }

  private getPercentageOfElementRef = (
    evt: MouseEvent<HTMLElement>,
    ref: HTMLElement
  ) => {
    if (!ref) throw new Error('DOM Reference is required')
    const clientRect = ref.getBoundingClientRect()
    const x = evt.clientX // || evt.touches[0].clientX  // TODO: TouchEvent is giving issues
    let per = Math.floor(
      ((x - clientRect.left) / (clientRect.right - clientRect.left)) * 100
    )
    return per
  }

  changeVolumeClicked = (evt: MouseEvent<HTMLElement>) => {
    if (!this.volumeBarRef) return
    let per = this.getPercentageOfElementRef(evt, this.volumeBarRef)
    if (per < 5) per = 0
    if (per > 95) per = 100
    this.props.AudioPlayerActions.VolumeChanged(per)
  }

  mutePlayer = () => {
    this.props.AudioPlayerActions.MutePlayer()
  }

  createHowl = (src: string) => {
    this.resetHowl()
    // Create the initial Howl
    this.audio = new Howl({
      src,
      volume: 0.5,
      preload: true,
      autoplay: true,
      xhrWithCredentials: true,
      html5: true,
      format: ['mp3'],
    })
    this.listenHowlEvents()
  }

  resetHowl = () => {
    if (this.audio) {
      this.offHowlEvents()
      this.audio.unload()
      this.audio = undefined
    }
  }

  listenHowlEvents = () => {
    if (!this.audio) throw new HowlerRequired()
    this.audio.on('seek', this.onSeek.bind(this))
    this.audio.on('pause', this.onPause.bind(this))
    this.audio.on('stop', this.onEnd.bind(this))
    this.audio.on('end', this.onEnd.bind(this))
    this.audio.on('play', this.onPlay.bind(this))
    this.audio.on('load', this.onTrackLoad.bind(this))
  }

  offHowlEvents = () => {
    if (!this.audio) throw new HowlerRequired()
    this.audio.off('seek')
    this.audio.off('pause')
    this.audio.off('stop')
    this.audio.off('end')
    this.audio.off('play')
    this.audio.off('load')
  }

  onProgressClick = (evt: MouseEvent<HTMLElement>) => {
    if (!this.progressBarRef) return
    const percentage = this.getPercentageOfElementRef(evt, this.progressBarRef)
    // calculate the second using the percentage
    if (this.audio) {
      const second = (percentage / 100) * this.getDuration()
      this.goToSecond(second)
    }
  }

  onClose = () => {
    this.setState({ isClosed: true })
    if (this.audio) {
      this.audio.stop()
    }
  }

  toggleExpand = () => {
    const isExpanded = !this.state.isExpanded
    this.setState({ isExpanded })
    this.expandSetting(isExpanded)
  }

  render() {
    const { volume, isPaused, isMuted } = this.props.audioPlayer
    const {
      playing,
      duration,
      seek,
      seekPercentage,
      noAudioContext,
    } = this.state
    if (!playing || this.state.isClosed) return null
    const showLoading = this.state.isLoading || this.state.isSeeking
    return (
      <div id="p-wrapper" className="slideup">
        {noAudioContext && (
          <div className="notice">
            <p>
              Audio streaming can be slow in this browser. Please use Chrome or
              Firefox to get a better experience.
            </p>
          </div>
        )}
        <div id="p">
          {}
          {(!this.state.isExpanded ||
            !this.state.canDisplayWaveforms ||
            !playing.waveforms) && (
            <div
              id="progress-wp"
              onClick={this.onProgressClick}
              ref={r => (this.progressBarRef = r)}
            >
              <figure id="progress" style={{ width: `${seekPercentage}%` }} />
            </div>
          )}
          <div id="pl">
            <img src={NoteIcon} alt="note" />
            <h4>
              {playing.displayTitle}{' '}
              <span>• {formatArtistNames(playing.artists)} </span>
            </h4>
          </div>
          {showLoading && (
            <div id="pc" style={{ marginTop: '15px' }}>
              <Loader.Small color="white" />
            </div>
          )}
          {!showLoading && (
            <div id="pc">
              <a>
                <img
                  src={PreviousIcon}
                  alt="Click to go back"
                  onClick={this.onPlayPrev}
                />
              </a>
              <a>
                <img
                  className="pp"
                  src={!isPaused ? PauseIcon : PlayIcon}
                  alt={!isPaused ? 'Click to pause' : 'Click to play'}
                  onClick={this.onClickPlay}
                />
              </a>
              <a>
                <img
                  src={NextIcon}
                  alt="Click to skip"
                  onClick={this.onPlayNext}
                />
              </a>
            </div>
          )}
          <div id="pr">
            <span>
              <a>
                <img
                  onClick={this.mutePlayer}
                  src={!isMuted ? SoundOnIcon : SoundOffIcon}
                  alt={
                    !isMuted
                      ? 'Click to disable sound'
                      : 'Click to enable sound'
                  }
                />
              </a>
              <div id="vwrap">
                <figure
                  id="rangebg"
                  ref={r => (this.volumeBarRef = r)}
                  onClick={this.changeVolumeClicked}
                >
                  <figure id="activebg" style={{ width: `${volume}%` }} />
                </figure>
              </div>
            </span>
            {!this.state.isLoading && (
              <time>
                <span>{formatTime(seek, 's')}</span> /{' '}
                <span>{formatTime(duration, 's')}</span>
              </time>
            )}
            <div className="icons">
              <button className="btn-icon" onClick={this.toggleExpand}>
                {!this.state.isExpanded && (
                  <ExpandLessIcon style={{ fontSize: 18 }} />
                )}
                {this.state.isExpanded && (
                  <ExpandMoreIcon style={{ fontSize: 18 }} />
                )}
              </button>
              <button className="btn-icon" onClick={this.onClose}>
                <CloseIcon style={{ fontSize: 18 }} />
              </button>
            </div>
          </div>
          {this.state.isExpanded &&
            this.state.canDisplayWaveforms &&
            !this.state.isLoading &&
            playing.waveforms && (
              <AudioWaveform
                url={playing.waveforms.datUrl}
                position={seek}
                duration={duration}
                onClick={this.goToSecond}
              />
            )}
          <div
            id="cover"
            style={{ backgroundImage: `url(${playing.image.url})` }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    audioPlayer: state.get('audioPlayer').toJS(),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    AudioPlayerActions: bindActionCreators(AudioPlayerActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioPlayer)
