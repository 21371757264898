import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import TrackDefaultImage from '../../../assets/img/track.svg'

const TrackComponent = ({ onPlay, track, playing = false, isGrid = false }) => {
  const onClicked = e => {
    e.preventDefault()
    onPlay(track.id)
  }
  const playingClassName = playing ? 'pause' : 'play'
  if (isGrid) {
    return (
      <li key={track.id} className={playing ? 'playing' : ''}>
        <Link to={`/track/${track.id}`}>
          <div className="cover-wp">
            <div className="cover-pl-wp">
              <figure onClick={onClicked} className={playingClassName} />
            </div>
            <img
              src={_.get(track, 'album.images[0].url', TrackDefaultImage)}
              alt={track.album.displayTitle}
            />
          </div>
          <span>
            <h5>{track.displayTitle}</h5>
            <h6>{track.artists.map(ar => ar.displayName).join(', ')}</h6>
          </span>
        </Link>
      </li>
    )
  }
  return (
    <li key={track.id} className={playing ? 'playing' : ''}>
      <section>
        <div className="artwork">
          <figure onClick={onClicked} className={playingClassName} />
          {track.album && (
            <img
              className="artwork"
              src={_.get(track, 'album.images[1].url', TrackDefaultImage)}
              alt={track.album.displayTitle}
            />
          )}
        </div>
        <span>
          <h5>{track.displayTitle}</h5>
          <span className="artists">
            {track.artists.map((ar, i) => (
              <Link to={`/artist/${ar.id}`} key={ar.id}>
                {ar.displayName}
                {i < track.artists.length - 1 ? ',' : ''}{' '}
              </Link>
            ))}
          </span>
        </span>
      </section>
      <section>
        <span className="cm">
          {track.album.releaseDate && (
            <h6 className="release-date">{track.album.releaseDate}</h6>
          )}
          {track.album.genres && track.album.genres.length > 0 && (
            <h6 className="genres">
              {track.album.genres.map((genre, i) => (
                <span key={genre}>
                  {i < track.album.genres.length - 1
                    ? `${_.upperFirst(genre)}, `
                    : _.upperFirst(genre)}
                </span>
              ))}
            </h6>
          )}
        </span>
        {track.waveforms && track.waveforms.imageUrl && (
          <span className="cm">
            <img src={track.waveforms.imageUrl} alt="" className="wave-hint" />
          </span>
        )}
        {track.downloadUrl && (
          <a href={track.downloadUrl} target="_blank" rel="noopener noreferrer">
            <span className="cardinfo">Download MP3</span>
          </a>
        )}
        <Link to={`/track/${track.id}`}>
          <span className="cardinfo">Trackinfo</span>
        </Link>
      </section>
      {track.selling && track.selling.priceText && (
        <section className="tp">
          <span>
            <p>
              <b>{track.selling.priceText} DKK</b>
            </p>
          </span>
        </section>
      )}
      <section className="tp-gradient" />
    </li>
  )
}

TrackComponent.propTypes = {
  isGrid: PropTypes.bool,
  isHovered: PropTypes.bool.isRequired,
  onHover: PropTypes.func.isRequired,
  onHoverOut: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  track: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayTitle: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string,
    artists: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      })
    ),
    album: PropTypes.shape({
      releaseDate: PropTypes.string.isRequired,
      genres: PropTypes.arrayOf(PropTypes.string),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        })
      ),
    }),
  }).isRequired,
}

export default TrackComponent
