import React from 'react'
import isEmpty from 'lodash/isEmpty'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import Loader from '../../../components/Loader'
import InfiniteAlbumList from '../../../components/InfiniteList/InfiniteAlbumListComponent'
import EmptyParams from '../components/EmptyParams'
import SearchQuery from '../components/Search'

const SEARCH_ALBUMS_QUERY = gql(`
  query AlbumSearchQuery($displayTitle: String!, $size: Int = 20, $page: Int = 1) {
    albums(displayTitle: $displayTitle, size: $size, page: $page) {
      pageInfo {
        totalCount
        nextPage
        hasNextPage
      }
      edges {
        node {
          id
          displayTitle,
          images {
            width,
            height,
            url,
          }
          artists {
            id
            displayName
          }
        }
      }
    }
  }
`)

const AlbumSearchView = () => (
  <SearchQuery placeholder="Search by album...">
    {({ query }) => {
      if (isEmpty(query)) return <EmptyParams />
      return (
        <div id="content">
          <Query
            query={SEARCH_ALBUMS_QUERY}
            variables={{ displayTitle: query }}
          >
            {({
              loading,
              error,
              data,
              fetchMore,
            }) => {
              if (loading) return (<Loader />)
              if (error) return (<p>An error had happened.</p>)
              if (data && data.albums && data.albums.edges && data.albums.edges.length === 0) {
                return (<p>There are no results.</p>)
              }
              return (
                <div>
                  <header>
                    <h3>{`ALBUMS (${data.albums.pageInfo.totalCount})`}</h3>
                  </header>
                  <InfiniteAlbumList
                    fetchMore={fetchMore}
                    albums={data.albums}
                  />
                </div>
              )
            }}
          </Query>
        </div>
      )
    }}
  </SearchQuery>
)

export default AlbumSearchView
