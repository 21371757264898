import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Resumable from 'resumablejs'
import { CloudOff as CloudOffIcon } from '@material-ui/icons'
import UploaderZone from './IngestionUploaderZone'

class IngestionUploader extends Component {
  static propTypes = {
    onFilesChange: PropTypes.func.isRequired,
  }

  state = {
    isOnline: true,
    startedUpload: false,
    uploadItems: [],
  }

  componentDidMount() {
    this.uploaders = [
      this.setupUploader(this.episodesUploaderRef, 'Episode'),
      this.setupUploader(this.trailersUploaderRef, 'Trailer'),
    ]

    // listen for online
    this.offlineListener = window.addEventListener('offline', () => {
      this.uploaders.forEach(uploader => {
        if (uploader.isUploading()) {
          uploader.pause()
        }
      })
      this.setState({ isOnline: false })
    })
    this.onlineListener = window.addEventListener('online', () => {
      this.uploaders.forEach(uploader => {
        if (uploader.files.length > 0) {
          uploader.upload()
        }
      })
      this.setState({ isOnline: true })
    })
  }

  componentWillUnmount() {
    if (this.offlineListener) window.removeEventListener(this.offlineListener)
    if (this.onlineListener) window.removeEventListener(this.onlineListener)
  }

  getIndexOfFileInState = _file => {
    if (!_file) throw new Error('File is required')
    const index = this.state.uploadItems.findIndex(
      ({ file }) => file.uniqueIdentifier === _file.uniqueIdentifier
    )
    if (index < 0) throw new Error('File is not available in uploadItems list')
    return index
  }

  setFileState = (file, data) => {
    const index = this.getIndexOfFileInState(file)
    this.setState(state => {
      state.uploadItems[index] = { ...state.uploadItems[index], ...data }
      return state
    })
  }

  // When a specific file updates its upload progress
  onUploaderFileProgress = file => {
    this.setFileState(file, { progress: file.progress() * 100 })
  }

  onUploaderFileChunkingStart = (file, type) => {
    this.setState(state => {
      state.uploadItems = state.uploadItems.concat({
        type,
        file,
        progress: 0,
        completed: false,
        status: 'Preparing...',
        hasFailed: false,
      })
      return state
    })
  }

  onUploaderFileChunkingComplete = file => {
    this.setFileState(file, { hasFailed: false, status: 'Uploading...' })
  }

  onUploaderFileAdded = () => {
    this.startUpload()
  }

  onUploaderFileError = file => {
    this.setFileState(file, { status: 'Upload failed', hasFailed: true })
  }

  onUploaderFileSuccess = (file, message) => {
    const { id } = JSON.parse(message)
    if (!id)
      throw new Error('Id missing in the message payload from file uploaded')
    this.setFileState(file, { id, completed: true, status: 'Uploaded' })

    // pass the id and the filename of each file in the ingestion uploader
    this.props.onFilesChange(
      this.state.uploadItems.map(({ file, id }) => ({
        id,
        fileName: file.fileName,
      }))
    )
  }

  setupUploader(DOMNode, type) {
    if (!type) throw new Error('Type of file uploader is required')
    if (!DOMNode)
      throw new Error(
        'Input file DOM reference is required to attach resumable but its not available.'
      )
    const r = new Resumable({
      target: `${process.env.REACT_APP_API}/productions/files/upload`,
      query: { type: type.toLowerCase() },
      fileType: ['edl', 'fcpxml']
    })
    r.assignBrowse(DOMNode)
    r.assignDrop(DOMNode)
    r.on('chunkingStart', file => this.onUploaderFileChunkingStart(file, type))
    r.on('chunkingComplete', this.onUploaderFileChunkingComplete.bind(this))
    r.on('fileAdded', this.onUploaderFileAdded.bind(this))
    r.on('fileProgress', this.onUploaderFileProgress.bind(this))
    r.on('fileSuccess', this.onUploaderFileSuccess.bind(this))
    r.on('fileError', this.onUploaderFileError.bind(this))
    r.on('uploadStart', () => {
      if (!this.state.startedUpload) {
        this.setState({ startedUpload: true })
      }
    })
    return r
  }

  startUpload = () => {
    if (this.state.isOnline) {
      this.uploaders.forEach(uploader => uploader.upload())
    }
  }

  deleteFile = file => {
    this.uploaders.forEach(uploader => {
      // Verify the upload item, exists in the uploader and remove if found
      const uploaderFile = uploader.getFromUniqueIdentifier(
        file.uniqueIdentifier
      )
      if (uploaderFile) {
        if (uploaderFile.isUploading()) uploaderFile.abort()
        uploader.removeFile(file)
        // Delete file from the state
        this.setState(state => {
          state.uploadItems = state.uploadItems.filter(
            item => item.file.uniqueIdentifier !== file.uniqueIdentifier
          )
          return state
        })
      }
    })
  }

  retryFile = file => {
    this.uploaders.forEach(uploader => {
      // Verify the upload item, exists in the uploader and remove if found
      const uploaderFile = uploader.getFromUniqueIdentifier(
        file.uniqueIdentifier
      )
      if (uploaderFile) {
        uploaderFile.retry()
      }
    })
  }

  render() {
    const { uploadItems } = this.state
    const episodeFiles = uploadItems.filter(f => f.type === 'Episode')
    const trailerFiles = uploadItems.filter(f => f.type === 'Trailer')
    return (
      <React.Fragment>
        {!this.state.isOnline && (
          <div id="nbar" className="relative blue has-icon">
            <span className="nbar-icon">
              <CloudOffIcon style={{ fontSize: 35 }} />
            </span>
            {this.state.startedUpload && (
              <p>
                Your connection is lost. Uploads will resume as soon as your
                connection is online again.
              </p>
            )}
            {!this.state.startedUpload && (
              <p>
                Your connection is lost. Please reconnect again to upload files.
              </p>
            )}
          </div>
        )}
        <div id="wupload">
          <UploaderZone
            heading="Normal productions"
            childRef={ref => (this.episodesUploaderRef = ref)}
            files={episodeFiles}
            onDeleteFile={this.deleteFile}
            onRetryFile={this.retryFile}
          />
          <UploaderZone
            heading="Trailers"
            childRef={ref => (this.trailersUploaderRef = ref)}
            files={trailerFiles}
            onDeleteFile={this.deleteFile}
            onRetryFile={this.retryFile}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default IngestionUploader
