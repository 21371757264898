import React from 'react'
import PropTypes from 'prop-types'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withFormik } from 'formik'
import * as yup from 'yup'
import Input from '../../../../components/input'

export const ChangePasswordForm = ({
  values,
  errors,
  isValid,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  dirty,
}) => (
  <form className="userbasic" onSubmit={handleSubmit}>
    <Input
      label="Current password"
      name="password"
      type="password"
      id="i6"
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.password}
      autocomplete="current-password"
      required
    />
    <Input
      label="New password"
      name="newPassword"
      type="password"
      id="i6"
      value={values.newPassword}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.newPassword}
      autocomplete="new-password"
      required
    />
    <Input
      label="Retype new password"
      name="newRePassword"
      type="password"
      id="i7"
      value={values.newRePassword}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.newRePassword}
      autocomplete="new-password"
      required
    />
    {dirty && (
      <div style={{ display: 'inline-block', width: '100%' }}>
        <button
          disabled={!isValid}
          type="submit"
          style={{ marginTop: 0 }}
          className="btn btn-icon btnsave right black"
        >
          {isSubmitting ? 'CHANGING...' : 'CHANGE PASSWORD'}
        </button>
      </div>
    )}
  </form>
)

ChangePasswordForm.propTypes = {
  values: PropTypes.shape({
    password: PropTypes.string,
    newPassword: PropTypes.string,
    newRePassword: PropTypes.string,
  }),
  errors: PropTypes.shape({
    password: PropTypes.string,
    newPassword: PropTypes.string,
    newRePassword: PropTypes.string,
  }),
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}
ChangePasswordForm.defaultProps = {
  values: {
    password: '',
    newPassword: '',
  },
  errors: {
    password: '',
    newPassword: '',
  },
}

const updateProfileMutation = gql(`
  mutation UpdatePassword($password: String!, $newPassword: String!) {
    updatePassword(password: $password, newPassword: $newPassword) {
      changed
    }
  }
`)

export default compose(
  graphql(updateProfileMutation),
  withFormik({
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(6)
        .max(30)
        .required(),
      newPassword: yup
        .string()
        .min(6)
        .max(30)
        .required(),
      newRePassword: yup
        .string()
        .min(6)
        .max(30)
        .required()
        .test(
          'password-match',
          'New passwords do not match',
          function matchPasswords(value) {
            return this.parent.newPassword === value
          }
        ),
    }),
    handleSubmit: async (
      values,
      { props, setFieldError, resetForm, setSubmitting }
    ) => {
      try {
        setSubmitting(true)
        await props.mutate({ variables: values })
        setSubmitting(false)
        props.onSuccess('Password updated!')
        resetForm()
      } catch (e) {
        setSubmitting(false)
        if (e.graphQLErrors) {
          e.graphQLErrors.forEach(_e => {
            if (_e.data && _e.data.code && _e.data.code === 'USER_PWD_WRONG') {
              setFieldError('password', 'wrong password')
            }
          })
        }
        props.onError(e)
      }
    },
  })
)(ChangePasswordForm)
