import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import { client, privateClient } from '../../../utils/Apollo'
import { OnLogout } from '../../../actions/authActions'

export class LogoutView extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    OnLogout: PropTypes.func.isRequired,
    client: PropTypes.shape({
      clearStore: PropTypes.func.isRequired,
    }).isRequired,
  }

  async componentDidMount() {
    if (this.props.isLogged) {
      const { data } = await this.props.logout()
      // reset cache & redux store
      if (data && data.logout && !data.logout.isLogged) {
        await client.cache.reset()
        await privateClient.cache.reset()
        this.props.OnLogout()
      }
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = state => ({
  isLogged: state.get('auth').get('isLogged'),
})

const logoutMutation = gql(`
  mutation Logout {
    logout {
      isLogged
    }
  }
`)

export default compose(
  connect(
    mapStateToProps,
    { OnLogout }
  ),
  graphql(logoutMutation, {
    name: 'logout',
  }),
  withApollo
)(LogoutView)
