import gql from 'graphql-tag'

export default gql(`
  query Profile {
    me {
      id
      name
      isAdmin
      organization {
        id
        name
        logo
        role
      }
    }
  }
`)
