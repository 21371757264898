import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import Loader from './Loader'
import { privateClient, client } from '../utils/Apollo'

/**
 * ApolloLoader is a wrapper in top of Apollo Query to display Loader or display errors
 * after loading a GraphQL query
 */
const ORQuery = ({
  query,
  variables,
  apollo,
  errorMessage,
  children,
  isPrivate,
}) => (
  <Query
    query={query}
    variables={variables}
    client={isPrivate ? privateClient : client}
    {...apollo}
  >
    {({ loading, error, ...queryProps }) => {
      if (loading) return <Loader />
      if (error) return <p>{errorMessage}</p>
      return children(queryProps)
    }}
  </Query>
)

ORQuery.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  variables: PropTypes.object,
  apollo: PropTypes.object,
  isPrivate: PropTypes.bool, // run with private client?
}

ORQuery.defaultProps = {
  isPrivate: false,
  errorMessage: 'An unexpected error happened. Please try again later!',
}

export default ORQuery
