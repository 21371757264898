import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../Loader'

class InfiniteList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    cacheKey: PropTypes.string.isRequired,
    fetchMore: PropTypes.func.isRequired,
    items: PropTypes.shape({
      pageInfo: PropTypes.shape({
        nextPage: PropTypes.number.isRequired,
        hasNextPage: PropTypes.bool.isRequired,
      }).isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.object.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    loadingMore: false,
  }

  renderItems = (edges = []) => edges.map(({ node }) => {
    const Component = this.props.component
    return (<Component key={node.id} {...node} />)
  })

  loadMore = async () => {
    if (this.state.loadingMore || !this.props.fetchMore) return
    this.setState({ loadingMore: true })
    await this.props.fetchMore({
      variables: {
        page: this.props.items.pageInfo.nextPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const { cacheKey } = this.props
        return {
          [cacheKey]: {
            ...fetchMoreResult[cacheKey],
            edges: [
              ...prev[cacheKey].edges,
              ...fetchMoreResult[cacheKey].edges,
            ],
          },
        }
      },
    })
    this.setState({ loadingMore: false })
  }

  render() {
    const { items } = this.props
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMore}
        hasMore={items.pageInfo.hasNextPage}
        className={this.props.className}
        loader={<div key={0}><Loader.Small /></div>}
        element="ul"
      >
        {this.renderItems(items.edges)}
      </InfiniteScroll>
    )
  }
}

export default InfiniteList
