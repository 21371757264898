import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router'
import { NavLink } from 'react-router-dom'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Loader from '../../components/Loader'
import UserInfo from './info'
import UserUsers from './users'
import ProfileQuery from '../../graphql/ProfileQuery'

export const UserRouteComponent = ({ data: { me: user, loading } }) => {
  if (loading) return <Loader />
  const organizationRole = user && user.organization && user.organization.role
  if (!organizationRole)
    throw new Error('No organization role available for user')
  return (
    <React.Fragment>
      <p id="breadcrumb">
        <NavLink to="/me/info" activeClassName="active">
          Basic Information
        </NavLink>
        <span> // </span>
        {organizationRole === 'admin' && (
          <NavLink to="/me/users" activeClassName="active">
            Users
          </NavLink>
        )}
      </p>
      <Route exact path="/me/info" component={UserInfo} />
      {organizationRole === 'admin' && (
        <Route exact path="/me/users" component={UserUsers} />
      )}
    </React.Fragment>
  )
}

UserRouteComponent.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    me: PropTypes.shape({
      organization: PropTypes.shape({
        role: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
}

export default graphql(ProfileQuery)(UserRouteComponent)
