export const TRACK_INFO = 'trackInfo/TRACK_INFO'
export const TRACK_INFO_PENDING = 'trackInfo/TRACK_INFO_PENDING'
export const TRACK_INFO_FULFILLED = 'trackInfo/TRACK_INFO_FULFILLED'
export const TRACK_INFO_REJECTED = 'trackInfo/TRACK_INFO_REJECTED'

export const LOAD_RELATED_TRACKS = 'trackInfo/LOAD_RELATED_TRACKS'
export const LOAD_RELATED_TRACKS_PENDING =
  'trackInfo/LOAD_RELATED_TRACKS_PENDING'
export const LOAD_RELATED_TRACKS_FULFILLED =
  'trackInfo/LOAD_RELATED_TRACKS_FULFILLED'
export const LOAD_RELATED_TRACKS_REJECTED =
  'trackInfo/LOAD_RELATED_TRACKS_REJECTED'

export const LOAD_RELATED_ARTISTS = 'trackInfo/LOAD_RELATED_ARTISTS'
export const LOAD_RELATED_ARTISTS_PENDING =
  'trackInfo/LOAD_RELATED_ARTISTS_PENDING'
export const LOAD_RELATED_ARTISTS_FULFILLED =
  'trackInfo/LOAD_RELATED_ARTISTS_FULFILLED'
export const LOAD_RELATED_ARTISTS_REJECTED =
  'trackInfo/LOAD_RELATED_ARTISTS_REJECTED'
