import { Map, List, fromJS } from 'immutable'
import * as types from '../types/searchTypes'

const initialState = Map({
  tracks: List(),
})

export default function searchReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SEARCH_BROWSE_INIT:
      return state
        .set('filters', fromJS(payload.filters))
        .set('query', payload.query)
        .set('sort', payload.sort)
    case types.SEARCH_BROWSE_FILTER_ADDED:
      const { value } = payload
      return state.setIn(['filters', payload.name], value)
    case types.SEARCH_BROWSE_FILTER_REMOVED:
      return state.deleteIn(['filters', payload.name])
    case types.SEARCH_INPUT_CHANGED:
      return state.set('query', payload)
    case types.SEARCH_INPUT_CLEAR:
      return state.set('query', '')
    case types.SEARCH_RESET:
      return state
        .set('query', '')
        .set('filters', fromJS({}))
        .set('sort', '')
    case types.SEARCH_SORT_CHANGED:
      return state.set('sort', payload)
    default:
      return state
  }
}
