import * as types from '../types/audioPlayerTypes'

export function ShowPlayer() {
  return { type: types.SHOW_PLAYER }
}

export function HidePlayer() {
  return { type: types.HIDE_PLAYER }
}

export function PauseTrack() {
  return { type: types.PAUSE_TRACK }
}

export function AddTrackToPlaylist(track) {
  return { type: types.ADD_TRACK_TO_PLAYLIST, track }
}

export function PlayTrack(track, albumUPC) {
  return { type: types.PLAY_TRACK, track, albumUPC }
}

export function PlayNextTrack() {
  return { type: types.PLAY_NEXT_TRACK }
}

export function PlayPrevTrack() {
  return { type: types.PLAY_PREV_TRACK }
}

export function StoppedPlaying() {
  return dispatch => {
    dispatch(HidePlayer())
  }
}

export function Play(track, album) {
  return (dispatch, getState) => {
    const audioPlayer = getState().get('audioPlayer')
    if (!audioPlayer.get('visible')) dispatch(ShowPlayer())
    // In order to display the correct cover image for each
    // track we need to attach the album cover to the track
    // so is possible to use prev/next track without getting
    // the album each time
    const playerTrack = { ...track }
    if ('album' in track) {
      playerTrack.image = { ...track.album.images[0] }
    } else {
      playerTrack.image = { ...album.images[0] }
    }
    const albumUPC = album ? album.upc : track.album.upc
    // if (!albumUPC) throw new Error('Album UPC missing')
    dispatch(AddTrackToPlaylist(playerTrack))
    dispatch(PlayTrack(playerTrack, albumUPC))
  }
}

export function PlayAlbum(album) {
  return (dispatch, getState) => {
    const audioPlayer = getState().get('audioPlayer')
    // If the audioplayer is playing the album, pause it...
    if (
      audioPlayer.get('playingAlbumUPC') === album.upc &&
      !audioPlayer.get('isPaused')
    ) {
      dispatch(PauseTrack())
      return
    }
    // otherwise... play the first track of the album
    const track = album.tracks[0]
    dispatch(Play(track, album))
  }
}

export function VolumeChanged(volume) {
  return { type: types.VOLUME_CHANGED, volume }
}

export function MutePlayer() {
  return { type: types.MUTE_PLAYER }
}
