/* @flow */
import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import PrivateRoute from '../components/AuthRoute'
import ExperimentalFeature from '../components/ExperimentalFeature'

// Get layout container
import Layout from '../containers/Layout'
import withContentLayout from '../containers/ContentLayoutHOC'
// Common routes
import NotFound from './common/notFound'
// Auth routes
import AuthLogin from './auth/login'
import AuthLogout from './auth/logout'
import RegisterInvite from './auth/invite'
import ResetPassword from './auth/resetPassword'
import IngestionFeedbackRequest from './ingestion/FeedbackRequest'

import Track from './track'
import Artist from './artist'
import ArtistsListCountry from './artistsLists/country'
import Search from './search'
import Playlists from './playlists'
import Playlist from './playlist'
import SensitivePlacements from './sensitivePlacements'
import TrackRanks from './ranks/TrackRankDetailView'

// Company Routes
import Ingestion from './ingestion'

// User Routes
import User from './user'

const Routes = () => (
  <div>
    <Switch>
      <Route
        exact
        path="/ingestion/feedback/:feedbackId"
        component={IngestionFeedbackRequest}
      />
      <Layout>
        <Switch>
          <Route exact path="/login" component={AuthLogin} />
          <Route
            exact
            path="/"
            render={() => <Redirect replace to="/search/browse" />}
          />
          <PrivateRoute exact path="/logout" component={AuthLogout} />
          <PrivateRoute path="/search" component={Search} />
          <Route exact path="/reset" component={ResetPassword} />
          <Route exact path="/join/:inviteId" component={RegisterInvite} />
          <PrivateRoute exact path="/logout" component={AuthLogout} />
          <Route
            exact
            path="/"
            render={() => <Redirect replace to="/search/browse" />}
          />
          <PrivateRoute path="/search" component={Search} />
          <PrivateRoute
            path="/playlists"
            component={withContentLayout(Playlists)}
          />
          <PrivateRoute
            path="/playlist/:playlistId"
            component={withContentLayout(Playlist)}
          />
          <PrivateRoute path="/ranks" component={withContentLayout(TrackRanks)} />
          <PrivateRoute
            exact
            path="/track/:trackId"
            component={withContentLayout(Track)}
          />
          <PrivateRoute exact path="/artist/:artistId" component={Artist} />
          <PrivateRoute
            exact
            path="/artists/list/:country/:letter?"
            component={ArtistsListCountry}
          />
          <PrivateRoute
            exact
            path="/ingestion/(.+)?"
            component={withContentLayout(Ingestion, 'ingestion')}
          />
          <PrivateRoute
            path="/me/(.+)/"
            component={withContentLayout(User)}
          />
          <Route
            exact
            path="/me"
            render={() => <Redirect replace to="/me/info" />}
          />
          <ExperimentalFeature feature="sensitive-placements">
            <PrivateRoute
              exact
              path="/sensitive-placements"
              component={SensitivePlacements}
            />
          </ExperimentalFeature>
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Switch>
  </div>
)

export default Routes
