import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import gql from 'graphql-tag'
import { constants } from '@onerights/common'
import ORMutation from '../../../../components/ORMutation'
import ItemList from './ItemList'
import ProductionFileItem from './ProductionFileItem'

const SEND_TO_NCB_MUTATION = gql(`
  mutation SendToNCBMutation($productionId: ID!) {
    sendToNCB(productionId: $productionId) {
      sent
      production {
        id
        status
        files {
          id
          status
          tracks {
            id
            status
          }
        }
      }
    }
  }
`)

const SEND_FOR_FEEDBACK_MUTATION = gql(`
  mutation SendForFeedbackMutation($productionId: ID!) {
    sendForFeedback(productionId: $productionId) {
      sent
      production {
        id
        status
        files {
          id
          status
          tracks {
            id
            status
          }
        }
      }
    }
  }
`)

const SET_AS_INVOICED = gql(`
  mutation SetAsInvoiced($productionId: ID!, $invoiced: Boolean!) {
    productionInvoiced(productionId: $productionId, invoiced: $invoiced) {
      invoiced
      production {
        id
        invoiced
      }
    }
  }
`)

const ProductionItem = ({
  id,
  title,
  totalFiles,
  fetchStatus,
  invoiced,
  status,
  files,
  onOpen,
  isOpen,
}) => (
  <article className={classNames('production-item', { open: isOpen })}>
    <header>
      <div className="colp colp-2x">{title}</div>
      <div className="colp">{totalFiles} files</div>
      <div className="colp">Fetch-Status: {fetchStatus}</div>
      <div className="colp colp-2x">Status: {status}</div>
      <div className="colp colp-has-btn">
        <button
          type="button"
          data-id={id}
          onClick={onOpen}
          className="btn w100"
        >
          {!isOpen ? 'Open' : 'Close'}
        </button>
      </div>
    </header>
    <div className="production-item-content">
      <div className="production-item-list">
        <ItemList items={files} component={ProductionFileItem} />
      </div>
      {status === 'REPORTED' && (
        <div className="production-item-controls">
          <ORMutation
            mutation={SET_AS_INVOICED}
            variables={{ productionId: id }}
            isPrivate
          >
            {(setInvoiced, { loading }) => (
              <label htmlFor="invoiced">
                <input
                  id="invoiced"
                  type="checkbox"
                  disabled={loading}
                  checked={invoiced}
                  onChange={e => {
                    const { checked } = e.target
                    setInvoiced({ variables: { invoiced: checked } })
                  }}
                />
                Invoiced (Only filled by Morten)
              </label>
            )}
          </ORMutation>
        </div>
      )}
      {status !== constants.REPORT_DONE.toUpperCase() && (
        <div className="production-item-controls">
          <ORMutation
            mutation={SEND_FOR_FEEDBACK_MUTATION}
            variables={{ productionId: id }}
            isPrivate
          >
            {(sendForFeedback, { loading }) => (
              <button
                disabled={[
                  constants.FEEDBACK_REQUESTED.toUpperCase(),
                  constants.REPORT_READY.toUpperCase(),
                ].includes(status)}
                type="button"
                onClick={sendForFeedback}
                className="btn"
              >
                {loading ? 'Sending...' : 'Send tracks for feedback'}
              </button>
            )}
          </ORMutation>
          <ORMutation
            mutation={SEND_TO_NCB_MUTATION}
            variables={{ productionId: id }}
            isPrivate
          >
            {(sendToNCB, { loading }) => (
              <button
                disabled={status !== constants.REPORT_READY.toUpperCase()}
                type="button"
                onClick={sendToNCB}
                className="btn"
              >
                {loading ? 'Sending...' : 'Send to NCB'}
              </button>
            )}
          </ORMutation>
        </div>
      )}
    </div>
  </article>
)

ProductionItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  invoiced: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
    })
  ),
  totalFiles: PropTypes.number.isRequired,
  fetchStatus: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
}

ProductionItem.defaultProps = {
  isOpen: false,
  files: [],
}

export default ProductionItem
