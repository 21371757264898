import * as types from '../types/notificationTypes'

export function ShowMessage(message) {
  return { type: types.ADD_NOTIFICATION, message, notificationType: 'info' }
}

export function ShowSuccess(message) {
  return { type: types.ADD_NOTIFICATION, message, notificationType: 'success' }
}

export function ShowAlert(message) {
  return { type: types.ADD_NOTIFICATION, message, notificationType: 'alert' }
}

export function HideMessage(id) {
  return { type: types.HIDE_NOTIFICATION, id }
}
