import { push } from 'react-router-redux'
import querystring from 'qs'
import { parseSearch, objectifyFilters } from '../utils/router'
import * as types from '../types/searchTypes'

function SetQueryStringsToURL() {
  return (dispatch, getState) => {
    const search = getState()
      .get('search')
      .toJS()
    const objFilters = objectifyFilters(search.filters)
    let qsSearch = querystring.stringify(objFilters)
    // If there is a query "?q=text" append to url
    if (search.query && search.query.length > 0) {
      if (qsSearch) qsSearch += '&'
      qsSearch += querystring.stringify({ q: search.query })
    }
    // If there is sort.. append to url
    if (search.sort && search.sort.length > 0) {
      if (qsSearch) qsSearch += '&'
      qsSearch += querystring.stringify({ sort: search.sort })
    }
    dispatch(push({ search: qsSearch }))
  }
}

export function SearchInputClear() {
  return dispatch => {
    dispatch({ type: types.SEARCH_INPUT_CLEAR })
    dispatch(SetQueryStringsToURL())
  }
}

export function SearchInputChanged(searchText) {
  return dispatch => {
    dispatch({ type: types.SEARCH_INPUT_CHANGED, payload: searchText })
    dispatch(SetQueryStringsToURL())
  }
}

export function AddSearchFilter(pathname, { name, value }) {
  return dispatch => {
    if (!name || !value) throw new Error('filter value or name required')
    dispatch({
      type: types.SEARCH_BROWSE_FILTER_ADDED,
      payload: { name, value },
    })
    dispatch(SetQueryStringsToURL())
  }
}

export function ChangeSort(sortedBy) {
  return dispatch => {
    dispatch({ type: types.SEARCH_SORT_CHANGED, payload: sortedBy })
    dispatch(SetQueryStringsToURL())
  }
}

export function RemoveSearchFilter(pathname, filter) {
  return dispatch => {
    dispatch({ type: types.SEARCH_BROWSE_FILTER_REMOVED, payload: filter })
    dispatch(SetQueryStringsToURL())
  }
}

export function ResetSearch() {
  return dispatch => {
    dispatch({ type: types.SEARCH_RESET })
    dispatch(SetQueryStringsToURL())
  }
}

export function SearchInputQueryInit(urlSearch) {
  return dispatch => {
    const searchObj = parseSearch(urlSearch)
    dispatch({ type: types.SEARCH_INPUT_CHANGED, payload: searchObj.query })
  }
}

export function AddInitFiltersFromUrl(urlSearch) {
  return dispatch => {
    const searchObj = parseSearch(urlSearch)
    dispatch({ type: types.SEARCH_BROWSE_INIT, payload: searchObj })
  }
}
