import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import qs from 'qs'

/** components */
import ExperimentalFeature from '../../components/ExperimentalFeature'
import Loader from '../../components/Loader'
import TrackList from '../../components/trackList'
import LetterPicker from '../../components/LetterPicker'

const ARTISTS_LIST_COUNTRY = gql(`
  query TracksForSale($prefix: String!, $page: Int) {
    tracks(forSale: true, prefix: $prefix, page: $page) {
      prefixes
      pageInfo {
        totalCount
        hasNextPage
        nextPage
      }
      edges {
        node {
          id
          isrc
          displayTitle
          selling {
            priceText
            type
          }
          previewUrl
          downloadUrl
          artists {
            id
            displayName
            popularity
          }
          album {
            displayTitle,
            releaseDate,
            genres,
            upc
            images {
              url
            }
          }
        }
      }
    }
  }
`)

const SensitivePlacementsView = ({ location }) => {
  // Set the A as the default prefix if nothing has been selected in the url query
  const searchQuery = qs.parse(location.search.replace('?', ''))
  const prefix = searchQuery && searchQuery.l ? searchQuery.l : 'a'

  return (
    <ExperimentalFeature feature="sensitive-placements">
      <main id="searched">
        <div id="content">
          <Query query={ARTISTS_LIST_COUNTRY} variables={{ prefix }}>
            {({ loading, error, data, fetchMore }) => {
            if (loading) return <Loader />
            if (
              data &&
              data.tracks &&
              data.tracks.edges &&
              data.tracks.edges.length === 0
            ) {
              return <p>There are no results for the letter selected.</p>
            }
            return (
              <div>
                <header className="vert">
                  <h3>ONE STOP FOR SENSITIVE PLACEMENT</h3>
                  <p>
                    Can be used for trailers, bumper, in/out and vignette. Deal
                    terms subject to specific agreement.
                  </p>
                </header>
                <LetterPicker
                  prefixes={data.tracks.prefixes}
                  activePrefix={prefix}
                  itemLinkTo={char =>
                    `/sensitive-placements?l=${char.toLowerCase()}`
                  }
                />
                <hr />
                <TrackList tracks={data.tracks} fetchMore={fetchMore} />
              </div>
            )
          }}
          </Query>
        </div>
      </main>
    </ExperimentalFeature>
  )
}

SensitivePlacementsView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
}

export default SensitivePlacementsView
