import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ArtistDefaultImage from '../../assets/img/artist.svg'

const ArtistComponent = ({
  id,
  images,
  displayName,
  totalTracks,
}) => {
  const image = (images.length >= 2) ? images[1].url : ArtistDefaultImage
  const getTotalTracks = (totalTracks) ? `${totalTracks} tracks` : null
  return (
    <li>
      <Link to={`/artist/${id}`}>
        <img src={image} alt={displayName} />
        <figure className="triangle" />
        <span>
          <h5>{displayName}</h5>
          <h6>{getTotalTracks}</h6>
        </span>
      </Link>
    </li>
  )
}

ArtistComponent.defaultProps = {
  totalTracks: 0,
}

ArtistComponent.propTypes = {
  id: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
  })).isRequired,
  displayName: PropTypes.string.isRequired,
  totalTracks: PropTypes.number,
}

export default ArtistComponent
