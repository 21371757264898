import { fromJS } from 'immutable'
import * as types from '../types/userTypes'

export default function userReducer(state = {}, action) {
  switch (action.type) {
    case types.SET_USER:
      return state.set('user', fromJS(action.user))
    case types.UNSET_USER:
      return state.delete('user')
    default:
      return state
  }
}
