import gql from 'graphql-tag'

export default gql(`
  query SearchTracksQuery(
    $sungLanguage: String,
    $precleared: Boolean,
    $trackPopularityGte: Int,
    $trackPopularityLte: Int,
    $artistPopularityGte: Int,
    $artistPopularityLte: Int,
    $releaseDateGte: String,
    $releaseDateLte: String,
    $bpmLte: Int,
    $bpmGte: Int,
    $genres: String,
    $country: String,
    $key: String,
    $page: Int,
    $size: Int,
    $sort: TracksSort,
  ) {
    tracks(
      sungLanguage: $sungLanguage,
      precleared: $precleared,
      popularity_gte: $trackPopularityGte,
      popularity_lte: $trackPopularityLte,
      artistPopularity_gte: $artistPopularityGte,
      artistPopularity_lte: $artistPopularityLte,
      releaseDate_gte: $releaseDateGte,
      releaseDate_lte: $releaseDateLte,
      bpm_lte: $bpmLte,
      bpm_gte: $bpmGte,
      key: $key,
      genres: $genres,
      country: $country,
      sort: $sort,
      page: $page,
      size: $size,
    ) {
      filters {
        countries {
          label
          value
        }
        genres
        keys {
          label
          value
        }
        languages {
          label
          value
        }
        bpm {
          min {
            label
            value
          }
          max {
            label
            value
          }
        }
        artistPopularity {
          min {
            label
            value
          }
          max {
            label
            value
          }
        }
        trackPopularity {
          min {
            label
            value
          }
          max {
            label
            value
          }
        }
      }
      pageInfo {
        nextPage
        hasNextPage
      }
      edges {
        node {
          id
          displayTitle,
          isrc,
          previewUrl,
          streamUrl
          downloadUrl
          waveforms {
            datUrl
            imageUrl
          }
          artists {
            id
            displayName,
            popularity,
          }
          album {
            displayTitle,
            releaseDate,
            genres,
            upc
            images {
              url,
            }
          }
        }
      }
    }
  }
`)
