import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { withRouter } from 'react-router'
import { graphql, compose } from 'react-apollo'
import { withFormik, Form, Field, FieldArray } from 'formik'
import * as yup from 'yup'
import IngestionUploader from './IngestionUploader'
import InputField from '../../../components/InputField'
import withNotifications from '../../../containers/NotificationsHOC'
import ProductionsQuery from '../../../graphql/productions/productionsQuery'

const CreateProductionMutation = gql(`
  mutation CreateProductionMutation($input: ProductionInput!) {
    createProduction(productionInput: $input) {
      id
      title
      fetchStatus
    }
  }
`)

const FRAME_RATES = ['23.976', '24', '25', '29.97', '30', '50', '59.94', '60']

const IngestionForm = ({ values, isValid, isSubmitting, setFieldValue }) => {
  return (
    <Form>
      <section className="c">
        <h3 className="toppadding">1: Upload production files</h3>
        <p>
          Add your files to the uploader. You can click on each form and
          choose your files or drag and drop the files into each upload-field.
        </p>
        <IngestionUploader
          onFilesChange={files => setFieldValue('files', files)}
        />
      </section>
      <section className="c">
        <h3 className="toppadding">2: Company info</h3>
        <p>
          This is the required information that will be passed on to rights
          organizations and other entities. Only change prefilled text if
          necessary.
        </p>
        <div className="userbasic">
          <Field
            label="Company name"
            name="company.name"
            disabled
            required
            component={InputField}
          />
          <Field
            label="CVR (VAT no)"
            name="company.cvr"
            disabled
            required
            component={InputField}
          />
        </div>
        <div className="userbasic">
          <Field
            label="Address"
            name="company.address"
            disabled
            required
            component={InputField}
          />
          <Field
            label="Zip"
            name="company.zip"
            disabled
            required
            component={InputField}
          />
          <Field
            label="City"
            name="company.city"
            disabled
            required
            component={InputField}
          />
        </div>
        <h5>Contact Person</h5>
        <p>
          Please specify a person that we can contact if needed to clarify or
          solve issues. This help us speed up the registration.
        </p>
        <div className="userbasic">
          <Field
            label="Name"
            name="contact.name"
            required
            component={InputField}
          />
          <Field
            label="Email"
            type="email"
            name="contact.email"
            required
            component={InputField}
          />
          <Field
            label="Phone"
            name="contact.phone"
            required
            component={InputField}
          />
        </div>
      </section>
      <section className="c">
        <h3>3: Production</h3>
        <p>
          Required information is needed to make sure reporting is done correct.
        </p>
        <div className="userbasic">
          <Field
            label="Title"
            name="production.title"
            required
            component={InputField}
          />
        </div>
        <div className="userbasic">
          <Field
            label="Frame Rate"
            type="select"
            name="production.frameRate"
            required
            component={InputField}
            options={FRAME_RATES}
          />
          <Field
            label="Showing Area"
            type="select"
            name="production.showingArea"
            required
            component={InputField}
            options={['Nordics', 'Europe', 'World']}
          />
          <Field
            label="Production type"
            type="select"
            name="production.productionType"
            required
            component={InputField}
            options={[
              'Other TV-Production',
              'Live On Tape (production with audience)',
              'Concerts',
              'TV Drama (showed in 1 Nordic country)',
              'TV Drama (showed in several Nordic countries)',
              'TV Drama (showed outside the Nordics)',
              'Movie',
              'Shortfilm (with cinema distribution)',
              'Shortfilm (without cinema distribution)',
              'Schoolproject',
              'Internal presentation',
              'Documentary (with cinema distribution)',
              'Documentary (without cinema distribution)',
              'Educational production',
            ]}
          />
          <Field
            label="Display type"
            type="select"
            name="production.displayType"
            required
            component={InputField}
            options={[
              'Public showing (TV, cinema, internet…)',
              'DVD',
              'VOD (with paywall)',
            ]}
          />
        </div>
      </section>
      <FieldArray
        name="files"
        render={() => {
          if (values.files.length === 0) return null
          return (
            <section className="c">
              <h3 className="toppadding">4: Custom Notes</h3>
              <p>
                Is there anything we should know about each production that will
                minimize the need for feedback later on? We've listed all files
                you've added below so you can add any required info.
              </p>
              <ul className="uploadnotes">
                {values.files.map((file, index) => (
                  <li key={file.id}>
                    <p>{file.fileName}</p>
                    <div className="userbasic">
                      <Field
                        name={`files.${index}.note`}
                        component={InputField}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          )
        }}
      />
      <section className="c">
        <button
          disabled={isSubmitting || !isValid}
          type="submit"
          className="btn w100 btnsend right green"
        >
          Send in registration
        </button>
      </section>
    </Form>
  )
}

IngestionForm.propTypes = {
  values: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  withNotifications,
  graphql(CreateProductionMutation, { name: 'createProduction' }),
  withFormik({
    validateOnBlur: true,
    displayName: 'IngestionForm',
    initialValues: {
      files: [],
      contact: {
        name: '',
        email: '',
        phone: '',
      },
      company: {
        name: '',
        cvr: '',
        address: '',
        zip: '',
        city: '',
      },
      production: {
        title: '',
        showingArea: '',
        productionType: '',
        displayType: '',
      },
    },
    mapPropsToValues: ({ user }) => {
      return {
        files: [],
        contact: {
          name: user.name,
          email: user.email,
          phone: '',
        },
        company: {
          name: user.organization.name,
          cvr: user.organization.cvr,
          address: user.organization.address,
          zip: user.organization.zip,
          city: user.organization.city,
        },
      }
    },
    validationSchema: yup.object().shape({
      contact: yup.object().shape({
        name: yup
          .string()
          .min(3)
          .required(),
        email: yup
          .string()
          .email()
          .required(),
        phone: yup
          .string()
          .min(6)
          .max(10)
          .required(),
      }),
      production: yup.object().shape({
        title: yup
          .string()
          .min(3)
          .required(),
        frameRate: yup
          .string()
          .min(2)
          .required(),
        showingArea: yup
          .string()
          .min(3)
          .required(),
        productionType: yup
          .string()
          .min(3)
          .required(),
        displayType: yup
          .string()
          .min(3)
          .required(),
      }),
      files: yup
        .array()
        .of(
          yup.object().shape({
            fileName: yup.string().required(),
            id: yup.string().required(),
            note: yup.string(),
          })
        )
        .required(),
    }),
    handleSubmit: async (
      { production, files, contact },
      {
        setSubmitting,
        resetForm,
        props: { createProduction, onError, onSuccess, history },
      }
    ) => {
      try {
        setSubmitting(true)
        const input = {
          title: production.title,
          frameRate: production.frameRate,
          showingArea: production.showingArea,
          productionType: production.productionType,
          displayType: production.displayType,
          contactPerson: contact,
          files: files.map(f => ({ id: f.id, note: f.note })),
        }
        await createProduction({
          variables: { input },
          // add the new production to the cache
          update: (store, { data }) => {
            const variables = { status: 'PENDING' }
            const storeData = store.readQuery({
              query: ProductionsQuery,
              variables,
            })
            storeData.productions.push(data.createProduction)
            store.writeQuery({
              query: ProductionsQuery,
              data: storeData,
              variables,
            })
          },
        })
        resetForm()
        setSubmitting(false)
        onSuccess('Production ingested successfully!')
        history.replace('/ingestion')
      } catch (e) {
        setSubmitting(false)
        onError(e)
      }
    },
  })
)(IngestionForm)
