import React from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import * as yup from 'yup'

export const ResetPasswordForm = ({
  values,
  isValid,
  handleSubmit,
  handleChange,
}) => (
  <form onSubmit={handleSubmit}>
    <input
      type="email"
      name="email"
      placeholder="Your email"
      value={values.email}
      onChange={handleChange}
    />
    {isValid && (
      <button type="submit" className="btn btn-icon btnsend right black">
        RESET PASSWORD
      </button>
    )}
  </form>
)

ResetPasswordForm.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string,
  }),
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}
ResetPasswordForm.defaultProps = {
  values: {
    email: '',
  },
}

export default withFormik({
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .min(6)
      .max(30)
      .email()
      .required(),
  }),
  handleSubmit: async (values, { props, resetForm }) => {
    await props.onReset({ email: values.email })
    resetForm()
  },
})(ResetPasswordForm)
