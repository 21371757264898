import _ from 'lodash'
/**
 * Returns formatted time mm:ss from the input milliseconds
 * @param time {number}
 * @param timeType {string} the time to use for conversion. Defaults to 'ms'
 * @returns {string} formatted time mm:ss
 */
export function formatTime(time, timeType = 'ms') {
  let secs, mins

  // Milliseconds input
  if (timeType === 'ms') {
    secs = (time / 1000) % 60
    mins = (time / (1000 * 60)) % 60
  }

  // Seconds input
  if (timeType === 's') {
    secs = time % 60
    mins = (time / 60) % 60
  }

  secs = Math.floor(secs) || 0
  mins = Math.floor(mins) || 0
  const fullMins = `${mins < 10 ? '0' : ''}${mins}`
  const fullSeconds = `${secs < 10 ? '0' : ''}${secs}`
  return `${fullMins}:${fullSeconds}`
}

export function formatSongKey(key) {
  const songKeys = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb']
  if (key > 11) throw new Error(`Wrong key [${key}] to format a song`)
  return songKeys[key]
}

/**
 * Rounds the tempo based on input
 * @param tempo {number}
 * @returns {number}
 */
export function formatBPM(tempo) {
  return Math.floor(tempo)
}

/**
 * Returns major or minor based on number input
 * @param mode {number} 1 for Major and 0 for Minor
 * @returns {string}
 */
export function formatSongMode(mode) {
  return parseInt(mode, 10) === 1 ? 'Major' : 'Minor'
}

/**
 * Returns time signature string from a number
 * @param timeSignature {number} ex. 4 for 4/4 and 3 for 3/4
 * @returns {string}
 */
export function formatSongSignature(timeSignature) {
  switch (timeSignature) {
    case 4:
      return '4/4'
    case 3:
      return '3/4'
    case 6:
      return '6/8'
    default:
      return `${timeSignature}`
  }
}

/**
 * Returns loudness from input number
 * @param loudness {number}
 * @returns {number}
 */
export function formatSongLoudness(loudness) {
  return Math.round(parseFloat(loudness))
}

/**
 * Converts an amount to a percentage based on based
 * @param amount
 * @param base 100% to calculate
 * @returns {string}
 */
export function formatToPercentage(amount, base = 1) {
  return `${Math.floor((amount.toFixed(2) / base) * 100)}%`
}

/**
 * Converts an array of objects to a single string of artists
 * @param  {[object]} artists
 * @return {string} comma separated artist
 */
export function formatArtistNames(artists) {
  if (artists.length === 1) return artists[0].displayName
  const limit = 5
  if (artists.length > limit) {
    const names = artists
      .slice(0, limit)
      .map(artist => artist.displayName)
      .join(', ')
    return `${names} & ${artists.length - limit} more...`
  }
  return artists.map(artist => artist.displayName).join(', ')
}

export function formatGenreName(genre) {
  // capitalize
  if (typeof genre !== 'string') return genre
  return genre
    .split('-')
    .map(s => _.capitalize(s))
    .join(' ')
}
