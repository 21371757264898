import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

/** components */
import Loader from '../../../components/Loader'
import InfiniteArtistList from '../../../components/InfiniteList/InfiniteArtistListComponent'
import LetterPicker from '../../../components/LetterPicker'

const ARTISTS_LIST_COUNTRY = gql(`
  query ArtistListCountry($prefix: String!, $country: String!) {
    artists(prefix: $prefix, country: $country) {
      prefixes
      pageInfo {
        nextPage
        hasNextPage
      }
      edges {
        node {
          id
          displayName
          totalTracks
          images {
            url
          }
        }
      }
    }
  }
`)

const ArtistListCountryView = ({
  match: {
    params: { country, letter },
  },
}) => {
  // Set the A as the default prefix if nothing has been selected
  const prefix = letter || 'a'

  // Temporarily we use hardcoded lists headings.
  // maybe in a future, we create lists dynamically
  const countriesHeadings = {
    dk: 'Danish',
    se: 'Swedish',
    no: 'Norwegian',
  }

  return (
    <main id="searched">
      <div id="content">
        <Query query={ARTISTS_LIST_COUNTRY} variables={{ prefix, country }}>
          {({ loading, error, data, fetchMore }) => {
            if (loading) return <Loader />
            if (
              data &&
              data.artists &&
              data.artists.edges &&
              data.artists.edges.length === 0
            ) {
              return <p>There are no results for the letter selected.</p>
            }
            return (
              <div>
                <header>
                  <h3>{countriesHeadings[country]} Artists - By letter</h3>
                </header>
                <LetterPicker
                  prefixes={data.artists.prefixes}
                  activePrefix={prefix}
                  itemLinkTo={char =>
                    `/artists/list/${country}/${char.toLowerCase()}`
                  }
                />
                <hr />
                <InfiniteArtistList
                  fetchMore={fetchMore}
                  artists={data.artists}
                />
              </div>
            )
          }}
        </Query>
      </div>
    </main>
  )
}

export default ArtistListCountryView
