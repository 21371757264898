import React from 'react'
import { Link } from 'react-router-dom'
import AdminContent from '../../components/AdminContent'
import ProductionsList from './ProductionsList'

const IngestionDashboardView = () => (
  <React.Fragment>
    <AdminContent>
      <section className="c">
        <h3 className="toppadding">Admin Dashboard</h3>
        <Link to="/ingestion/admin">
          <button type="button" className="btn btn-icon green left btnplus">
            Admin Ingestion
          </button>
        </Link>
      </section>
    </AdminContent>
    <section className="c">
      <h3 className="toppadding">Ingest new production</h3>
      <p>
        Before ingestion make sure you have .EDL files ready. We read the files
        to figure out the usage and will send an email back for the files we
        cannot recognise. Renaming files after download from the various
        platforms is not adviceable as they're usually impossible to find.
      </p>
      <Link to="/ingestion/submit">
        <button type="button" className="btn btn-icon green left btnplus">
          Start ingestion
        </button>
      </Link>
    </section>
    <section className="c">
      <h3 className="toppadding">In-process</h3>
      <ProductionsList done={false} />
    </section>
    <section className="c">
      <h3 className="toppadding">Finished</h3>
      <ProductionsList done />
    </section>
  </React.Fragment>
)

export default IngestionDashboardView
