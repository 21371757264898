import React from 'react'
import { Route } from 'react-router'
import ExperimentalFeature from '../../components/ExperimentalFeature'
import IngestionDashboardView from './IngestionDashboardView'
import IngestionSubmitView from './IngestionSubmit/IngestionSubmitView'
import IngestionAdminDashboard from './AdminDashboard'

const IngestionView = () => (
  <ExperimentalFeature feature="ingestion">
    <React.Fragment>
      <Route exact path="/ingestion" component={IngestionDashboardView} />
      <Route exact path="/ingestion/submit" component={IngestionSubmitView} />
      <Route path="/ingestion/admin" component={IngestionAdminDashboard} />
    </React.Fragment>
  </ExperimentalFeature>
)

export default IngestionView
