import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

function genCharArray(charA, charZ) {
  const a = []
  let i = charA.charCodeAt(0)
  const j = charZ.charCodeAt(0)
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i))
  }
  return a
}

const LetterPicker = ({ prefixes, activePrefix, itemLinkTo }) => {
  const charsArray = genCharArray('A', 'Z')
  // Attach special nordic chars manually
  charsArray.push('Æ')
  charsArray.push('Ø')
  charsArray.push('Å')

  return (
    <ul id="azlist">
      {charsArray.map(char => {
        const unused = prefixes.indexOf(char) === -1
        const classNames = classnames({
          active: char.toLowerCase() === activePrefix,
          unused,
        })
        if (unused) {
          return (
            <li key={char}>
              <span className={classNames}>{char}</span>
            </li>
          )
        }
        return (
          <li key={char}>
            <Link className={classNames} to={itemLinkTo(char)}>
              {char}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

LetterPicker.propTypes = {
  prefixes: PropTypes.arrayOf(PropTypes.string).isRequired,
  activePrefix: PropTypes.string.isRequired,
  itemLinkTo: PropTypes.func.isRequired,
}

export default LetterPicker
