import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NotificationItem from './components/NotificationItemComponent'
import * as NotificationActions from '../../actions/notificationActions'

class NotificationSystem extends Component {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.onHideItem = this.onHideItem.bind(this)
    this.state = {
      _notifications: !this.props.notifications || [],
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      _notifications: nextProps.notifications,
    })
  }

  onHideItem(id) {
    this.props.dispatch(NotificationActions.HideMessage(id))
  }

  render() {
    return (
      <div>
        {this.state._notifications.map(({ id, message, type }) => (
          <NotificationItem
            key={id}
            id={id}
            onHide={this.onHideItem}
            type={type}
            message={message}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.get('notifications'),
  }
}

export default connect(mapStateToProps)(NotificationSystem)
