import React from 'react'
import { Delete as DeleteIcon, Replay as ReplayIcon } from '@material-ui/icons'
import UploadImage from '../../../assets/img/upload.svg'

const IngestionUploaderZone = ({
  heading,
  childRef,
  files,
  onDeleteFile,
  onRetryFile,
}) => (
  <div className="upwrap">
    <h4>{heading}</h4>
    <div className="uploadfield" ref={ref => childRef(ref)}>
      <span>
        <p className="uptxt">
          <span>Add file</span>
          &nbsp;or drop files here
        </p>
        <img src={UploadImage} alt="upload" />
      </span>
    </div>
    <ul className="uploads">
      {files.map(({ file, progress, completed, status, hasFailed }) => (
        <li key={file.uniqueIdentifier}>
          <span className="upload-heading">
            <p>
              {file.fileName} ({status})
            </p>
            <p>{completed ? 'Done' : `${parseInt(progress, 10)}%`}</p>
          </span>
          <progress max="100" value={progress} />
          <section className="upload-controls">
            <span className="upload-control">
              <DeleteIcon
                style={{ fontSize: 16 }}
                onClick={() => onDeleteFile(file)}
              />
            </span>
            {hasFailed && (
              <span className="upload-control">
                <ReplayIcon
                  style={{ fontSize: 16 }}
                  onClick={() => onRetryFile(file)}
                />
              </span>
            )}
          </section>
        </li>
      ))}
    </ul>
  </div>
)

export default IngestionUploaderZone
