import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'

const ChatWrapper = styled.div`
  width: 100%;
`

const ChatWrapperTitle = styled.h5`
  margin: 0 0 10px 0;
  font-weight: 400;
  color: gray;
  font-size: 0.8rem;
`

const ChatMessagesWrapper = styled.div`
  background: #fbfbfb;
  padding: 20px;
  border: 1px solid #ebebeb;
`

const ChatItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`
const ChatMessage = styled.div``
const ChatSender = styled.span`
  margin: 0 0 10px 0;
  font-weight: 500;
  margin-right: 10px;
  font-size: 0.9rem;
  time {
    font-size: 11px;
    font-style: italic;
    font-weight: normal;
  }
`
const ChatMessageText = styled.span``

const ChatFeedback = ({ notes, title }) => {
  if (!notes || notes.length === 0) return null
  return (
    <React.Fragment>
      <ChatWrapper>
        <ChatWrapperTitle>{title}</ChatWrapperTitle>
        <ChatMessagesWrapper>
          {notes.map(({ id, sender, text, createdAt }) => (
            <ChatItemWrapper key={`note-${id}`}>
              <ChatMessage>
                <ChatSender>
                  {sender.name}{' '}
                  <time>({moment(createdAt).format('DD/MM/YYYY HH:mm')})</time>:
                </ChatSender>
                <ChatMessageText>{text}</ChatMessageText>
              </ChatMessage>
            </ChatItemWrapper>
          ))}
        </ChatMessagesWrapper>
      </ChatWrapper>
    </React.Fragment>
  )
}

ChatFeedback.defaultProps = {
  notes: [],
  title: 'Feedback notes history',
}

ChatFeedback.propTypes = {
  title: PropTypes.string,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      sender: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      text: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ),
}

export default ChatFeedback
