import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import AdminProductionLists from './AdminProductionLists'
import AdminContent from '../../../components/AdminContent'

const IngestionDashboard = ({ match: { path } }) => (
  <AdminContent>
    <Switch>
      <Route exact path={path} component={AdminProductionLists} />
    </Switch>
  </AdminContent>
)

IngestionDashboard.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
}

export default IngestionDashboard
