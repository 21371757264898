import React from 'react'
import PropTypes from 'prop-types'
import ORQuery from './ORQuery'
import ProfileQuery from '../graphql/ProfileQuery'

const AdminContent = ({ children }) => (
  <ORQuery
    query={ProfileQuery}
  >
    {({ data }) => {
      if (!data.me.isAdmin) return null
      return children
    }}
  </ORQuery>
)

AdminContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminContent
