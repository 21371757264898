import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import qs from 'qs'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import AuthLayout from '../../../containers/AuthLayout'
import { ShowAlert, ShowSuccess } from '../../../actions/notificationActions'
import Logo from '../../../assets/img/logo-new.svg'
import { OnLogin } from '../../../actions/authActions'
import { SetUser } from '../../../actions/userActions'

export class LoginView extends React.Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      OnLogin: PropTypes.func.isRequired,
    }).isRequired,
    notifications: PropTypes.shape({
      ShowSuccess: PropTypes.func.isRequired,
      ShowAlert: PropTypes.func.isRequired,
    }).isRequired,
  }

  state = {
    email: '',
    password: '',
  }

  handleSubmit = async e => {
    try {
      e.preventDefault()
      const { email, password } = this.state
      await this.props.login({
        variables: { email, password },
      })

      // Get the next path if coming from required auth route
      const params = qs.parse(this.props.location.search.replace(/^\?/, ''))

      setTimeout(() => {
        this.props.auth.OnLogin(params.next)
        this.props.notifications.ShowSuccess('Welcome back!')
      }, 50)
      // go to main
    } catch (error) {
      let errorMessage = 'An unexpected error happened'
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors
          .map(({ message }) => message)
          .join('')
      }
      this.props.notifications.ShowAlert(errorMessage)
    }
  }

  handleInputChange = e => {
    if (!e.target) throw new Error('no target in event')
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <AuthLayout>
        <div id="logingroup">
          <img src={Logo} alt="OneRights Official Logo" id="llogo" />
          <form onSubmit={this.handleSubmit}>
            <input
              type="email"
              autoComplete="email"
              value={this.state.email}
              name="email"
              onChange={this.handleInputChange}
              placeholder="Email"
              required
            />
            <input
              type="password"
              autoComplete="current-password"
              value={this.state.password}
              name="password"
              onChange={this.handleInputChange}
              placeholder="Password"
              required
            />
            <a role="button" onClick={this.handleSubmit}>
              <button
                type="submit"
                className="btn btn-icon btnlogin right black"
              >
                LOG IN
              </button>
            </a>
            <Link to="/reset">
              <p>Lost your password?</p>
            </Link>
          </form>
        </div>
      </AuthLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  notifications: bindActionCreators({ ShowAlert, ShowSuccess }, dispatch),
  user: bindActionCreators({ SetUser }, dispatch),
  auth: bindActionCreators({ OnLogin }, dispatch),
})

const loginMutation = gql(`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      isLogged
    }
  }
`)

const ProfileQuery = gql(`
  query Profile {
    me {
      name
      organization {
        name
        logo
        role
      }
    }
  }
`)

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  graphql(loginMutation, { name: 'login', options: { refetchQueries: [
    {
      query: ProfileQuery,
    }
  ], awaitRefetchQueries: true } })
)(LoginView)
