// Production constants
var FEEDBACK_NEEDED = 'feedback_needed';
var FEEDBACK_REQUESTED = 'feedback_requested';
var FEEDBACK_GIVEN = 'feedback_given';
var REPORT_SKIP = 'report_skip';
var REPORT_READY = 'report_ready';
var REPORT_DONE = 'report_done';
var FETCH_FAILED = 'fetch_failed';
module.exports = {
  FEEDBACK_NEEDED: FEEDBACK_NEEDED,
  FEEDBACK_REQUESTED: FEEDBACK_REQUESTED,
  FEEDBACK_GIVEN: FEEDBACK_GIVEN,
  REPORT_SKIP: REPORT_SKIP,
  REPORT_READY: REPORT_READY,
  REPORT_DONE: REPORT_DONE,
  FETCH_FAILED: FETCH_FAILED
};