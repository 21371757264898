import gql from 'graphql-tag'

export default gql(`
  query ProductionFileTrackQuery($trackId: ID!, $fileId: ID) {
    productionFileTrack(trackId: $trackId) {
      id
      title
      filename
      composers
      album
      trackNumber
      publishers
      label
      duration(fileId: $fileId)
      useType
      source
      status
      notes {
        id
        sender {
          name
        }
        text
        createdAt
      }
    }
  }
`)
