import _ from 'lodash'

/**
 *
 * @param {Object} filter filter values
 * @param {Object} defaultFilter filter values
 * @param {string} defaultFilter.min default filter min values
 * @param {Object} defaultFilter filter values
 * @param {string} defaultFilter.max default filter max values
 * @param {Object} defaultFilter default filter values
 * @param {Object} defaultFilter.min
 * @param {string} defaultFilter.min.value filter min value
 * @param {Object} defaultFilter default filter values
 * @param {Object} defaultFilter.max
 * @param {string} defaultFilter.max.value filter max value
 */
export function getRangesForKey(filter, defaultFilter) {
  const min = parseInt(_.get(defaultFilter, 'min.value', 1), 10)
  const max = parseInt(_.get(defaultFilter, 'max.value', 100), 10)
  if (!filter || _.isEmpty(filter)) {
    return {
      min,
      max,
      minSelected: min,
      maxSelected: max,
    }
  }
  const [minFilterSelected, minFilter] = filter.min.split('|')
  const [maxFilterSelected, maxFilter] = filter.max.split('|')
  return {
    min: parseInt(minFilter, 10),
    minSelected: parseInt(minFilterSelected, 10),
    max: parseInt(maxFilter, 10),
    maxSelected: parseInt(maxFilterSelected, 10),
  }
}

/**
 * Creates an object from filters that can be used to inject into the graphql
 * variables for apollo query
 * @param {Object} filters object of filters to get the values
 */
export const getFiltersVariables = filters => {
  const variables = {}

  if (filters.key) variables.key = filters.key
  if (filters.genres) variables.genres = filters.genres
  if (filters.country) variables.country = filters.country
  if (filters.sungLanguage) variables.sungLanguage = filters.sungLanguage
  if (filters.precleared) variables.precleared = filters.precleared

  // Add ranges values to the variables
  Array.prototype.forEach.call(
    ['artistPopularity', 'trackPopularity', 'bpm'],
    propKey => {
      if (filters.hasOwnProperty(propKey)) {
        const { maxSelected, minSelected } = getRangesForKey(filters[propKey])
        variables[`${propKey}Lte`] = maxSelected
        variables[`${propKey}Gte`] = minSelected
      }
    }
  )

  if (filters.yearRange) {
    variables.releaseDateGte = _.get(filters, 'yearRange.from', '')
    variables.releaseDateLte = _.get(filters, 'yearRange.until', '')
  }

  return variables
}

/**
 * Creates a filters object with default/custom values from the filters passed.
 * @param {Object} filters data to get the custom values if any
 */
export const getFiltersFromObject = (filters = {}, defaultRanges = {}) => {
  return {
    country: filters.country || '',
    sungLanguage: filters.sungLanguage || '',
    key: filters.key || '',
    genres: filters.genres || '',
    yearRange: {
      from: _.get(filters, 'yearRange.from', ''),
      until: _.get(filters, 'yearRange.until', ''),
    },
    artistPopularity: getRangesForKey(
      filters.artistPopularity,
      defaultRanges.artistPopularity
    ),
    trackPopularity: getRangesForKey(
      filters.trackPopularity,
      defaultRanges.trackPopularity
    ),
    bpm: getRangesForKey(filters.bpm, defaultRanges.bpm),
  }
}

export default {
  getFiltersVariables,
  getFiltersFromObject,
}
