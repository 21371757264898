import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import { Play } from '../../actions/audioPlayerActions'
import Track from './components/TrackComponent'
import Loader from '../Loader'

class TrackList extends Component {
  static propTypes = {
    isGrid: PropTypes.bool,
    audioPlayer: PropTypes.shape({
      isPaused: PropTypes.bool.isRequired,
      playingIndex: PropTypes.number,
      playlist: PropTypes.array.isRequired,
    }).isRequired,
    Play: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    tracks: PropTypes.shape({
      pageInfo: PropTypes.shape({
        nextPage: PropTypes.number.isRequired,
        hasNextPage: PropTypes.bool.isRequired,
      }).isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            isrc: PropTypes.string.isRequired,
            displayTitle: PropTypes.string.isRequired,
            artists: PropTypes.arrayOf(
              PropTypes.shape({
                displayName: PropTypes.string.isRequired,
              })
            ),
            album: PropTypes.shape({
              images: PropTypes.arrayOf(
                PropTypes.shape({
                  url: PropTypes.string.isRequired,
                })
              ),
            }),
          }).isRequired,
        }).isRequired
      ),
    }),
  }

  static defaultProps = {
    isGrid: false,
    tracks: {
      pageInfo: {
        nextPage: 2,
        hasNextPage: false,
      },
    },
  }

  state = {
    loadingMore: false,
    trackHovered: null,
  }

  /**
   * Dispatches action for Player to play a selected track from list
   * @param trackId {string}
   */
  onPlayTrack = trackId => {
    const trackSelected = this.props.tracks.edges.filter(
      ({ node }) => node.id === trackId
    )
    if (!trackSelected) throw new Error('No track found for player')
    this.props.Play(trackSelected[0].node)
  }

  onTrackHovered = trackId => {
    this.setState({ trackHovered: trackId })
  }

  onTrackUnhovered = () => {
    this.setState({ trackHovered: null })
  }

  /**
   * Returns if the item component is playing when render happens based on audioPlayer state
   * @param trackId {string} id of track to check if playing
   * @returns {boolean}
   */
  isPlaying = trackId => {
    const { playingIndex } = this.props.audioPlayer
    if (playingIndex === null) return false
    const isPlayingId = this.props.audioPlayer.playlist[playingIndex].id
    return isPlayingId === trackId && !this.props.audioPlayer.isPaused
  }

  renderTracks(edges = []) {
    return edges.map(({ node }) => (
      <Track
        isGrid={this.props.isGrid}
        key={node.id}
        isHovered={this.state.trackHovered === node.id}
        onHover={this.onTrackHovered}
        onHoverOut={this.onTrackUnhovered}
        onPlay={this.onPlayTrack}
        playing={this.isPlaying(node.id)}
        track={node}
      />
    ))
  }

  loadMore = async () => {
    if (this.state.loadingMore || !this.props.fetchMore) return
    this.setState({ loadingMore: true })
    await this.props.fetchMore({
      variables: {
        page: this.props.tracks.pageInfo.nextPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // handle playlists case
        if (fetchMoreResult.playlist) {
          return {
            playlist: {
              ...fetchMoreResult.playlist,
              tracks: {
                ...fetchMoreResult.playlist.tracks,
                edges: [
                  ...prev.playlist.tracks.edges,
                  ...fetchMoreResult.playlist.tracks.edges,
                ],
              },
            },
          }
        } else if (fetchMoreResult.tracks) {
          return {
            ...fetchMoreResult,
            tracks: {
              ...fetchMoreResult.tracks,
              edges: [...prev.tracks.edges, ...fetchMoreResult.tracks.edges],
            },
          }
        } else if (fetchMoreResult.tracksRank) {
          return {
            ...fetchMoreResult,
            tracksRank: {
              ...fetchMoreResult.tracksRank,
              edges: [
                ...prev.tracksRank.edges,
                ...fetchMoreResult.tracksRank.edges,
              ],
            },
          }
        }
        throw new Error(
          'Schema handling for Tracklist fetchMore not implemented'
        )
      },
    })
    this.setState({ loadingMore: false })
  }

  render() {
    const { tracks } = this.props
    const trackListClass = this.props.isGrid
      ? 'artists ar aplay grid'
      : 'tracks'
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMore}
        hasMore={tracks.pageInfo.hasNextPage}
        className={trackListClass}
        loader={
          <div key={0}>
            <Loader.Small />
          </div>
        }
        element="ul"
      >
        {this.renderTracks(tracks.edges)}
      </InfiniteScroll>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ Play }, dispatch)
const mapStateToProps = state => ({
  audioPlayer: state.get('audioPlayer').toJS(),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackList)
