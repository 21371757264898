import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { formatTime, formatArtistNames } from '../../utils/Formatters'
import { Play, PlayAlbum } from '../../actions/audioPlayerActions'
import Loader from '../../components/Loader'
import TrackList from '../../components/trackList'
import { FiltersWrapper, Filters } from '../../components/SearchFilters'

const PLAYLIST_DETAIL_QUERY = gql(`
  query PlaylistDetailQuery(
    $playlistId: ID!,
    $sungLanguage: String,
    $precleared: Boolean,
    $trackPopularityGte: Int,
    $trackPopularityLte: Int,
    $artistPopularityGte: Int,
    $artistPopularityLte: Int,
    $releaseDateGte: String,
    $releaseDateLte: String,
    $bpmLte: Int,
    $bpmGte: Int,
    $key: String,
    $genres: String,
    $page: Int,
    $size: Int,
    $sort: TracksSort,
  ) {
    playlist(playlistId: $playlistId) {
      id
      name
      category
      cover
      tracks(
        sungLanguage: $sungLanguage,
        precleared: $precleared,
        popularity_gte: $trackPopularityGte,
        popularity_lte: $trackPopularityLte,
        artistPopularity_gte: $artistPopularityGte,
        artistPopularity_lte: $artistPopularityLte,
        releaseDate_gte: $releaseDateGte,
        releaseDate_lte: $releaseDateLte,
        genres: $genres,
        bpm_lte: $bpmLte,
        bpm_gte: $bpmGte,
        key: $key,
        sort: $sort,
        page: $page,
        size: $size,
      ) {
        filters {
          genres
          keys {
            label
            value
          }
          languages {
            label
            value
          }
          bpm {
            min {
              label
              value
            }
            max {
              label
              value
            }
          }
          artistPopularity {
            min {
              label
              value
            }
            max {
              label
              value
            }
          }
          trackPopularity {
            min {
              label
              value
            }
            max {
              label
              value
            }
          }
        }
        pageInfo {
          nextPage
          hasNextPage
        }
        edges {
          node {
            id
            isrc
            displayTitle
            durationMs
            previewUrl
            downloadUrl
            streamUrl
            waveforms {
              datUrl
              imageUrl
            }
            album {
              id
              displayTitle
              releaseDate
              genres
              type
              upc
              images {
                url
              }
            }
            artists {
              id
              displayName
            }
          }
        }
      }
    }
  }
`)

class PlaylistDetailView extends React.Component {
  state = {
    viewMode: 'list',
    listSize: 10,
  }

  static propTypes = {
    Play: PropTypes.func.isRequired,
    audioPlayer: PropTypes.shape({
      isPaused: PropTypes.bool.isRequired,
      playingAlbumUPC: PropTypes.string,
      playingIndex: PropTypes.number,
      playlist: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        })
      ),
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        playlistId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }

  _isPlayingTrack = trackId => {
    const { playingIndex } = this.props.audioPlayer
    if (playingIndex === null) return false
    return (
      this.props.audioPlayer.playlist[playingIndex].id === trackId &&
      !this.props.audioPlayer.isPaused
    )
  }

  componentDidMount = () => {
    // check if grid view mode has been defined in path route
    // TODO: move this behaviour to use query string BUT as of today react-router-dom
    // is not parsing them.
    const isGrid = this.props.location.pathname.indexOf('/grid') >= 0
    if (isGrid) this.setState({ viewMode: 'grid', listSize: 30 })
  }

  _renderPlaylistTrack = (playlist, track, index) => {
    const isPlayingClassName = this._isPlayingTrack(track.id) ? 'pause' : 'play'
    return (
      <li key={track.id}>
        <section onClick={() => this.props.Play(track, track.album)}>
          <figure className="number">{index + 1}</figure>
          <figure className={isPlayingClassName} />
          <h4>
            {track.displayTitle}{' '}
            <span>{`•${formatArtistNames(track.artists)}`}</span>
            <time>({formatTime(track.durationMs)})</time>
          </h4>
        </section>
        <section>
          <Link to={`/track/${track.id}`}>
            <span className="cardinfo">Trackinfo</span>
          </Link>
        </section>
      </li>
    )
  }

  // check if view should be list mode
  isGrid = () => this.state.viewMode === 'grid'

  render() {
    return (
      <FiltersWrapper>
        {({ filtersQuery }) => (
          <Query
            query={PLAYLIST_DETAIL_QUERY}
            variables={{
              playlistId: this.props.match.params.playlistId,
              size: this.state.listSize,
              ...filtersQuery,
            }}
          >
            {({ loading, networkStatus, error, data, ...props }) => {
              if (loading && networkStatus === 1) {
                return <Loader />
              }
              if (error) return <p>An error happened...</p>
              const { playlist } = data
              return (
                <React.Fragment>
                  <header>
                    {this.isGrid() ? (
                      <h3>{playlist.name}</h3>
                    ) : (
                      <h1>{playlist.name}</h1>
                    )}
                  </header>
                  <div className="tracklistinfo singlepl">
                    {!this.isGrid() && (
                      <section className="plfilters">
                        <figure className="tleft">
                          <img
                            id="artwork"
                            src={playlist.cover}
                            alt={playlist.name}
                          />
                        </figure>
                        <Filters data={data.playlist.tracks.filters} />
                      </section>
                    )}
                    {loading && <Loader />}
                    {!this.isGrid() && !loading ? (
                      <div className="tracklist ar">
                        <TrackList
                          tracks={playlist.tracks}
                          fetchMore={props.fetchMore}
                        />
                      </div>
                    ) : (
                      <TrackList
                        tracks={playlist.tracks}
                        isGrid
                        fetchMore={props.fetchMore}
                      />
                    )}
                  </div>
                  <hr />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </FiltersWrapper>
    )
  }
}

const mapStateToProps = state => ({
  audioPlayer: state.get('audioPlayer').toJS(),
  trackPlaying:
    state.getIn([
      'audioPlayer',
      'playlist',
      state.getIn(['audioPlayer', 'playingIndex']),
    ]) || false,
  playerIsPaused: state.getIn(['audioPlayer', 'isPaused']),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ Play, PlayAlbum }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaylistDetailView)
