import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'

class AuthLayout extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  }

  componentDidMount() {
    if (this.props.isLogged) {
      this.props.dispatch(push('/'))
    }
  }

  render() {
    return (
      <div>
        <main className="login" id="artistbg">
          {this.props.children}
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLogged: state.get('auth').get('isLogged'),
})

export default connect(mapStateToProps)(AuthLayout)
