import React from 'react'
import PropTypes from 'prop-types'

const Select = ({
  options,
  selected,
  onChange,
  name,
  required,
  emptyValue,
}) => (
  <article className="dropdown">
    <select
      name={name}
      defaultValue={selected}
      onChange={onChange}
      required={required}
    >
      <option value="">{emptyValue}</option>
      {options.map(o => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  </article>
)

Select.propTypes = {
  emptyValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

Select.defaultProps = {
  emptyValue: 'Select...',
  required: false,
}

Select.defaultProps = {
  selected: '',
}

export default Select
