import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const PlaylistList = ({ playlists }) => {
  // group playlists by type in order to display sections
  const playlistsCategories = []
  playlists.forEach(p => {
    if (playlistsCategories.indexOf(p.category) < 0) {
      playlistsCategories.push(p.category)
    }
  })
  return (
    <div>
      {playlistsCategories.map(category => {
        const playlistsForCategory = playlists
          .filter(p => p.category === category)
          .sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order
            }
            return 0
          })
        return (
          <div key={category}>
            <header>
              <h3>{category}</h3>
            </header>
            <ul className="artists grid ar">
              {playlistsForCategory.map(p => (
                <li key={p.id}>
                  <Link to={`/playlist/${p.id}`}>
                    <img src={p.cover} alt={`${p.name} cover`} />
                    <span>
                      <h5>{p.name}</h5>
                      <h6>{p.totalTracks} tracks</h6>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

PlaylistList.defaultProps = {
  playlists: [],
}

PlaylistList.propTypes = {
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cover: PropTypes.string.isRequired,
      totalTracks: PropTypes.number.isRequired,
    }).isRequired
  ),
}

export default PlaylistList
