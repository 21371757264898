import React from 'react'
import PropTypes from 'prop-types'
import { formatGenreName } from '../../utils/Formatters'

class SearchGenresPicker extends React.Component {
  static propTypes = {
    genres: PropTypes.arrayOf(PropTypes.string).isRequired,
    selected: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selected: '',
  }

  state = {
    isExpanded: false,
    totalVisible: 5,
    selected: [],
  }

  componentDidMount() {
    this.setState({ selected: this.props.selected.split(',') })
  }

  toggleVisibility = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  removeAll = () => {
    this.setState({
      selected: [],
    }, () => {
      this.notify()
    })
  }

  notify = () => {
    this.props.onChange(this.state.selected.join(','))
  }

  /**
   * Detects when a checkbox is clicked to toggle its checked state.
   * In this case we expect label to include a data-name as we're not relying in the
   * native checkbox element
   */
  onToggleChange = (e) => {
    if (!e.target && !e.target.dataset && !e.target.dataset.name) return
    const genre = e.target.dataset.name
    this.setState(state => {
      const idx = state.selected.findIndex(g => g === genre)
      if (idx >= 0) {
        state.selected.splice(idx, 1)
      } else {
        state.selected.push(genre)
      }
      return state
    }, () => {
      this.notify()
    })
  }

  getGenres() {
    const { genres } = this.props
    if (!this.state.isExpanded) return genres.slice(0, this.state.totalVisible)
    return genres
  }

  render() {
    return (
      <ul className="cblist">
        {
          this.getGenres().map(genre => (
            <li key={genre} onClick={this.onToggleChange}>
              <input checked={this.state.selected.includes(genre)} readOnly type="checkbox" />
              <label data-name={genre}>{formatGenreName(genre)}</label>
            </li>
          ))
        }
        <li id="settings">
          <button disabled={this.props.genres.length <= this.state.totalVisible} type="button" onClick={this.toggleVisibility}>{this.state.isExpanded ? 'Hide all' : 'Show all'}</button>
          <button disabled={this.state.selected.length === 0} type="button" onClick={this.removeAll}>Remove all</button>
        </li>
      </ul>
    )
  }
}

export default SearchGenresPicker
