import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const InputContainer = ({ containerClass, description, children }) => (
  <article className={containerClass}>
    <div className="inputfield-group">{children}</div>
    {description && <p className="inputfield-description">{description}</p>}
  </article>
)

const InputField = ({
  field,
  label,
  description,
  vertical,
  form: { errors, touched },
  ...props
}) => {
  /*
   * Handle the validation state based on touched and errors
   */
  const isTouched = _.get(touched, field.name)
  const errMsg = _.get(errors, field.name)
  const errMsgLabel = errMsg && errMsg.replace(field.name, label)

  const inputClass = classnames({
    warning: (props.isRequired || field.value !== '') && isTouched && errMsg,
  })

  const containerClass = classnames({
    inputfield: true,
    vertical,
  })

  // Forces an empty value if no value submitted with the field
  if (!field.value) field.value = ''

  if (props.type && props.type === 'select') {
    // build the options jsx
    const options = props.options.map(opt => {
      if (typeof opt === 'string')
        return (
          <option key={`opt-${opt}`} value={opt}>
            {opt}
          </option>
        )
      return (
        <option key={`opt-${opt.value}`} value={opt.value}>
          {opt.label ? opt.label : opt.value}
        </option>
      )
    })

    // Attach the label as a key of options BUT empty as
    // with this type of input we dont have a way to display a label
    if (label)
      options.unshift(
        <option key={`opt-${label}`} value="">{`${label}...`}</option>
      )

    // Attach label to dropdown only when in vertical mode
    return (
      <InputContainer description={description} containerClass={containerClass}>
        <div className="dropdown">
          <select {...field} {...props}>
            {options}
          </select>
        </div>
        {vertical && label && <label htmlFor={props.name}>{label}</label>}
      </InputContainer>
    )
  }

  return (
    <InputContainer description={description} containerClass={containerClass}>
      <input title={errMsgLabel} className={inputClass} {...field} {...props} />
      {label && <label htmlFor={props.name}>{label}</label>}
    </InputContainer>
  )
}

InputField.defaultProps = {
  description: '',
  required: false,
  vertical: false,
  type: 'text',
}

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  vertical: PropTypes.bool,
  type: PropTypes.string,
}

export default InputField
