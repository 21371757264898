import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, FieldArray, Field } from 'formik'
import gql from 'graphql-tag'
import InputField from '../../../components/InputField'
import ORQuery from '../../../components/ORQuery'
import ORMutation from '../../../components/ORMutation'
import withNotifications from '../../../containers/NotificationsHOC'
import ChatFeedback from '../components/ChatFeedback'
import OnerightsLogo from '../../../assets/img/logo-new.svg'

const FEEDBACK_REQUEST_QUERY = gql`
  query FeedbackRequestQuery($id: ID!, $token: String!) {
    feedback: productionFeedbackRequest(id: $id, token: $token) {
      production {
        title
        contactPerson {
          name
        }
        organization {
          id
          name
          logo
        }
      }
      files {
        id
        filename
        tracks {
          filename
          id
          title
          composers
          useType
          notes {
            id
            sender {
              name
            }
            text
            createdAt
          }
        }
      }
    }
  }
`

const SEND_FEEBACK_MUTATION = gql`
  mutation SendProductionFeedback(
    $id: ID!
    $token: String!
    $input: ProductionFeedbackInput!
  ) {
    sendProductionFeedback(id: $id, token: $token, input: $input) {
      sent
    }
  }
`

const FeedbackRequest = ({ feedbackId, token, onError, onComplete }) => {
  return (
    <ORQuery
      query={FEEDBACK_REQUEST_QUERY}
      variables={{ id: feedbackId, token }}
    >
      {({ data }) => {
        const { feedback } = data
        if (!feedback) {
          return (
            <Fragment>
              <h3>
                We{"'"}re sorry but this request is not longer valid for your
                organization.
              </h3>
            </Fragment>
          )
        }
        return (
          <Fragment>
            <div className="clientlogo">
              <img
                src={feedback.production.organization.logo}
                alt={feedback.production.organization.name}
              />
            </div>
            <div className="orlogo">
              <img src={OnerightsLogo} width={75} alt="OneRights logo" />
            </div>
            <section className="c">
              <h3 className="toppadding">
                Dear {feedback.production.contactPerson.name}, we need your
                help!
              </h3>
              <p>
                We haven{"'"}t been able to find the required information for
                some audio-content in the production. Below you{"'"}ll see the
                tracks we haven{"'"}t been able to complete without help. You
                can either help us by providing information about the artist and
                the title of the track or give out a hint in the notes. Then we
                {"'"}
                ll find the rest! Thanks!
              </p>
            </section>
            <h1>
              <span>Production:</span> {feedback.production.title}
            </h1>
            <ORMutation mutation={SEND_FEEBACK_MUTATION} skipError>
              {sendFeedback => (
                <Formik
                  initialValues={{ files: feedback.files }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      setSubmitting(true)
                      const input = {
                        files: values.files.map(f => ({
                          id: f.id,
                          tracks: f.tracks.map(t => ({
                            id: t.id,
                            artists: t.artists,
                            composers: t.composers,
                            publishers: t.publishers,
                            label: t.label,
                            album: t.album,
                            title: t.title,
                            useType: t.useType,
                            note: t.note,
                          })),
                        })),
                      }
                      const res = await sendFeedback({
                        variables: { id: feedbackId, token, input },
                      })
                      if (res.data.sendProductionFeedback.sent) {
                        onComplete()
                      } else {
                        onError(
                          'An unknown error happenned sending feedback. Please try again.'
                        )
                      }
                      setSubmitting(false)
                    } catch (e) {
                      setSubmitting(false)
                      onError(e)
                    }
                  }}
                >
                  {({ values: { files }, isValid, isSubmitting }) => (
                    <Form>
                      <FieldArray name="files">
                        {() =>
                          files.map((file, fileIndex) => (
                            <div key={file.id}>
                              <hr />
                              <h3>
                                Filename: <span>{file.filename}</span>
                              </h3>
                              <FieldArray name="tracks">
                                <ul className="users feedback">
                                  {file.tracks.map((track, trackIndex) => {
                                    const itemFullKey = `files[${fileIndex}].tracks[${trackIndex}]`
                                    return (
                                      <li key={track.id}>
                                        <p className="filename">
                                          Audiofile:{' '}
                                          <span>{track.filename}</span>
                                        </p>
                                        <section>
                                          <div className="userbasic note">
                                            <Field
                                              label="Usage Type"
                                              vertical
                                              type="select"
                                              name={`${itemFullKey}.useType`}
                                              required
                                              component={InputField}
                                              options={[
                                                'BACKGROUND',
                                                'EM',
                                                'BEM',
                                                'KAT',
                                                'SIG',
                                                'BUMP',
                                                'GT',
                                                'LIV',
                                                'VB',
                                              ]}
                                            />
                                            <Field
                                              label="Composers"
                                              vertical
                                              name={`${itemFullKey}.composers`}
                                              component={InputField}
                                            />
                                            <Field
                                              label="Publishers"
                                              vertical
                                              name={`${itemFullKey}.publishers`}
                                              component={InputField}
                                            />
                                          </div>
                                        </section>
                                        <section>
                                          <div className="userbasic note">
                                            <Field
                                              label="Track title"
                                              vertical
                                              name={`${itemFullKey}.title`}
                                              component={InputField}
                                            />
                                            <Field
                                              label="Album"
                                              vertical
                                              name={`${itemFullKey}.album`}
                                              component={InputField}
                                            />
                                            <Field
                                              label="Label"
                                              vertical
                                              name={`${itemFullKey}.label`}
                                              component={InputField}
                                            />
                                          </div>
                                        </section>
                                        <section>
                                          <div className="userbasic note">
                                            <ChatFeedback notes={track.notes} />
                                          </div>
                                        </section>
                                        <section>
                                          <div className="userbasic note">
                                            <Field
                                              label="Note"
                                              vertical
                                              name={`${itemFullKey}.note`}
                                              component={InputField}
                                              description="You can write a note to our content validator."
                                            />
                                          </div>
                                        </section>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </FieldArray>
                            </div>
                          ))
                        }
                      </FieldArray>
                      <button
                        disabled={!isValid || isSubmitting}
                        type="submit"
                        className="btn w100 btnsend right green"
                      >
                        {isSubmitting ? 'Sending...' : 'Send feedback'}
                      </button>
                    </Form>
                  )}
                </Formik>
              )}
            </ORMutation>
          </Fragment>
        )
      }}
    </ORQuery>
  )
}

FeedbackRequest.propTypes = {
  feedbackId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default withNotifications(FeedbackRequest)
