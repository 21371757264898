import React from 'react'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import ItemList from './ItemList'
import ProductionFileTrackItem from './ProductionFileTrackItem'

export default class ProductionFileItemTrack extends React.Component {
  state = {
    isExpanded: false,
  }

  toggleExpand = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }))
  }

  render() {
    const { label, items } = this.props
    const heading = (
      <div className="item-state-heading">
        <button type="button" className="btn" onClick={this.toggleExpand}>
          {label} ({items.length})
          {this.state.isExpanded && <ExpandLessIcon style={{ fontSize: 18 }} />}
          {!this.state.isExpanded && (
            <ExpandMoreIcon style={{ fontSize: 18 }} />
          )}
        </button>
      </div>
    )

    if (!this.state.isExpanded)
      return <div className="items-state-group">{heading}</div>

    const { fileId } = this.props
    return (
      <div className="items-state-group open">
        {heading}
        <ItemList
          items={items}
          component={props => (
            <ProductionFileTrackItem fileId={fileId} {...props} /> // append the fileId of the production file track
          )}
        />
      </div>
    )
  }
}
