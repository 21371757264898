import React from 'react'
import PropTypes from 'prop-types'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withFormik } from 'formik'
import * as yup from 'yup'
import Input from '../../../../components/input'

export const UserInfoForm = ({
  values,
  errors,
  isValid,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  dirty,
}) => (
  <form className="userbasic" onSubmit={handleSubmit}>
    <Input
      label="Name"
      name="name"
      value={values.name}
      id="i1"
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.name}
      required
    />
    <Input
      label="Email"
      name="email"
      value={values.email}
      type="email"
      id="i2"
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.email}
      required
    />
    {dirty && (
      <div style={{ display: 'inline-block', width: '100%' }}>
        <button
          disabled={!isValid}
          type="submit"
          style={{ marginTop: 0 }}
          className="btn btn-icon btnsave right black"
        >
          {isSubmitting ? 'SAVING...' : 'SAVE CHANGES'}
        </button>
      </div>
    )}
  </form>
)

UserInfoForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}
UserInfoForm.defaultProps = {
  errors: {
    name: '',
    email: '',
  },
}

const updateProfileMutation = gql(`
  mutation UpdateProfile($userInfo: UserProfileInput!) {
    updateProfile(userInfo: $userInfo) {
      name
      email
    }
  }
`)

export default compose(
  graphql(updateProfileMutation),
  withFormik({
    mapPropsToValues: ({ user }) => ({ email: user.email, name: user.name }),
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .min(3)
        .required(),
      email: yup
        .string()
        .email()
        .required(),
    }),
    handleSubmit: async (
      values,
      { props, setFieldError, setSubmitting, resetForm }
    ) => {
      try {
        setSubmitting(true)
        await props.mutate({ variables: { userInfo: values } })
        setSubmitting(false)
        props.onSuccess('Changes saved!')
        resetForm(values)
      } catch (e) {
        setSubmitting(false)
        // check if error is email already in use
        if (e.graphQLErrors) {
          e.graphQLErrors.forEach(_e => {
            if (_e.data && _e.data.code && _e.data.code === 'EMAIL_IN_USE') {
              setFieldError('email', 'email already in use')
            }
          })
        }
        props.onError(e)
      }
    },
  })
)(UserInfoForm)
