import React from 'react'
import Spinner from 'react-spinkit'

const Loader = props => (
  <Spinner
    key={0}
    name="line-scale-pulse-out"
    fadeIn="none"
    style={{
      display: 'flex',
      flex: 1,
      minHeight: '300px',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    {...props}
  />
)

Loader.Small = props => (
  <Spinner
    key={0}
    name="line-scale-pulse-out"
    fadeIn="none"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    {...props}
  />
)

export default Loader
