import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { ShowAlert, ShowSuccess } from '../actions/notificationActions'

const mapDispatchToProps = dispatch => ({
  notifications: bindActionCreators({ ShowAlert, ShowSuccess }, dispatch),
})

const NotificationsHOC = Component => ({ notifications, ...props }) => {
  function onError(error) {
    let errorMessage = 'An unexpected error happened'
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorMessage = error.graphQLErrors.map(({ message }) => message).join('')
    }
    notifications.ShowAlert(errorMessage)
  }
  function onSuccess(message) {
    if (!message) throw new Error('Message for success notification missing')
    notifications.ShowSuccess(message)
  }
  return <Component {...props} onError={onError} onSuccess={onSuccess} />
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  NotificationsHOC
)
