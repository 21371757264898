import React from 'react'
import PropTypes from 'prop-types'
import ORQuery from '../../components/ORQuery'
import ProductionsQuery from '../../graphql/productions/productionsQuery'

const ProductionsList = ({ done }) => (
  <ORQuery query={ProductionsQuery} variables={{ done }}>
    {({ data: { productions } }) => {
      if (productions && productions.length === 0) {
        return <p>{"Yay! You don't have pending productions."}</p>
      }
      return (
        <ul className="users">
          {productions.map(p => (
            <li key={p.id}>
              <section>
                <p>
                  {p.title}
                  <span>
                    &nbsp;•&nbsp; Contact:&nbsp;
                    {p.contactPerson.email}
                  </span>
                </p>
              </section>
              <section>
                <figure className="status-green" />
              </section>
            </li>
          ))}
        </ul>
      )
    }}
  </ORQuery>
)

ProductionsList.propTypes = {
  done: PropTypes.bool.isRequired,
}

export default ProductionsList
