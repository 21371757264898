import React from 'react'
import PropTypes from 'prop-types'
import qs from 'querystring'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { ShowAlert, ShowSuccess } from '../../../actions/notificationActions'
import Logo from '../../../assets/img/logo-new.svg'
import AuthLayout from '../../../containers/AuthLayout'
import ResetPasswod from './ResetPasswordForm'
import Loader from '../../../components/Loader'

class ResetPasswordView extends React.Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    resetPasswordConfirm: PropTypes.func.isRequired,
    notifications: PropTypes.shape({
      ShowAlert: PropTypes.func.isRequired,
      ShowSuccess: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  }
  state = {
    loading: true,
  }
  handleReset = async ({ email }) => {
    try {
      await this.props.resetPassword({
        variables: {
          email,
        },
      })
      this.props.notifications.ShowSuccess(
        'Reset password instructions has been sent!'
      )
    } catch (e) {
      this.onError(e)
    }
  }

  onError = error => {
    let errorMessage = 'An unexpected error happened'
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorMessage = error.graphQLErrors.map(({ message }) => message).join('')
    }
    this.props.notifications.ShowAlert(errorMessage)
  }

  async componentWillMount() {
    const params = qs.parse(this.props.location.search.substr(1))
    const { code, id } = params
    if (id && code) {
      // confirm the password reset
      await this.props.resetPasswordConfirm({ variables: { code, id } })
      this.props.notifications.ShowSuccess(
        'Password has been reset successfully!'
      )
      this.props.router.push('/login')
      return false
    }
    this.setState({ loading: false })
    return true
  }

  render() {
    if (this.state.loading) return <Loader />
    return (
      <AuthLayout>
        <div id="logingroup">
          <img src={Logo} alt="OneRights Official Logo" id="llogo" />
          <p>
            Enter your email below to retrieve your password reset instructions.
          </p>
          <ResetPasswod onReset={this.handleReset} />
        </div>
      </AuthLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  router: bindActionCreators({ push }, dispatch),
  notifications: bindActionCreators({ ShowAlert, ShowSuccess }, dispatch),
})

const resetPasswordMutation = gql(`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      resetted
    }
  }
`)

const resetPasswordConfirmMutation = gql(`
  mutation ForgotPasswordConfirm($id: ID!, $code: String!) {
    forgotPasswordConfirm(id: $id, code: $code) {
      resetted
    }
  }
`)

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  graphql(resetPasswordMutation, { name: 'resetPassword' }),
  graphql(resetPasswordConfirmMutation, { name: 'resetPasswordConfirm' })
)(ResetPasswordView)
