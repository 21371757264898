/** globals document  */
import outdatedBrowserRework from 'outdated-browser-rework'
import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import createPlugin from 'bugsnag-react'
import { ConnectedRouter } from 'react-router-redux'
import { ApolloProvider } from 'react-apollo'
import history from './utils/history'
import ApolloClient from './utils/Apollo'
import Routes from './routes'
import configureStore from './store'
import initialState from './initialState'
import * as serviceWorker from './registerServiceWorker'
// Add styles
import './assets/scss/style.scss'
import 'outdated-browser-rework/style.scss'

import bugsnagClient from './services/bugsnag'

outdatedBrowserRework()

const store = configureStore(initialState)

const ErrorBoundary = bugsnagClient.use(createPlugin(React))

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={ApolloClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)
serviceWorker.unregister()
