import React from 'react'
/**
 * Tracks child items open state via HOC state
 */
const ToggleStateHOC = Component => class ToggleList extends React.Component {
  state = {
    opennedItems: [], // keep track of items openned
  }

  /**
   * Toggles the visibility of the items
   */
  onItemOpen = (e) => {
    const itemId = e.target.dataset.id
    if (!itemId) throw new Error('id attribute not available in openned item.')
    this.setState(state => {
      // check if already visible
      if (!this.checkOpen(itemId)) {
        state.opennedItems.push(itemId)
      } else {
        state.opennedItems = state.opennedItems.filter(i => i !== itemId)
      }
      return state
    })
  }

  checkOpen = (itemId) => {
    if (!itemId) throw new Error('ItemId is required to verify if item is openned')
    return this.state.opennedItems.indexOf(itemId) !== -1
  }

  render() {
    return (<Component {...this.props} onItemOpen={this.onItemOpen} checkOpen={this.checkOpen} />)
  }
}

export default ToggleStateHOC
