import React from 'react'
import PropTypes from 'prop-types'
import Artist from '../Artist'
import InfiniteList from './InfiniteListComponent'

const InfiniteArtistList = ({ artists, fetchMore }) => (
  <InfiniteList
    cacheKey="artists"
    className="artists grid ar"
    fetchMore={fetchMore}
    items={artists}
    component={Artist}
  />
)

InfiniteArtistList.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  artists: PropTypes.shape({
    pageInfo: PropTypes.shape({
      nextPage: PropTypes.number.isRequired,
      hasNextPage: PropTypes.bool.isRequired,
    }).isRequired,
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.object.isRequired,
      }).isRequired,
    ),
  }).isRequired,
}

export default InfiniteArtistList
