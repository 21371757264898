import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '../../../assets/img/info.svg'
import {
  formatSongKey,
  formatSongMode,
  formatSongSignature,
  formatSongLoudness,
  formatToPercentage,
} from '../../../utils/Formatters'

const AudioFeatures = ({ features }) => {
  return (
    <div className="trackcontent">
      <h3>AUDIO FEATURES</h3>
      <p id="subinfo">
        Data should only be viewed as guiding and might not be 100% accurate.
      </p>
      <ul id="dots">
        <li>
          <p>
            {formatSongKey(features.key)}
            <span>Key</span>
          </p>
        </li>
        <li>
          <p>
            {formatSongMode(features.mode)}
            <span>Modality</span>
          </p>
        </li>
        <li>
          <p>
            {formatSongSignature(features.timeSignature)}
            <span>Time signature</span>
          </p>
        </li>
        <li>
          <p>
            {formatSongLoudness(features.loudness)}
            <span>Loudness</span>
          </p>
        </li>
      </ul>
      <table id="dataanalysis">
        <tbody>
          <tr>
            <td>
              Acousticness
              <a
                href="#"
                data-tooltip="A confidence measure from 0.0 to 1.0 of whether the track is acoustic. 1.0 represents high confidence the track is acoustic."
              >
                <img src={InfoIcon} alt="tip" />
              </a>
            </td>
            <td>
              <figure
                style={{ width: formatToPercentage(features.acousticness) }}
              />
            </td>
            <td>{formatToPercentage(features.acousticness, 1)}</td>
          </tr>
          <tr>
            <td>
              Danceability
              <a data-tooltip="Danceability describes how suitable a track is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity. A value of 0.0 is least danceable and 1.0 is most danceable.">
                <img src={InfoIcon} alt="tip" />
              </a>
            </td>
            <td>
              <figure
                style={{ width: formatToPercentage(features.danceability) }}
              />
            </td>
            <td>{formatToPercentage(features.danceability)}</td>
          </tr>
          <tr>
            <td>
              Energy
              <a
                href="#"
                data-tooltip="Energy is a measure from 0.0 to 1.0 and represents a perceptual measure of intensity and activity. Typically, energetic tracks feel fast, loud, and noisy. For example, death metal has high energy, while a Bach prelude scores low on the scale. Perceptual features contributing to this attribute include dynamic range, perceived loudness, timbre, onset rate, and general entropy."
              >
                <img src={InfoIcon} alt="tip" />
              </a>
            </td>
            <td>
              <figure style={{ width: formatToPercentage(features.energy) }} />
            </td>
            <td>{formatToPercentage(features.energy)}</td>
          </tr>
          <tr>
            <td>
              Instrumentalness
              <a
                href="#"
                data-tooltip="Predicts whether a track contains no vocals. \\u0022 Ooh\\u0022 and \\u0022aah\\u0022 sounds are treated as instrumental in this context. Rap or spoken word tracks are clearly \\u0022vocal\\u0022. The closer the instrumentalness value is to 1.0, the greater likelihood the track contains no vocal content. Values above 0.5 are intended to represent instrumental tracks, but confidence is higher as the value approaches 1.0."
              >
                <img src={InfoIcon} alt="tip" />
              </a>
            </td>
            <td>
              <figure
                style={{ width: formatToPercentage(features.instrumentalness) }}
              />
            </td>
            <td>{formatToPercentage(features.instrumentalness)}</td>
          </tr>
          <tr>
            <td>
              Liveness
              <a
                href="#"
                data-tooltip="Detects the presence of an audience in the recording. Higher liveness values represent an increased probability that the track was performed live. A value above 0.8 provides strong likelihood that the track is live."
              >
                <img src={InfoIcon} alt="tip" />
              </a>
            </td>
            <td>
              <figure
                style={{ width: formatToPercentage(features.liveness) }}
              />
            </td>
            <td>{formatToPercentage(features.liveness)}</td>
          </tr>
          <tr>
            <td>
              speechiness
              <a
                href="#"
                data-tooltip="Speechiness detects the presence of spoken words in a track. The more exclusively speech-like the recording (e.g. talk show, audio book, poetry), the closer to 1.0 the attribute value. Values above 0.66 describe tracks that are probably made entirely of spoken words. Values between 0.33 and 0.66 describe tracks that may contain both music and speech, either in sections or layered, including such cases as rap music. Values below 0.33 most likely represent music and other non-speech-like tracks."
              >
                <img src={InfoIcon} alt="tip" />
              </a>
            </td>
            <td>
              <figure
                style={{ width: formatToPercentage(features.speechiness) }}
              />
            </td>
            <td>{formatToPercentage(features.speechiness)}</td>
          </tr>
          <tr>
            <td>
              Valence / Happiness
              <a
                href="#"
                data-tooltip="A measure from 0.0 to 1.0 describing the musical positiveness conveyed by a track. Tracks with high valence sound more positive (e.g. happy, cheerful, euphoric), while tracks with low valence sound more negative (e.g. sad, depressed, angry)."
              >
                <img src={InfoIcon} alt="tip" />
              </a>
            </td>
            <td>
              <figure style={{ width: formatToPercentage(features.valence) }} />
            </td>
            <td>{formatToPercentage(features.valence)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

AudioFeatures.propTypes = {
  features: PropTypes.shape({
    key: PropTypes.number.isRequired,
    mode: PropTypes.number.isRequired,
    timeSignature: PropTypes.number.isRequired,
    loudness: PropTypes.number.isRequired,
    acousticness: PropTypes.number.isRequired,
    danceability: PropTypes.number.isRequired,
    energy: PropTypes.number.isRequired,
    instrumentalness: PropTypes.number.isRequired,
    liveness: PropTypes.number.isRequired,
    speechiness: PropTypes.number.isRequired,
    valence: PropTypes.number.isRequired,
  }).isRequired,
}

export default AudioFeatures
