import React from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import gql from 'graphql-tag'
import { Query, compose } from 'react-apollo'
import { formatTime } from '../../utils/Formatters'
import { Play, PlayAlbum } from '../../actions/audioPlayerActions'
import AlbumDefaultImage from '../../assets/img/album.svg'
import Loader from '../../components/Loader'

const ARTIST_DETAIL_QUERY = gql(`
  query ArtistDetailQuery($artistId: ID!) {
    artist(artistId: $artistId) {
      id
      displayName
      images {
        url
      }
      relatedArtists(size: 5) {
        pageInfo {
          totalCount
        }
        edges {
          node {
            id
            displayName
          }
        }
      }
      albums {
        id
        displayTitle
        type
        upc
        releaseDate
        images {
          url
        }
        tracks {
          id
          isrc
          displayTitle
          durationMs
          previewUrl
          downloadUrl
          streamUrl
          waveforms {
            datUrl
            imageUrl
          }
          artists {
            id
            displayName
          }
        }
      }
      appearsOnAlbums {
        id
        displayTitle
        type
        upc
        images {
          url
        }
        tracks {
          id
          isrc
          displayTitle
          durationMs
          previewUrl
          downloadUrl
          streamUrl
          waveforms {
            datUrl
            imageUrl
          }
          artists {
            id
            displayName
          }
        }
      }
    }
  }
`)

class ArtistView extends React.Component {
  static propTypes = {
    Play: PropTypes.func.isRequired,
    PlayAlbum: PropTypes.func.isRequired,
    audioPlayer: PropTypes.shape({
      isPaused: PropTypes.bool.isRequired,
      playingAlbumUPC: PropTypes.string,
      playingIndex: PropTypes.number,
      playlist: PropTypes.arrayOf(
        PropTypes.shape({
          isrc: PropTypes.string.isRequired,
        })
      ),
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        artistId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.albumSelectedId = null
    this.albumSelectedRef = React.createRef()
  }

  componentWillMount() {
    // parse the query string of the route
    const qsResult = qs.parse(this.props.location.search.replace('?', ''))
    // if an album has been specified we should keep the id
    // as later we'll need to do a scroll to the album
    if (qsResult.album) {
      this.albumSelectedId = qsResult.album
    }
  }

  /** Once the data has been loaded from graphql */
  onDataLoadingComplete = () => {
    // If there is a ref selected... scroll to it
    if (this.albumSelectedRef.current) {
      window.setTimeout(() => {
        window.scroll({
          top: this.albumSelectedRef.current.offsetTop - 130, // 130 is a temp fix due wrong scrolling position
          behavior: 'smooth',
        })
      }, 10)
    }
  }

  _playAlbumTrack = (album, track) => {
    this.props.Play(track, album)
  }

  _PlayAlbum = album => {
    this.props.PlayAlbum(album)
  }

  // Checks if the album is played using the local state
  _isPlayingAlbum = upc =>
    !this.props.audioPlayer.isPaused &&
    this.props.audioPlayer.playingAlbumUPC === upc

  _isPlayingTrack = trackId => {
    const { playingIndex } = this.props.audioPlayer
    if (playingIndex === null) return false
    return (
      this.props.audioPlayer.playlist[playingIndex].id === trackId &&
      !this.props.audioPlayer.isPaused
    )
  }

  _renderAlbumTrack = (album, track, index) => {
    const isPlayingClassName = this._isPlayingTrack(track.id) ? 'pause' : 'play'
    return (
      <li key={track.id}>
        <section onClick={() => this._playAlbumTrack(album, track)}>
          <figure className="number">{index + 1}</figure>
          <figure className={isPlayingClassName} />
          <h4>
            {`${track.displayTitle}${this._getFeatArtistsFromTrack(track)}`}
            <time>({formatTime(track.durationMs)})</time>
          </h4>
        </section>
        <section>
          {track.downloadUrl && (
            <a
              href={track.downloadUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="cardinfo">Download MP3</span>
            </a>
          )}
          <Link to={`/track/${track.id}`}>
            <span className="cardinfo">Trackinfo</span>
          </Link>
        </section>
      </li>
    )
  }

  /**
   * Returns a string of artists that are different than the artist requested.
   * It will be used to display artists that are sharing a track with the main artist with prefix "feat"
   */
  _getFeatArtistsFromTrack = track => {
    // Delete the artist requested from the artists and use only the displayName for each one
    const diffArtists = track.artists
      .filter(artist => artist.id !== this.props.match.params.artistId)
      .map(({ displayName }) => displayName)
    if (diffArtists.length === 0) return ''
    let artists = ' feat '
    if (diffArtists.length === 1) return `${artists} ${diffArtists[0]}`
    artists += diffArtists.slice(0, diffArtists.length - 1).join(' , ')
    // push the last with a &
    artists += ` & ${diffArtists.pop()}`
    return artists
  }

  /** If there album selected from ?album=XXX matches the album passed it returns a React ref */
  _getRefIfAlbumSelected = albumId => {
    if (albumId === this.albumSelectedId) return this.albumSelectedRef
    return null
  }

  _renderAlbum = album => {
    const isPlayingClassName = this._isPlayingAlbum(album.upc)
      ? 'pause'
      : 'play'
    return (
      <div key={album.id} ref={this._getRefIfAlbumSelected(album.id)}>
        <div className="tracklistinfo">
          <section onClick={() => this._PlayAlbum(album)}>
            <figure className="tleft overlay">
              <img
                id="artwork"
                src={_.get(album, 'images[1].url', AlbumDefaultImage)}
                alt={album.displayTitle}
              />
              <figure className={isPlayingClassName} />
              <span className="ol" />
            </figure>
          </section>
          <div className="tracklist ar">
            <h2>
              {album.displayTitle}&nbsp;<span>•&nbsp;{album.releaseDate}</span>
            </h2>
            <ol>
              {album.tracks.map(this._renderAlbumTrack.bind(this, album))}
            </ol>
          </div>
        </div>
        <hr />
      </div>
    )
  }
  _getAlbumsByType = (albums = [], type) =>
    albums.filter(a => a.type.toLowerCase() === type) || []
  _renderAlbumSection = (albums = [], type, title) => {
    const albumsForType = this._getAlbumsByType(albums, type)
    if (albumsForType.length === 0) return null
    return (
      <div>
        <header>
          <h3>{title}</h3>
        </header>
        {albumsForType.map(this._renderAlbum)}
      </div>
    )
  }
  _getHighestResArtistImageUrl = images => {
    if (!images.length) return null
    const highestImage = images.reduce(
      (prev, cur) => (cur.height > prev.height ? cur : prev),
      images[0]
    )
    return highestImage.url
  }
  render() {
    return (
      <Query
        query={ARTIST_DETAIL_QUERY}
        variables={{ artistId: this.props.match.params.artistId }}
        onCompleted={this.onDataLoadingComplete}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />
          if (error) return <p>An error happened...</p>
          const { artist } = data
          if (!artist) return <p>No artist has been found</p>
          const artistCoverImage = this._getHighestResArtistImageUrl(
            artist.images
          )
          return (
            <div>
              <div id="arhead">
                <figure
                  className={!artistCoverImage ? 'empty' : ''}
                  style={{
                    backgroundImage: artistCoverImage
                      ? `url(${artistCoverImage})`
                      : 'none',
                  }}
                />
                <div className="c">
                  <h1>{artist.displayName}</h1>
                  {artist.relatedArtists.pageInfo.totalCount > 0 && (
                    <div>
                      <h3>Related artists:</h3>
                      <ul className="c-artists">
                        {artist.relatedArtists.edges.map(({ node }) => (
                          <li key={node.id}>
                            <Link to={`/artist/${node.id}`}>
                              {node.displayName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <main id="content" className="fadein">
                <p id="breadcrumb">
                  <Link to="/search/browse">Search</Link>
                  <span> &nbsp;//&nbsp;</span> {artist.displayName}
                </p>
                {this._renderAlbumSection(artist.albums, 'album', 'ALBUMS')}
                {this._renderAlbumSection(artist.albums, 'single', 'SINGLES')}
                {artist.appearsOnAlbums && artist.appearsOnAlbums.length > 0 && (
                  <div>
                    <header>
                      <h3>Appears On</h3>
                    </header>
                    {artist.appearsOnAlbums.map(this._renderAlbum)}
                  </div>
                )}
              </main>
            </div>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = state => ({
  audioPlayer: state.get('audioPlayer').toJS(),
  trackPlaying:
    state.getIn([
      'audioPlayer',
      'playlist',
      state.getIn(['audioPlayer', 'playingIndex']),
    ]) || false,
  playerIsPaused: state.getIn(['audioPlayer', 'isPaused']),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ Play, PlayAlbum }, dispatch)

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ArtistView)
